import React, { FC } from 'react';

import { Button, message } from 'antd';
import { UserSwitchOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import ShareSettingsTable from '../ShareSettingsTable';
import SharePopupHeader from './SharePopupHeader';
import { useStores } from '../../../hooks';
import { SecuredFile } from '../../../../types/types';
import { SharedUserToDisplay } from '../../../../stores';
import { ModalContentProps } from '../../../Common/Modal';
import { SharedItemType } from '../../../Common/RecipientRoleSelect';
import { ENDPOINTS, BASEURL } from '../../../../api';
import { captureUnexpectedNetworkError } from '../../../utils';
import { checkPhonesChanges, getChangedUsers, prepareNewUsersList } from '../helpers';
import './index.scss';

const nameSpace = 'filesTable.modals.settings.shareSettings';
const messagesNameSpace = `${nameSpace}.messages`;

interface ShareSettingsProps extends ModalContentProps {
    fileSelected: SecuredFile;
    sharedItemType: SharedItemType;
    users: SharedUserToDisplay[];
    onChangeUsers: (newUsers: SharedUserToDisplay[]) => void;
    onShareSettings: (isSuccess: boolean) => void;
    toggleFileSettings: () => void;
}

const ShareSettings: FC<ShareSettingsProps> = observer(({
    fileSelected,
    users,
    onChangeUsers,
    sharedItemType,
    onShareSettings,
    toggleFileSettings,
    setLoadingStatus,
}) => {
    const { t } = useTranslation();
    const { authSettingsStore: { API }, sharedUsersStore, usersPhonesStore } = useStores();
    const { sharedUsersToDisplay, tryBulkSavePhonesSettings } = sharedUsersStore;
    const { usersPhonesMap } = usersPhonesStore;
    const { file_id: fileId } = fileSelected;

    const changedUsers = getChangedUsers(users, sharedUsersToDisplay).map(item => ({...item, email: item.email.toLowerCase()}));
    const hasChanges = changedUsers.length || checkPhonesChanges(sharedUsersToDisplay, usersPhonesMap);

    const savePhones = async (): Promise<void> => {
        const isSucceed = await tryBulkSavePhonesSettings();
        if (!isSucceed) {
            throw Error('Cannot save phones');
        }
    };

    const sendAccessChanges = async (): Promise<void> => {
        if (changedUsers.length) {
            await API.patch(BASEURL.backend(), ENDPOINTS.shareFile(fileId), { body: { data: changedUsers } });
        }
    };

    const handleSubmit = async (): Promise<void> => {
        try {
            setLoadingStatus(true, t(`${messagesNameSpace}.wait.saveSharingSettings`));
            await Promise.all([
                sendAccessChanges(),
                savePhones(),
            ]);
            setLoadingStatus(false);
            onShareSettings(true);
            sharedUsersStore.setSharedUsers(prepareNewUsersList(changedUsers, users), fileId);
        } catch (error) {
            captureUnexpectedNetworkError(error, 'ShareModal.ShareSettings.handleSubmit');
            setLoadingStatus(false);
            const isPhones = error == 'Error: Cannot save phones';
            message.error(t(`${messagesNameSpace}.error.${isPhones ? 'couldNotSavePhones' : 'couldNotSave'}`));
        }
    };

    return (
        <div className="share-setting-wrapper">
            <div className="header">
                <SharePopupHeader
                    title="Edit permissions"
                    toggleFileSettings={toggleFileSettings}
                    toggleText="Add users"
                    toggleIcon={<UserSwitchOutlined />}
                />
            </div>
            <div className="share-settings-content" data-testid="shareDialog_WhoHasAccess">
                <ShareSettingsTable
                    fileSelected={fileSelected}
                    sharedItemType={sharedItemType}
                    sharedUsers={users}
                    onChangeUsers={onChangeUsers}
                />
                {users.length > 0 && (
                    <Button
                        className="apply-share"
                        data-testid="manageAccess_apply"
                        disabled={!hasChanges}
                        onClick={handleSubmit}
                        type="primary"
                    >
                        {t('general.buttons.apply')}
                    </Button>
                )}
            </div>
        </div>
    );
});

export default ShareSettings;
