import React, { FC, useCallback, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import { Button, Divider, Form } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from 'rc-field-form/lib/interface';

import { useStores } from '@/components/hooks';
import { FormFields, FormFieldsChange } from '@/stores/AuditsStore/interaces';
import i18n from '@/content';

import ClearFilters from './ClearFilters';
import ExportCSV from './ExportCSV';
import SelectOperation from './SelectOperation';
import InputFilter from './InputFilter';
import styles from './AuditsFilters.module.scss';

const { useForm } = Form;

const INIT_VALUES: Record<FormFields, string> = {
    [FormFields.searchedUser]: '',
    [FormFields.selectedOperation]: '',
    [FormFields.searchedFilename]: '',
    [FormFields.searchedFileId]: '',
};

const AuditsFilters: FC = observer(() => {
    const [, setParams] = useSearchParams();

    const { auditsStore } = useStores();
    const [form] = useForm();

    const onFinish = (): void => {
        auditsStore.tryFetchAudits();
    };

    const onValuesChange = (changedValues: Store): void => {
        auditsStore.setFilter(changedValues as FormFieldsChange);
    };

    const clear = useCallback((): void => {
        setParams({});
        form.resetFields();
        auditsStore.clearFilters();
    }, [form]);

    const setSearchedFileId = useCallback((fileId: string): void => {
        form.setFieldsValue({ [FormFields.searchedFileId]: fileId });
    }, [form]);

    useEffect(() => {
        if (auditsStore.fileId) {
            setSearchedFileId(auditsStore.fileId);
        }
    }, [auditsStore.fileId, setSearchedFileId]);

    return (
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={INIT_VALUES}
            onValuesChange={onValuesChange}
            className={styles['filters-form']}
        >
            <Divider type="vertical" className={`${styles['first-divider']} ${styles.divider}`} />
            <InputFilter filterType={FormFields.searchedUser} />
            <SelectOperation />
            <div className={styles['filename-input-wrapper']}>
                <InputFilter filterType={FormFields.searchedFileId} />
            </div>
            <div className={styles['filename-input-wrapper']}>
                <ClearFilters onClick={clear} />
                <InputFilter filterType={FormFields.searchedFilename} />
            </div>
            <Form.Item className={styles['form-item']}>
                <Button
                    className={styles['apply-btn']}
                    type="primary"
                    htmlType="submit"
                >
                    {i18n.t('general.buttons.apply')}
                    <RedoOutlined />
                </Button>
            </Form.Item>

            <div className={styles['export-csv-wrapper']}>
                <Divider type="vertical" className={`${styles['last-divider']} ${styles.divider}`} />
                <ExportCSV />
            </div>
        </Form>
    );
});

export default AuditsFilters;
