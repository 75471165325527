import React, { FC } from 'react';

import { Divider } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import WorkspaceNameBlock from './WorkspaceNameBlock';
import SharingBlock, { NotifyRecipients } from '../../Common/SharingBlock';
import FilesPolicyBlock from '../../UploadDrawer/UploadFiles/FilesPolicyBlock';
import { useStores } from '../../hooks';
import styles from './ShareWorkspace.module.scss';

const nameSpace = 'workspaceDrawer';

const ShareWorkspace: FC = observer(() => {
    const { t } = useTranslation();

    const {
        workspacesStore: { chosenForPolicyWorkspace, chosenForPolicyWorkspaceFid },
        sharedUsersStore: { hasRecipients },
    } = useStores();

    return (
        <>
            <div className={styles['block-wrapper']}>
                <WorkspaceNameBlock />
            </div>
            <div className="sharing-policy-block">
                <div className={styles['block-wrapper']}>
                    <SharingBlock
                        showPhones={chosenForPolicyWorkspace?.policy?.isMfa}
                        headerContent={t(`${nameSpace}.sendTo`)}
                    />
                    {hasRecipients && <Divider />}
                </div>
                {hasRecipients && (
                    <>
                        <div className={styles['block-wrapper']}>
                            <NotifyRecipients />
                            <Divider />
                        </div>
                    </>
                )}
                {chosenForPolicyWorkspaceFid && (
                    <FilesPolicyBlock
                        isWorkspace
                        contentModulesClassName={styles['block-wrapper']}
                    />
                )}
            </div>
        </>
    );
});

export default ShareWorkspace;
