import {
    FC, useEffect, useState,
} from 'react';

import { Button, ConfigProvider } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Can } from '@casl/react';
import { PaperClipOutlined } from '@ant-design/icons';
import { LANGUAGE_POLICY } from '@/consts';
import Spinner from '../../Common/Spin';
import { PublicFooter } from '../../Common/PublicComponents';
import { BatchDownloadWidget } from '../../Common/OverflowWidget';
import {
    Header,
    useLanguageDefinition,
    useURLLinkId,
} from '../Common';
import { useStores, useTargetScreen } from '../../hooks';
import usePossibleRedirectToFolder from './usePossibleRedirectToFolder';
import appConfig from '../../../config/env';
import ability from '@/config/ability';
import { LANGUAGES } from '@/content/languages';
import styles from './RecipientPage.module.scss';
import { RecipientPageContent } from './InnerContent/RecipientPageContent';
import SharedOwnersLoaders from '@/components/Common/SendFilesBack/SharedOwnersLoaders';
import usePageLanguage from '../../hooks/usePageLanguage';

interface RecipientPageProps {
    isRootLoading: boolean;
}

const nameSpace = 'recipientPage.header';

const RecipientPage: FC<RecipientPageProps> = observer(({
    isRootLoading,
}) => {
    const { isLanguageDefined, defineLanguage } = useLanguageDefinition();
    const { i18n } = useTranslation();
    const { chooseLanguage } = usePageLanguage(i18n);

    const {
        recipientPageStore,
        userStore,
    } = useStores();

    const isMobile = useTargetScreen('md');

    const { fullScreen, collapsed, sharedFilesStore } = recipientPageStore;
    const {
        isLoading,
        isMainDataLoaded,
        linkData,
    } = sharedFilesStore;

    useURLLinkId((linkId) => sharedFilesStore.setLinkId(linkId));

    const isReadyToLoadData = !isRootLoading && !!sharedFilesStore.linkId;

    useEffect(() => {
        if (isMainDataLoaded && !isLanguageDefined) {
            const possibleLanguage = (
                sharedFilesStore.allFilesPoliciesAggregate[LANGUAGE_POLICY]?.value as LANGUAGES
                || appConfig.DEFAULT_LANGUAGE
            );
            if (!sharedFilesStore.errorType) {
                defineLanguage(possibleLanguage);
            }
            chooseLanguage(possibleLanguage);
        }
    }, [isMainDataLoaded, isLanguageDefined]);

    useEffect(() => {
        if (isReadyToLoadData) {
            sharedFilesStore.tryFetchLink();
        }
    }, [isReadyToLoadData]);

    usePossibleRedirectToFolder(sharedFilesStore, userStore);

    const visibleFooter = (!isMobile && !fullScreen && !collapsed) || (!collapsed && fullScreen);

    return (
        <ConfigProvider direction={i18n.dir()}>
            <Spinner spinning={isLoading}>
                <div className={styles.container}>
                    {!fullScreen && (
                        <Header>
                            <Can I="SpecterxShareFiles" a="ALL" ability={ability}>
                                <div className={styles['buttons-container']}>
                                    <SharedOwnersLoaders
                                        allowMultipleSharing
                                        sourceEmail={linkData?.src_email || ''}
                                        renderButton={(onClick) => (
                                            (
                                                <div>
                                                    {appConfig.SHOW_SHARE_BACK_BUTTON && (
                                                        <Button
                                                            className={styles['send-back-button']}
                                                            onClick={onClick}
                                                            type="link"
                                                            shape="round"
                                                            icon={<PaperClipOutlined />}
                                                        >
                                                            {i18n.t(`${nameSpace}.buttons.shareAFileBack`)}
                                                        </Button>
                                                    )}
                                                </div>
                                            )
                                        )}
                                    />
                                </div>
                            </Can>
                        </Header>
                    )}
                    <RecipientPageContent isLanguageDefined={isLanguageDefined} />
                    {visibleFooter && <PublicFooter showSkeleton={!isLanguageDefined} />}
                </div>
            </Spinner>
            <BatchDownloadWidget wrapperClassName={styles['recipient-popup']} />
        </ConfigProvider>
    );
});

export default RecipientPage;
