import type { FC } from 'react';

import {
    Pagination, Table, Tag,
} from 'antd';
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import type { SpinProps } from 'antd/lib/spin';

import ColoredTooltip from '@/components/Common/ColoredTooltip';
import { Select } from '@/components/Common/UIKit';
import { useStores } from '@/components/hooks';
import type { FileMenu } from '@/types/types';
import { AccessApps, LogRecord, Operations } from '@/stores/AuditsStore/interaces';
import i18n from '@/content';
import { tryCopyToClipboard } from '@/components/utils';

import SimpleCell from './SimpleCell';
import TimeStampCell from './TimeStampCell';
import FileIdCell from './FileIdCell';
import AccessAppCell from './AccessAppCell';
import DeviceIdentityCell from './DeviceIdentityCell';
import AuditBreadcrumbs from './AuditBreadcrumbs';
import { Actions, PolicyTag } from '../../FilesTable/Columns';
import useTableHeight from './useTableHeight';
import { FileActionType } from '../../FilesTable/FileAction/menuItems';
import {
    MAP_OPERATION_TYPE_TO_COLOR, PAGE_SIZES_TO_DROPDOWN,
} from '../constants';
import './index.scss';

const { Column } = Table;

const FILE_MENU: Partial<FileMenu> = {
    options: ['delete_file', 'set_file_policy'],
};

const mainNameSpace = 'auditLogs';
const nameSpace = `${mainNameSpace}.tableColumns`;

interface AuditsTableProps {
    handleMenuClick: (action: FileActionType, fileId: string) => void | Promise<void>;
}

const AuditsTable: FC<AuditsTableProps> = observer(({ handleMenuClick }) => {
    const {
        auditsStore: {
            isLoading, logsList, logsToDisplay, fileId,
            pagination: { currentPage, pageSize, nextPageToken },
            handlePageChange, handlePageSizeChange,
        },
    } = useStores();

    const { containerRef, height } = useTableHeight(!!logsToDisplay.length);

    const totalRecords = logsList.length;
    // +1 using here to show next page into Ant Paginator
    const totalRecordsToPagination = nextPageToken ? totalRecords + 1 : totalRecords;
    const tableLoading: SpinProps = {
        wrapperClassName: 'audits-table-spinner',
        spinning: isLoading,
        indicator: <LoadingOutlined className="audits-spin" spin />,
    };

    const onClickUsername = (owner: string): void => {
        tryCopyToClipboard(owner, i18n.t(`${nameSpace}.user`));
    };

    return (
        <div className="audits-table-wrapper">
            <div className="pagination-wrapper">
                <div className="pagination-wrapper-breadcrumbs">
                    {fileId && <AuditBreadcrumbs fileId={fileId} />}
                </div>
                <div className="pagination-wrapper-panel">
                    <Pagination
                        showSizeChanger={false}
                        current={currentPage}
                        total={totalRecordsToPagination}
                        onChange={handlePageChange}
                        disabled={isLoading}
                        pageSize={pageSize}
                    />
                    <span className="rows-per-page">
                        {i18n.t(`${mainNameSpace}.rowsPerPage`)}
                    </span>
                    <Select<number>
                        options={PAGE_SIZES_TO_DROPDOWN}
                        onChange={handlePageSizeChange}
                        value={pageSize}
                        disabled={isLoading}
                    />
                </div>
            </div>
            <div className="audits-table-inner-wrapper" ref={containerRef}>
                <Table<LogRecord>
                    rowKey="timestamp"
                    className="audits-table"
                    dataSource={logsToDisplay}
                    loading={tableLoading}
                    pagination={false}
                    scroll={{ x: true, y: height }}
                >
                    <Column<LogRecord>
                        title={i18n.t(`${nameSpace}.timestamp`)}
                        dataIndex="timestamp"
                        key="timestamp"
                        render={(timestamp: string) => (
                            <TimeStampCell timestamp={timestamp} />
                        )}
                    />
                    <Column<LogRecord>
                        title={i18n.t(`${nameSpace}.user`)}
                        dataIndex="userName"
                        key="userName"
                        render={(text, record) => (
                            <SimpleCell
                                text={record.user}
                                onClick={() => onClickUsername(record?.operation === 'upload'
                                    ? record.owner_id
                                    : record.user)}
                            />
                        )}
                    />
                    <Column<LogRecord>
                        title={i18n.t(`${nameSpace}.operation`)}
                        dataIndex="operation"
                        key="operation"
                        render={(operation: Operations) => (
                            <Tag color={MAP_OPERATION_TYPE_TO_COLOR[operation] || '#108ee9'}>
                                <code>{i18n.t(`${mainNameSpace}.operationTypes.${operation}`)}</code>
                            </Tag>
                        )}
                    />
                    <Column<LogRecord>
                        title={i18n.t(`${nameSpace}.fileName`)}
                        dataIndex="file_name"
                        key="file_name"
                        width="200px"
                        render={(filename: string | undefined, { child_name: childName }) => {
                            const name = childName || filename;
                            return filename && (
                                <ColoredTooltip title={name} placement="topLeft">
                                    <SimpleCell className="file-name" text={name} />
                                </ColoredTooltip>
                            );
                        }}
                    />
                    <Column<LogRecord>
                        title={i18n.t(`${nameSpace}.affectedUsers`)}
                        dataIndex="affected_users"
                        key="affected_users"
                        render={(users: string[] | undefined) => (
                            <>
                                {users && users.map((user) => (<Tag key={user}><code>{user}</code></Tag>))}
                            </>
                        )}
                    />
                    <Column<LogRecord>
                        title={i18n.t(`${nameSpace}.policyName`)}
                        dataIndex="policy_name"
                        key="policy_name"
                        render={(policyName: string | undefined) => (
                            policyName && <PolicyTag title={policyName} />
                        )}
                    />
                    <Column<LogRecord>
                        title={i18n.t(`${nameSpace}.app`)}
                        dataIndex="app"
                        key="app"
                        render={(accessApp: AccessApps | undefined) => (
                            accessApp && <AccessAppCell accessApp={accessApp} />
                        )}
                    />
                    <Column<LogRecord>
                        title={i18n.t(`${nameSpace}.userIp`)}
                        dataIndex="user_ip"
                        key="user_ip"
                        render={(userIP: string | undefined, { user_agent: userAgentStr, user_country: user_country_code }) => (
                            <DeviceIdentityCell userIP={userIP} userAgentStr={userAgentStr} userCountryCode={user_country_code} />
                        )}
                    />
                    <Column<LogRecord>
                        title={i18n.t(`${nameSpace}.fileId`)}
                        dataIndex="file_id"
                        key="file_id"
                        render={(fileId: string | undefined, { origin_id: originId, child_id: childId }) => {
                            const id = childId || fileId;
                            return (id || originId) && <FileIdCell fileId={id} originId={originId} />;
                        }}
                    />
                    <Column<LogRecord>
                        title=""
                        dataIndex="file_id"
                        key="file_id"
                        render={(fileId: string | undefined, { operation, child_id: childId, details }) => {
                            const isGroupAction = details && 'group_id' in details;
                            const id = childId || fileId;
                            return (
                                <>
                                    {!isGroupAction && id && operation !== 'delete' && (
                                        <Actions
                                            fileId={id}
                                            openIcon={<MoreOutlined />}
                                            menu={FILE_MENU}
                                            handleMenuClick={handleMenuClick}
                                        />
                                    )}
                                </>
                            );
                        }}
                    />
                </Table>
            </div>
        </div>
    );
});

export default AuditsTable;
