import { FC } from 'react';

import { Divider, message } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react';

import { useStores } from '@/components/hooks';

import { NotifyRecipients } from '@/components/Common/SharingBlock';
import CustomMessage from '@/components/Common/SharingBlock/CustomMessage';
import EmailInput, { InitRecipientItem } from '@/components/Common/SharingBlock/EmailInput';
import InviteAs from '@/components/Common/SharingBlock/InviteAs';
import RecipientListItem from '@/components/Common/SharingBlock/RecipientListItem';
import { SuccessShareScreen } from './SuccessShareScreen';

import { Recipient } from '@/types/types';
import { validateEmail } from '@/components/validators';

import styles from './UsersList.module.scss';

interface RecipientCheckResult {
    result: 'success' | 'warning' | 'info' | 'error';
    messageKey?: string;
    messagePayload?: Record<string, string>;
}

const hasSharedUsers = true;
const ownerEmail = 'd.belov@tg.dunice.net';

interface UsersListProps {
    success: boolean;
}

const UsersList: FC<UsersListProps> = ({ success }) => {
    const {
        sharedUsersStore,
        usersListStore: { userGroupsList },
        userStore: { userInformation },
        uploadFilesStore: { hasMfa },
    } = useStores();

    const {
        addRecipients,
        setCurrentInputEmail,
        inviteAs,
        recipientsToDisplay,
        sharedUsers,
    } = sharedUsersStore;

    const checkRecipientErrors = (value: string): RecipientCheckResult => {
        const emailLower = value.toLowerCase();

        const nameSpace = 'sharingBlock.validation';
        let checkResult: RecipientCheckResult;
        const isGroup = !!userGroupsList.find((item) => item.groupId.toLowerCase() === emailLower);
        const isGroupAlreadyAdded = (isGroup
          && !!recipientsToDisplay.find((item) => item.id.toLowerCase() === emailLower));
        const isEmailAlreadyAdded = !!recipientsToDisplay.find((item) => item.email.toLowerCase() === emailLower);

        switch (true) {
        case isGroupAlreadyAdded:
            checkResult = { result: 'info', messageKey: `${nameSpace}.groupAlreadyAdded` };
            break;

        case isGroup:
            checkResult = { result: 'success' };
            break;

        case !validateEmail(value):
            checkResult = { result: 'error', messageKey: 'general.specterxCommon.invalidEmail' };
            break;

        case userInformation.email.toLowerCase() === emailLower:
            checkResult = { result: 'info', messageKey: `${nameSpace}.cannotShareWithYourself` };
            break;

        case !!ownerEmail && ownerEmail.toLowerCase() === emailLower:
            checkResult = { result: 'info', messageKey: `${nameSpace}.cannotShareWithOwner` };
            break;

        case isEmailAlreadyAdded:
            checkResult = { result: 'info', messageKey: `${nameSpace}.emailAlreadyAdded` };
            break;

        case hasSharedUsers && !!sharedUsers.find((user) => user.email.toLowerCase() === emailLower):
            checkResult = {
                result: 'info',
                messageKey: `${nameSpace}.alreadyShared`,
                messagePayload: { value },
            };
            break;

        default:
            checkResult = { result: 'success' };
        }

        return checkResult;
    };

    const tryAddRecipients = (recipientsToAdd: InitRecipientItem[]): void => {
        if (recipientsToAdd?.length) {
            const validRecipients = recipientsToAdd.filter(({ value }) => {
                const { result, messageKey, messagePayload } = checkRecipientErrors(value);
                const hasErrors = result !== 'success';
                if (hasErrors) {
                    message[result](t(messageKey, messagePayload));
                }
                return !hasErrors;
            });
            const newRecipients: Recipient[] = validRecipients.map<Recipient>(({ value, label, type }) => ({
                email: label,
                id: value,
                role: inviteAs,
                type,
            }));
            addRecipients(newRecipients);
            setCurrentInputEmail('');
        }
    };

    if (success) {
        return (
            <SuccessShareScreen />
        );
    }

    return (
        <>
            <span className={styles['users-list-title']}>Share securely using SpecterX with</span>
            <div>
                <EmailInput addRecipients={tryAddRecipients} />
                <InviteAs />
            </div>
            <Divider className={styles['divider']} />
            <NotifyRecipients />
            <CustomMessage />
            <Divider className={styles['divider']} />
            <div className={styles['recipients-list-wrapper']}>
                <ul className={styles['added-users-list']}>
                    {recipientsToDisplay?.map((recipient, index) => (
                        <div key={recipient.email}>
                            <RecipientListItem
                                key={recipient.email}
                                recipient={recipient}
                                showPhones={hasMfa}
                                hasAccessRoleToggle
                            />
                            {index !== recipientsToDisplay.length - 1 && <Divider className={styles['user-divider']} />}
                        </div>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default observer(UsersList);
