import {
    avg, calculateDecreaseCoefficient, getProgressDelta, random,
} from './math';

const FULL_PERCENTS = 100;

/*
* Config for progress bar
* @param {Number} breakpointPercents value in percents, while progress bar should fill with constant speed
* @param {Number} breakpointTime     value in seconds, while progress bar should fill with constant speed
* @param {Number} frequencyMin       Minimal value for frequency of progress update
* @param {Number} frequencyMax       Maximum value for frequency of progress update
* @param {Number} progressLimit      Value to stop automatically progress update.
*                                    Should be between 100 and breakpointPercents
* @param {Number} minProgressDelta   When progress increase will be less than this value,
*                                    counter will be stopped even if progressLimit won't be reached
* */

interface ProgressOptions {
    readonly breakpointPercents: number;
    readonly breakpointTime: number;
    readonly frequencyMin: number;
    readonly frequencyMax: number;
    readonly progressLimit: number;
    readonly minProgressDelta: number;
}

type OnProgress = (pid: string, progress: number) => void;

const PROGRESS_OPTIONS_DEFAULT: ProgressOptions = {
    breakpointPercents: 80,
    breakpointTime: 90,
    frequencyMin: 1000,
    frequencyMax: 6000,
    progressLimit: 99.9,
    minProgressDelta: 0.05,
};

class ProgressCounter {
    private progress: number;

    private timeout: NodeJS.Timeout;

    private breakpointReached = false;

    private decreaseCoefficient: number;

    private speed: number;

    private readonly pid: string;

    private readonly options: ProgressOptions = PROGRESS_OPTIONS_DEFAULT;

    private readonly onProgress: OnProgress;

    constructor(pid: string, onProgress: OnProgress) {
        this.pid = pid;
        this.onProgress = onProgress;
    }

    private setProgress(newValue: number): void {
        this.progress = newValue;
    }

    private setDecreaseCoefficient(newValue: number): void {
        this.decreaseCoefficient = newValue;
    }

    private setSpeed(newValue: number): void {
        this.speed = newValue;
    }

    private reachBreakpoint(avgProgressDelta: number): void {
        this.breakpointReached = true;
        // Update coefficient to prevent incorrect speed decreasing
        this.setDecreaseCoefficient(calculateDecreaseCoefficient(this.progress, avgProgressDelta));
    }

    private updateProgress(frequency: number, avgProgressDelta: number): void {
        const {
            progress,
            speed,
            options: {
                progressLimit, breakpointPercents, frequencyMax, frequencyMin, minProgressDelta,
            },
        } = this;
        if (progress < progressLimit) {
            if (!this.breakpointReached && progress >= breakpointPercents) {
                this.reachBreakpoint(avgProgressDelta);
            }
            const newSpeedValue = this.breakpointReached ? speed * this.decreaseCoefficient : speed;
            const progressDelta = getProgressDelta(newSpeedValue, frequency);
            if (progressDelta > minProgressDelta) {
                const newProgressValue = progress + getProgressDelta(newSpeedValue, frequency);
                const isLimitReached = newProgressValue > progressLimit;
                const resultProgressValue = isLimitReached ? progressLimit : newProgressValue;
                this.setProgress(resultProgressValue);
                this.setSpeed(newSpeedValue);
                this.onProgress(this.pid, this.getCurrentProgress());

                if (!isLimitReached) {
                    const newFrequency = random(frequencyMin, frequencyMax);
                    this.timeout = setTimeout(() => {
                        this.updateProgress(newFrequency, avgProgressDelta);
                    }, newFrequency);
                }
            }
        }
    }

    getCurrentProgress(): number {
        return Math.floor(this.progress);
    }

    runProgress(): void {
        const {
            breakpointPercents, breakpointTime, frequencyMin, frequencyMax,
        } = this.options;

        this.setProgress(0);
        const speed = breakpointPercents / breakpointTime;
        this.setSpeed(speed);
        const avgFrequency = avg(frequencyMin, frequencyMax);
        const avgProgressDelta = getProgressDelta(speed, avgFrequency);
        // Set init value to prevent multiply on null
        const decreaseCoefficient = calculateDecreaseCoefficient(breakpointPercents, avgProgressDelta);
        this.setDecreaseCoefficient(decreaseCoefficient);
        const initFrequency = random(frequencyMin, frequencyMax);
        this.timeout = setTimeout(() => {
            this.updateProgress(initFrequency, avgProgressDelta);
        }, initFrequency);
    }

    complete(): void {
        clearTimeout(this.timeout);
        this.setProgress(FULL_PERCENTS);
    }

    cancel(): void {
        clearTimeout(this.timeout);
    }
}

export default ProgressCounter;