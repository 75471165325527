import { MutableRefObject } from 'react';

import { TableProps as RcTableProps } from 'rc-table/lib/Table';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { User as WSOUser } from 'oidc-client';

import { OpenOptions } from '@/config/openOptions';

export type { CognitoUser };
export type { WSOUser };

export type SpecterXUser = CognitoUser | WSOUser;

export type UploadMode = 'filesRequired' | 'filesNotRequired';

export interface CompanyInformation {
    name: string;
    imageUrl: string;
}

export interface PolicySimple {
    title: string;
    id: string;
}

export type FileOptionType = 'set_policy'
    | 'set_file_policy'
    | 'delete'
    | 'delete_file'
    | 'revoke'
    | 'audits'
    | 'add_files'
    | OpenOptions.download;

/* eslint-disable camelcase */
export interface FileMenu {
    options: FileOptionType[];
    open_with: OpenOptions[];
    read_only: boolean;
    share: boolean;
    edit_shares?: boolean;
}

type FileState = 'splitting' | 'secure' | 'assembling' | 'locked';

export type FileStatus = 'exporting'
    | 'ready_for_open'
    | 'ready'
    | 'preparing'
    | 'ready_for_download'
    | 'error'
    | 'uploading'
    | 'scanning'
    | 'scan_blocked'
    | 'deleted'
    | 'fetching_chuncks';

// TODO: always use file_id instead of legacy fid
export interface SecuredFile {
    fid: string;
    file_id: string;
    filename: string;
    filename_lowercase: string;
    file_size: number;
    current_state: FileState;
    creation_time: string;
    last_used_time: string;
    last_used_by: string;
    can_take_ownership?: boolean;
    owner: string;
    owner_id: string;
    owner_email?: string;
    menu?: FileMenu;
    isMfa?: boolean;
    policy?: PolicySimple;
    policy_id: string;
    is_folder: boolean;
    is_workspace?: boolean;
    parent_folder: string;
    origin: string;
    origin_context: string;
    error_message: string;
    file_status: FileStatus;
}

export interface OpenFileRequestBody {
    app_name: OpenOptions;
    auth_code?: string;
    always_check_mfa?: boolean;
}
/* eslint-enable camelcase */

export type FilePermissionActions = 'view_logs' | 'manage_access';
export type AllPermissionActions = FilePermissionActions | 'write' | 'read';

export interface CheckAbilityToResult {
    action: AllPermissionActions;
    allowed: boolean;
}

export interface SharedUser {
    userId: string;
    oneTimeAccess?: boolean;
    readOnly: boolean;
    role?: string;
    email?: string;
    deleted?: boolean;
    actions?: FilePermissionActions[];
    recipientType: RecipientType;
}

export type PolicySettingType = 'switch' | 'input' | 'table' | 'tag' | 'list' | 'checkbox' | 'dropdown';

export type PolicySettingValue = boolean | number | string | string[];

export type PolicyDropdownValues = Extract<PolicySettingValue, number | string>[];

export interface PolicySetting {
    public: boolean;
    description: string;
    id: string;
    value: PolicySettingValue;
    type: PolicySettingType;
    values?: PolicyDropdownValues;
    dependency?: string;
}

export interface PolicySettingsAll {
    access: PolicySetting[];
    flow?: PolicySetting[];
    permissions?: PolicySetting[];
}

export type PolicySettingsGroup = keyof PolicySettingsAll;

export interface Policy {
    id: string;
    title: string;
    deletable: boolean;
    isMfa: boolean;
    settings: PolicySettingsAll;
    active: boolean;
}

export interface NumRange {
    min: number;
    max: number;
}

export interface PhoneIdentity {
    phone: string;
    prefix: string;
}

export interface PhonesVector {
    initSnapshot: PhoneIdentity | null;
    currentPhone: PhoneIdentity | null;
}

export interface RecipientPhoneInfo {
    isSearching: boolean;
    phonesVector: PhonesVector;
}

export type RecipientRoles = 'viewer' | 'editor' | 'coOwner';

export type RecipientType = 'user' | 'group';

export interface Recipient {
    email: string; // email or groupName
    id: string; // email or groupId
    role: RecipientRoles;
    type: RecipientType;
}

export enum AWSRegions {
    euCentral = 'eu-central',
    euWest = 'eu-west',
    euNorth = 'eu-north',
}

export interface DropdownItem<V = string | number> {
    value: V;
    label: string;
}

export type SharingBlockersTypes = 'isUploading'
    | 'noFiles'
    | 'noWorkspaces'
    | 'noRecipients'
    | 'missingRecipient'
    | 'missingRecipientInvalid'
    | 'missingPhoneNumber'
    | 'searchPhoneNumber'
    | 'invalidNumber';

export type SyncCallback<T = void> = () => T;

export type AsyncCallback<T = void> = () => Promise<T>;

export type GenericCallback<T> = SyncCallback<T> | AsyncCallback<T>;

export type SimpleCallback = GenericCallback<void>;

export type StorageProvider = 'googledrive' | 'box';

export type StorageProviderLinkable = 'google_linkable' | 'box_linkable';

/* eslint-disable camelcase */
export interface CheckStorageProviderConnectionResponse {
    client_id: string;
}

export interface CheckBoxConnectionResponse extends CheckStorageProviderConnectionResponse {
    box_linkable: boolean;
}

export interface CheckGoogleConnectionResponse extends CheckStorageProviderConnectionResponse {
    google_linkable: boolean;
}
/* eslint-enable camelcase */

export interface StorageConnectionState {
    isDisconnected: boolean;
    clientId?: string;
}

export enum CognitoGroups {
    Externals = 'Externals',
    Auditors = 'Auditors',
    Collaborators = 'Collaborators',
    Administrators = 'Administrators',
}

export enum CognitoGroupsOrganizations {
    Auditors = 'Auditors',
    Collaborators = 'Collaborators',
    Administrators = 'Administrators',
}

export interface OpenFileResponse {
    link: string;
}

export interface AllowedAccessResponse {
    access: OpenOptions[];
}

export interface FileSummary {
    filename: string;
    fid: string;
}

export enum CognitoErrorCodes {
    CodeMismatchException = 'CodeMismatchException',
    InvalidParameterException = 'InvalidParameterException',
    UsernameExistsException = 'UsernameExistsException',
    UserNotConfirmedException = 'UserNotConfirmedException',
    UserNotFoundException = 'UserNotFoundException',
    UserLambdaValidationException = 'UserLambdaValidationException',
    NotAuthorizedException = 'NotAuthorizedException',
    UserNotFoundExceptionSignIn = 'UserNotFoundExceptionSignIn',
    NotAuthorizedExceptionSignIn = 'NotAuthorizedExceptionSignIn',
    NetworkError = 'NetworkError',
    UnexpectedLambdaException = 'UnexpectedLambdaException'
}

export interface CognitoAuthError {
    code: CognitoErrorCodes | string;
    message: string;
    name: CognitoErrorCodes | string;
}

export type OTPAuthType = 'email' | 'phone' | 'voice';

export type TableScrollProps = RcTableProps['scroll'];

export interface GdriveExportState {
    action: string;
    userId: string;
    resourceKeys: Record<string, string>,
    ids?: string[];
    exportIds?: string[];
}

export type ReactRef<T> = ((instance: T | null) => void) | MutableRefObject<T | null>;

export type AuthErrorType = 'oauthSignUp'
    | 'notInvited'
    | 'serverIssue'
    | 'accessDenied'
    | 'adminAccessDenied';
/* eslint-disable camelcase */

export interface FileMetaLegacy {
    filename: string;
    owner_email: string;
    access?: OpenOptions[];
    policy_settings?: Policy;
    is_folder: boolean;
    parent_folder?: string;
}

export interface FileMetadata {
    filename_lowercase: string;
    global_sort: number;
    is_folder: boolean;
    is_new: boolean;
    is_workspace: boolean;
    parent_folder: string;
    creation_time: string;
    current_state: string;
    file_id: string;
    file_size: number;
    file_status: FileStatus;
    download_status: FileStatus;
    filename: string;
    last_used_by: string;
    last_used_time: string;
    owner: string;
    policy_id: string;
    error_message: string;
}

export interface LinkDetails {
    active: boolean;
    creation_time: number;
    file_id?: string;
    link_id: string;
    recp_email: string;
    src_email: string;
    file_meta?: FileMetaLegacy;
    files_ids?: string[];
    message_id?: string;
}

export interface FileDetailsPublic {
    fid: string;
    filename: string;
    policy_id: string;
    owner_email: string;
    is_folder: boolean;
}
/* eslint-enable camelcase */

export type EntityLoadErrorType = 'serverError'
    | 'notFound'
    | 'noPermissions'
    | 'permissionCheckFail';

export interface OfficeViewerResponse {
    viewUrl: string;
    accessToken: string;
    accessTokenTtl: string;
}

export interface FileAccessResponse {
    link: string;
}

export type MFAError = 'MFARequired'
    | 'MFAFailed'
    | 'MFALimitReached';

export type FileAccessError = EntityLoadErrorType
    | MFAError
    | 'unsupportedAccessType'
    | 'noRelatedAccount'
    | 'fileBlocked'
    | 'fileScanning'
    | 'fileBeingPrepared';

export enum AuthStates {
    authenticated = 'authenticated',
    verifyContact = 'verifyContact',
    unauthenticated = 'unauthenticated',
    loading = 'loading',
}

export interface UserIdentity {
    id: string;
    email: string;
}

export interface OauthCodeResponse {
    code: string;
}

type JSONSerializablePrimitive = string | number | boolean;

export interface JSONSerializableRecord {
    [key: string]: JSONSerializablePrimitive
        | Array<JSONSerializablePrimitive | JSONSerializableRecord>
        | JSONSerializableRecord;
}
