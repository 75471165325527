import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from 'mobx';

import AuthSettingsStore from '../../AuthSettingsStore';
import UserStore from '../../UserStore';
import { OpenOptions } from '@/config/openOptions';
import { EntityLoadErrorType, LinkDetails } from '@/types/types';
import {
    AddPermissionsArgs,
    SharedFile,
    FilesListResponse,
    PermissionsErrors,
    PermissionsState,
    PermissionsSummary,
} from './interfaces';
import { BASEURL, ENDPOINTS, getFile } from '../../../api';
import {
    captureErrorForSentry,
    DeferredTask,
    eqUserIdentities,
    getPolicyAggregate,
    PolicyAggregate,
    RequestsQueue,
} from '../../../components/utils';
import {
    captureLinkLoadError,
    checkIsPermissionsError,
    errorToErrorType,
    fetchExistingLink,
    getAllFilesPoliciesAggregate,
    getDebugInfo,
    tryAddPermissionsToFile,
} from './helpers';

export type {
    EntityLoadErrorType,
    PermissionsSummary,
    PermissionsErrors,
    SharedFile,
};
export { getDebugInfo };

const ADD_PERMISSIONS_DEFAULT_ARGS: Required<AddPermissionsArgs> = {
    isSilent: false,
    targetFilesIds: [],
};

class SharedFilesStore {
    constructor(authSettingsStore: AuthSettingsStore, userStore: UserStore) {
        this.authSettingsStore = authSettingsStore;
        this.userStore = userStore;
        this.permissionsCheckQueue = new RequestsQueue<DeferredTask>();
        makeObservable(this);
    }

    private readonly authSettingsStore: AuthSettingsStore;

    private readonly userStore: UserStore;

    private readonly permissionsCheckQueue: RequestsQueue<DeferredTask>;

    @observable
    isLoading = true;

    @observable
    errorType: EntityLoadErrorType = null;

    @observable
    linkData: LinkDetails = null;

    @observable
    linkId: string;

    @observable
    filesList: SharedFile[] = [];

    @observable
    selectedFileId: string;

    @observable
    selectedFileIndex: number;

    @observable
    filesPermissions: Map<string, PermissionsState> = new Map<string, PermissionsState>();

    @observable
    linkErrorObject: unknown;

    @computed
    get allFilesPoliciesAggregate(): PolicyAggregate {
        return getAllFilesPoliciesAggregate(this.filesList);
    }

    @computed
    get isMainDataLoaded(): boolean {
        return !!this.filesList.length || !!this.errorType;
    }

    @computed
    get selectedFilePolicyAggregate(): PolicyAggregate {
        const { selectedFile } = this;
        return selectedFile ? getPolicyAggregate(selectedFile.policy) : null;
    }

    @computed
    get recipientEmail(): string {
        return this.linkData?.recp_email;
    }

    @computed
    get senderEmail(): string {
        return this.linkData?.src_email;
    }

    @computed
    get selectedFile(): SharedFile {
        return this.filesList.find(({ file_id: fileId }) => fileId === this.selectedFileId);
    }

    @computed
    get itemsIds(): string[] {
        return this.filesList.map<string>(({ file_id: fileId }) => fileId);
    }

    @computed
    get fileItemsIds(): string[] {
        return this.filesList.reduce<string[]>((acc, current) => {
            if (!current.is_folder) {
                acc.push(current.file_id);
            }
            return acc;
        }, []);
    }

    @computed
    get filesWithDownloadOptionIds(): string[] {
        return this.filesList.reduce<string[]>((acc, current) => {
            if (
                current.is_folder
                || current.is_workspace
                || current.allowed_apps?.includes(OpenOptions.download)
            ) {
                acc.push(current.file_id);
            }
            return acc;
        }, []);
    }

    @computed
    get downloadableFilesIds(): string[] {
        return this.filesWithDownloadOptionIds.filter((fileId) => (
            !checkIsPermissionsError(this.filesPermissions.get(fileId)?.summary)
        ));
    }

    @computed
    get allFilesCount(): number {
        return this.filesList.length;
    }

    @computed
    get selectedFilePermissions(): PermissionsState {
        return this.filesPermissions.get(this.selectedFileId);
    }

    @computed
    get selectedFilePermissionsSummary(): PermissionsSummary {
        return this.selectedFilePermissions?.summary;
    }

    @computed
    get baseDebugInfo(): Record<string, string> {
        return {
            linkId: this.linkId,
            userId: this.userStore.currentUserId,
            email: this.userStore.currentUserEmail,
        };
    }

    @computed
    get linkDebugInfo(): Record<string, string> {
        return getDebugInfo(this.linkErrorObject, this.baseDebugInfo);
    }

    @action
    setIsLoading = (isLoading: boolean): void => {
        this.isLoading = isLoading;
    }

    @action
    setErrorType = (errorType: EntityLoadErrorType): void => {
        this.errorType = errorType;
    }

    @action
    setLinkData = (linkData: LinkDetails): void => {
        this.linkData = linkData;
    }

    @action
    setLinkId = (linkId: string): void => {
        this.linkId = linkId;
    }

    @action
    setFilesList = (files: SharedFile[]): void => {
        this.filesList = files;
    }

    @action
    setSelectedFile = (fileId: string): void => {
        this.selectedFileId = fileId;
        this.selectedFileIndex = this.filesList.indexOf(this.selectedFile);
    }

    @action
    setFilePermission = (fileId: string, permission: PermissionsState): void => {
        this.filesPermissions.set(fileId, permission);
    }

    @action
    setLinkErrorObject = (value: unknown): void => {
        this.linkErrorObject = value;
    }

    tryFetchLink = async (): Promise<void> => {
        this.setIsLoading(true);
        try {
            await this.fetchLinkData();
            await this.fetchFiles();
            // TODO: provide way to run it in parallel with fetchFiles()
            if (this.userStore.isUserSetUp) {
                await this.tryBatchAddPermissions({ isSilent: true });
            }
        } catch (error) {
            this.setLinkErrorObject(error);
            const errorType = errorToErrorType(error);
            captureLinkLoadError(errorType, error);
            this.setErrorType(errorType);
        }
        this.setIsLoading(false);
    }

    tryBatchAddPermissions = async ({
        isSilent = false,
        targetFilesIds = [],
    }: AddPermissionsArgs = ADD_PERMISSIONS_DEFAULT_ARGS): Promise<void> => {
        const {
            linkId,
            authSettingsStore: { API },
            userStore: { currentUserEmail },
        } = this;
        if (!isSilent) {
            this.setIsLoading(true);
        }
        const filesIds: string[] = targetFilesIds?.length
            ? targetFilesIds
            : this.itemsIds;

        runInAction(() => {
            filesIds.forEach((fileId) => {
                this.filesPermissions.set(fileId, { summary: 'loading' });
            });
        });

        await Promise.all<void>(filesIds.map(async (fileId) => {
            await this.permissionsCheckQueue.processSimpleRequest(
                { id: fileId },
                async () => {
                    const permissionsState = await tryAddPermissionsToFile(API, {
                        fileId,
                        email: currentUserEmail,
                        linkId,
                    });
                    this.setFilePermission(fileId, permissionsState);
                    return true;
                },
            );
        }));
        if (!isSilent) {
            this.setIsLoading(false);
        }
    }

    async tryRefreshPermissions(fileId: string): Promise<void> {
        const file = this.filesList.find(({ file_id: id }) => id === fileId);
        const { currentUserIdentity } = this.userStore;
        const { API } = this.authSettingsStore;
        if (file && !eqUserIdentities(currentUserIdentity, { id: file.owner, email: file.owner_email })) {
            try {
                // TODO: update options based on this refresh
                const refreshedFile = await getFile(API, fileId);
                this.updateFilePermissions(fileId, { editAllowed: refreshedFile.menu?.read_only === false });
            } catch (error) {
                console.log('could not fetch file permissions', error);
                captureErrorForSentry(error, 'SharedFilesStore.tryRefreshPermissions');
            }
        }
    }

    private async fetchLinkData(): Promise<void> {
        const { API } = this.authSettingsStore;
        const linkDetails = await fetchExistingLink(API, this.linkId);
        this.setLinkData(linkDetails);
    }

    private async fetchFiles(): Promise<void> {
        const { API } = this.authSettingsStore;
        let filesIds: string[] = this.linkData.files_ids;
        if (!filesIds?.length) {
            filesIds = [this.linkData.file_id];
        }
        if ((filesIds?.length && !filesIds[0]) && this.linkData.message_id) {
            filesIds = [this.linkData.message_id];
        }
        const { files }: FilesListResponse = await API.get(
            BASEURL.backend(),
            ENDPOINTS.getMultipleFiles(filesIds),
            {},
        );
        this.setFilesList(files);
    }

    private updateFilePermissions(fileId: string, statePartial: Partial<PermissionsState>): void {
        const permissions = this.filesPermissions.get(fileId);
        if (permissions) {
            this.setFilePermission(fileId, { ...permissions, ...statePartial });
        }
    }
}

export default SharedFilesStore;
