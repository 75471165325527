import React, { FC, useEffect, useRef, useState } from 'react';

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { message } from 'antd';
import { Trans } from 'react-i18next';
import { Auth } from 'aws-amplify';

import i18n from '@/content';
import { Loading } from '../../Common/Loading';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import ForgotPassword from '../ForgotPassword';
import ConfirmUser from '../ConfirmUser';
import { PublicFooter, PublicHeader } from '../../Common/PublicComponents';
import OTPSignIn from '../OTPSignIn';
import { withRouter, WithRouterProps } from '@/components/HOC';
import { CognitoAuthError, CognitoErrorCodes } from '@/types/types';
import { IAuthenticatorChildrenProps, SignInOptions } from '../interfaces';
import { authErrorMessageToI18nPayload, captureCognitoError, onSpacePress } from '../../utils';
import './AuthContainer.scss';
import { useStores } from '@/components/hooks';
import useComponentDidUpdate from '../../hooks/useComponentDidUpdate';

const emailConfirmationNameSpace = 'emailConfirmation';
export const LONG_MESSAGE_DURATION_SECONDS = 60;

export interface AuthContainerProps extends IAuthenticatorChildrenProps, WithRouterProps {}

const AuthContainer: FC<IAuthenticatorChildrenProps> = (props) => {
    const { appStore } = useStores();
    const location = useLocation();
    const navigate = useNavigate();

    const [isNotConfirmed, setIsNotConfirmed] = useState<boolean>(false);
    const [confirmationUsername, setConfirmationUsername] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [userPassword, setUserPassword] = useState<string>('');

    useComponentDidUpdate(() => {
        const { setErrorStatus } = appStore;
            setErrorStatus(false);
            setIsNotConfirmed(false);
            setConfirmationUsername('');
        }, [location]);

    useEffect(() => {
        return () => {
            appStore.setErrorStatus(false);
        }
    }, []);

    const clickConfirm = async (): Promise<void> => {
        const { appStore: { setLoadingStatus, setErrorStatus } } = props;
        try {
            setLoadingStatus(true, i18n.t(`${emailConfirmationNameSpace}.sendingCode`));
            await Auth.resendSignUp(confirmationUsername);
            setErrorStatus(false);
            setIsNotConfirmed(false);
            setConfirmationUsername('');
            message.success(i18n.t(`${emailConfirmationNameSpace}.pleaseCheckEmail`));
        } catch (error) {
            captureCognitoError(error, 'AuthContainer.clickConfirm');
            message.error(i18n.t(`${emailConfirmationNameSpace}.couldNotSend`, { confirmationUsername }));
        } finally {
            setLoadingStatus(false);
        }
    }

    const signInCalls = async (
        authWorker: () => Promise<any>,
        nameSpace: string,
        options: SignInOptions,
    ): Promise<boolean> => {
        const { username, isFederated, password } = options;
        const { setLoadingStatus, setErrorStatus } = appStore;

        try {
            setIsNotConfirmed(false);
            setConfirmationUsername('');
            setErrorStatus(false);
            setLoadingStatus(true, i18n.t(`${nameSpace}.loading`));
            const user = await authWorker();
            if (user && nameSpace === 'signUp') {
                message.success(i18n.t(`${emailConfirmationNameSpace}.emailSent`), LONG_MESSAGE_DURATION_SECONDS);
            }
            // We shouldn't stop loading for federated sign in, because in this case we got response before redirect
            if (!isFederated) {
                setLoadingStatus(false);
                setErrorStatus(false);
            }
            return true;
        } catch (error) {
            captureCognitoError(error, 'AuthContainer.signInCalls');
            console.log('signInCalls error', error);
            const { code: errorCode, message: cognitoErrorMessage } = error as CognitoAuthError;
            let errorMessage = '';
            if (errorCode === CognitoErrorCodes.UsernameExistsException) {
                setUserName(username);
                setUserPassword(password);
                await Auth.forgotPassword(username.toLowerCase());
                navigate('/confirmUser')
                setLoadingStatus(false);
                return null;
            }
            if (errorCode === CognitoErrorCodes.UserNotConfirmedException) {
                setIsNotConfirmed(true);
                setConfirmationUsername(username);
            } else {
                const [key, i18nOption] = authErrorMessageToI18nPayload(cognitoErrorMessage, () => {
                    const i18nErrorCode = nameSpace === 'signIn' ? `${errorCode}SignIn` : errorCode;
                    const errorType = i18n.exists(`auth.errors.${i18nErrorCode}`)
                        ? i18nErrorCode
                        : 'serverIssue';
                    return [`auth.errors.${errorType}`];
                });
                errorMessage = i18n.t(key, i18nOption);
            }

            if (nameSpace !== 'Otp') {
                setErrorStatus(true, '', errorMessage || cognitoErrorMessage);
            }
            setLoadingStatus(false);

            return false;
        }
    };

    const { errorStatus, loadingStatus } = appStore;
    const { hasError, errorMessage } = errorStatus;

    const baseSignInPageProps = {
        signInCalls,
        location,
        navigate,
        ...props
    };

    return (
        <div className="auth-container">
            <div className="root-auth-container">
                <PublicHeader />
                <div className="content-wrapper">
                    {loadingStatus.isLoading && <Loading fullScreen loadingText="Please wait..." />}
                    <Routes>
                        <Route
                            path="/signIn"
                            element={<SignIn {...baseSignInPageProps} />}
                        />
                        <Route
                            path="/gdrive/share"
                            element={<SignIn gdrive {...baseSignInPageProps} />}
                        />
                        <Route
                            path="/box/share"
                            element={<SignIn box {...baseSignInPageProps} />}
                        />
                        <Route
                            path="/signUp"
                            element={<SignUp signInCalls={signInCalls} location={location} navigate={navigate} {...props} />}
                        />
                        <Route
                            path="/forgotPassword"
                            element={<ForgotPassword signInCalls={signInCalls} />}
                        />
                        <Route
                            path="/OTPSignIn"
                            element={<OTPSignIn />}
                        />
                        <Route
                            path="/confirmUser"
                            element={<ConfirmUser userName={userName} userPassword={userPassword}/>}
                        />
                        <Route
                            path="*"
                            element={(
                                <Navigate
                                    to={{ search: window.location.search, pathname: '/signIn' }}
                                    replace
                                />
                            )}
                        />
                    </Routes>
                    {hasError && (
                        <div className="error-container">
                            {isNotConfirmed
                                ? (
                                    <Trans i18nKey="auth.errors.notConfirmed">
                                        Please confirm email
                                        <span
                                            role="button"
                                            tabIndex={0}
                                            key="check"
                                            className="confirmation"
                                            onClick={clickConfirm}
                                            onKeyUp={(event) => onSpacePress(event, clickConfirm)}
                                        >
                                                Resend code
                                            </span>
                                        if you have not got one
                                    </Trans>
                                )
                                : <span>{errorMessage}</span>}
                        </div>
                    )}
                </div>
                <PublicFooter />
            </div>
        </div>
    );
};
export default AuthContainer;
