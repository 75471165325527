import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
    when,
} from 'mobx';
import { UserManager } from 'oidc-client';
import { Auth } from 'aws-amplify';
import { configureScope } from '@sentry/react';

import AuthSettingsStore from '../AuthSettingsStore';
import {
    AuthStates,
    CognitoGroups,
    CognitoUser,
    SpecterXUser,
    UserIdentity,
    WSOUser,
} from '@/types/types';
import { UserGroupsResponse, UserInformation } from './interfaces';
import { IS_WSO as IS_WSO_INITIAL } from '../../config/env';
import { BASEURL, ENDPOINTS } from '../../api';
import { captureErrorForSentry, postAuthMessage, sleep } from '../../components/utils';
import { getUserAttributes, getWSOGroups, tryGetCognitoGroups } from './helpers';

// TODO: increase this value if MFA cache removing won't work
const LOGOUT_MAX_DELAY_MS = 1_500;

const USER_INFORMATION_INIT: UserInformation = {
    email: '',
    familyName: '',
    givenName: '',
    userId: '',
    displayName: '',
    groups: [],
};

class UserStore {
    constructor(authSettingsStore: AuthSettingsStore, userManager?: UserManager) {
        this.authSettingsStore = authSettingsStore;
        if (IS_WSO_INITIAL) {
            userManager.events.addSilentRenewError(async (error) => {
                console.log('silent renew error', error);
                await this.onSilentRenewError();
                await this.login();
            });
            userManager.events.addUserSignedOut(async () => {
                await this.resetUser();
            });
            userManager.events.addUserSessionChanged(async () => {
                await this.updateSession();
            });
            this.userManager = userManager;
        }
        makeObservable(this);
    }

    private readonly authSettingsStore: AuthSettingsStore;

    public userManager?: UserManager;

    @observable authState: AuthStates = AuthStates.loading;

    @observable hasDoubleAuthError = false;

    @observable userInformation: UserInformation = { ...USER_INFORMATION_INIT };

    @computed
    get currentUserEmail(): string {
        return this.userInformation?.email;
    }

    @computed
    get currentUserId(): string {
        return this.userInformation?.userId;
    }

    @computed
    get currentUserIdentity(): UserIdentity {
        return {
            id: this.currentUserId,
            email: this.currentUserEmail,
        };
    }

    @computed
    get userGroupsSet(): Set<CognitoGroups> {
        return new Set<CognitoGroups>((this.userInformation?.groups) || []);
    }

    @computed
    get isExternal(): boolean {
        const { userGroupsSet } = this;
        return userGroupsSet.size ? userGroupsSet.has(CognitoGroups.Externals) : true;
    }

    @computed
    get isAuditor(): boolean {
        const { userGroupsSet } = this;
        return userGroupsSet.size ? userGroupsSet.has(CognitoGroups.Auditors) : true;
    }

    @computed
    get isAdmin(): boolean {
        const { userGroupsSet } = this;
        return userGroupsSet.size ? userGroupsSet.has(CognitoGroups.Administrators) : true;
    }

    @computed
    get isUserSetUp(): boolean {
        return !!this.userInformation?.userId;
    }

    private resetUser = async (): Promise<void> => {
        try {
            await this.userManager.revokeAccessToken();
        } catch (error) {
            console.log('Error while token revoke', error);
        }
        await this.userManager.removeUser();
        await this.userManager.clearStaleState();
        this.clearUserInformation();
        this.setAuthState(AuthStates.unauthenticated);
    }

    private updateSession = async (): Promise<void> => {
        const user = await this.userManager.getUser();
        console.log('session has been updated, user is', user);
        if (user) {
            await this.setUserInformation(user);
            await this.userManager.clearStaleState();
        } else {
            await this.resetUser();
        }
    }

    subscribeOnceOnSetUser = (): Promise<void> => (
        when(() => !!this.currentUserEmail)
    );

    setUserInformation = async (user: SpecterXUser): Promise<void> => {
        const { IS_WSO } = this.authSettingsStore;
        const userAttributes = await getUserAttributes(user, IS_WSO);
        let WSOGroups: CognitoGroups[] = [];
        if (IS_WSO) {
            console.log('user attributes are', userAttributes);
            const groups = await this.tryGetCognitoGroups(userAttributes.sub);
            console.log('groups', groups);
            WSOGroups = groups.length ? groups : getWSOGroups(userAttributes.groups);
        }
        /* eslint-disable camelcase */
        const {
            email, preferred_username, family_name, given_name, sub,
        } = userAttributes;
        const displayName = `${given_name || ''} ${family_name || ''}`.trim() || sub;
        const userEmail = email || preferred_username || '';
        /* eslint-enable camelcase */
        runInAction(() => {
            this.userInformation = {
                email: userEmail,
                familyName: family_name,
                givenName: given_name,
                displayName,
                userId: sub,
                groups: IS_WSO
                    ? WSOGroups
                    : tryGetCognitoGroups(user as CognitoUser),
            };
        });
        configureScope((scope) => {
            scope.setUser({
                id: sub,
                email: userEmail,
                displayName: given_name,
            });
        });
    }

    @action
    clearUserInformation = (): void => {
        this.userInformation = { ...USER_INFORMATION_INIT };
    }

    @action
    setDoubleAuthError = (val: boolean): void => {
        this.hasDoubleAuthError = val;
    }

    onSilentRenewError = async (): Promise<void> => {
        console.log('on Silent renew called');
        await this.resetUser();
    }

    login = async (): Promise<void> => {
        await this.userManager.signinRedirect();
    }

    renewToken = async (): Promise<void> => {
        const user = await this.userManager.signinSilent();
        await this.setUserInformation(user);
        this.setAuthState(AuthStates.authenticated);
    }

    continueLogin = async (): Promise<void> => {
        const user = await this.userManager.signinRedirectCallback();
        await this.saveWSOUserInfo(user);
        await this.userManager.clearStaleState();
        this.setAuthState(AuthStates.authenticated);
    }

    private async saveWSOUserInfo(user: WSOUser): Promise<void> {
        const { API } = this.authSettingsStore;
        await this.setUserInformation(user);
        const userAttributes = await getUserAttributes(user, true);

        /* eslint-disable camelcase */
        const {
            email, preferred_username, sub, family_name, given_name, groups,
        } = userAttributes;
        console.log('setWSOUserInfo, WSO attributes are', userAttributes);
        const wsoGroups = getWSOGroups(groups);
        console.log('setWSOUserInfo, WSO groups', wsoGroups);
        const userEmail = email || preferred_username || '';
        /* eslint-enable camelcase */
        const userInfo = {
            user_id: sub,
            email: userEmail.toLowerCase(),
            username: sub,
            given_name,
            family_name,
            preferred_username,
            group: wsoGroups,
            source: 'wso',
        };
        try {
            await API.post(BASEURL.backend(), ENDPOINTS.createUser(), { body: { user: userInfo } });
        } catch (error) {
            console.log('could not create WSO user entity', error);
        }
    }

    logout = async (): Promise<void> => {
        await this.userManager.signoutRedirect();
    }

    completeLogout = async (): Promise<void> => {
        await this.userManager.signoutRedirectCallback();
    }

    @action
    setAuthState = (newState: AuthStates): void => {
        this.authState = newState;
    }

    cognitoSignOut = async (): Promise<void> => {
        // This is experimental solution. It could not work with slow network
        await Promise.race<void>([this.tryRemoveUserCache(), sleep(LOGOUT_MAX_DELAY_MS)]);
        try {
            postAuthMessage({ type: 'signOut' });
            localStorage.setItem('signedOut', 'true');
            await Auth.signOut();
            this.setAuthState(AuthStates.unauthenticated);
        } catch (error) {
            console.log('sign out fail', error);
        }
    }

    private async tryRemoveUserCache(): Promise<void> {
        const { currentUserId } = this;
        const { API, skipMFA } = this.authSettingsStore;
        if (!skipMFA && currentUserId) {
            try {
                await API.del(BASEURL.backend(), ENDPOINTS.clearUserCache(currentUserId), {});
            } catch (error) {
                console.log('Could not clear cache', error);
                captureErrorForSentry(error, 'AuthSettingsStore.tryRemoveUserCache');
            }
        }
    }

    private async tryGetCognitoGroups(userId: string): Promise<CognitoGroups[]> {
        const { API } = this.authSettingsStore;
        const groups: CognitoGroups[] = [];
        const userIdLower = userId.toLowerCase();
        try {
            const groupsDict: UserGroupsResponse = await API.get(
                BASEURL.backend(),
                ENDPOINTS.getUserGroups(userIdLower),
                {},
            );
            groups.push(...Object.keys(groupsDict) as CognitoGroups[]);
        } catch (e) {
            console.log('could not receive user entity', e);
        }
        return groups;
    }
}

export default UserStore;
