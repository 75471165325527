import {
    SPXEndpoint,
    SPXAPI,
    RequestConfig,
    APIName,
    APIType,
} from './index';

type Method = 'get' | 'post' | 'put' | 'patch' | 'del';

export interface SPXAPIWithEndpointsResolving extends SPXAPI {
    toggleHasApiProxy: (HAS_API_PROXY: boolean) => void;
    extract: () => APIType;
}

class EndpointsResolver implements SPXAPIWithEndpointsResolving {
    /*
    * Decorator for API instance, which add /ext/ postfix and resolve API URL for API_PROXY users
    * */
    constructor(private HAS_API_PROXY: boolean, private readonly API: APIType) {}

    static resolveEndpoint(endpoint: SPXEndpoint, HAS_API_PROXY: boolean): string {
        let endpointStr: string;
        if (typeof endpoint === 'string') {
            endpointStr = endpoint;
        } else {
            const [serviceName, secondPart] = endpoint;

            if (HAS_API_PROXY) {
                endpointStr = `${serviceName}ext/${secondPart}`;
            } else {
                endpointStr = serviceName + secondPart;
            }
        }
        return endpointStr;
    }

    static resolveApiName(apiName: APIName, HAS_API_PROXY: boolean): APIName {
        let result: APIName = 'specterx';

        switch (apiName) {
        case 'localhost':
        case 'specterxProxy':
            result = apiName;
            break;
        case 'specterx':
            result = HAS_API_PROXY ? 'specterxProxy' : 'specterx';
            break;
        default:
            break;
        }
        return result;
    }

    private async APICall(
        method: Method,
        apiName: APIName,
        endpoint: SPXEndpoint,
        init: RequestConfig,
    ): Promise<any> {
        const endpointStr = EndpointsResolver.resolveEndpoint(endpoint, this.HAS_API_PROXY);
        const resolvedAPIName = EndpointsResolver.resolveApiName(apiName, this.HAS_API_PROXY);
        const emailFields = ['email', 'new_lead_email', 'new_lead_real_login_email'];
        emailFields.forEach((fieldName) => {
            if (init?.body?.[fieldName]) {
                init.body[fieldName] = init.body[fieldName].toLowerCase();
            }
        });
        return this.API[method](resolvedAPIName, endpointStr, init);
    }

    async get(apiName: APIName, endpoint: SPXEndpoint, init: RequestConfig): Promise<any> {
        return this.APICall('get', apiName, endpoint, init);
    }

    async del(apiName: APIName, endpoint: SPXEndpoint, init: RequestConfig): Promise<any> {
        return this.APICall('del', apiName, endpoint, init);
    }

    async patch(apiName: APIName, endpoint: SPXEndpoint, init: RequestConfig): Promise<any> {
        return this.APICall('patch', apiName, endpoint, init);
    }

    async put(apiName: APIName, endpoint: SPXEndpoint, init: RequestConfig): Promise<any> {
        return this.APICall('put', apiName, endpoint, init);
    }

    async post(apiName: APIName, endpoint: SPXEndpoint, init: RequestConfig): Promise<any> {
        return this.APICall('post', apiName, endpoint, init);
    }

    toggleHasApiProxy(HAS_API_PROXY: boolean): void {
        this.HAS_API_PROXY = HAS_API_PROXY;
    }

    extract(): APIType {
        /*
        * In some cases, like request canceling, we need to get API instance directly.
        * Pay attention, that in this case you'll need to resolve endpoint manually.
        * */
        return this.API;
    }
}

export default EndpointsResolver;
