import { PermissionsError, captureWarningForSentry } from '../../../../components/utils';
import { addPermissionsToFile, AddPermissionsParams, SPXAPI } from '../../../../api';
import { PermissionsState } from '../interfaces';

type PermissionsStatePartial = Pick<PermissionsState, 'summary' | 'errorObject'>;

const tryAddPermissionsToFile = async (
    API: SPXAPI,
    params: AddPermissionsParams,
): Promise<PermissionsStatePartial> => {
    const result: PermissionsStatePartial = {
        summary: 'permissionsAdded',
    };
    try {
        await addPermissionsToFile(API, params, (error) => {
            result.errorObject = error;
        });
    } catch (error) {
        captureWarningForSentry(error, 'SharedFilesStore.addPermissions');
        result.summary = error instanceof PermissionsError
            ? 'noPermissions'
            : 'permissionCheckFail';
    }
    return result;
};

export default tryAddPermissionsToFile;
