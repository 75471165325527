import { IS_NON_PROD_ENV } from '../config/env';
import { StorageProvider } from '../types/types';

export const FULL_PERCENTS = 100;
export const MILLISECONDS_IN_SECOND = 1000;
/* eslint-disable @typescript-eslint/no-magic-numbers */
export const MILLISECONDS_IN_MINUTE = 60 * MILLISECONDS_IN_SECOND;
export const MILLISECONDS_IN_DAY = 24 * 60 * 60 * MILLISECONDS_IN_SECOND;

const REDIRECT_WINDOW_MINUTES = IS_NON_PROD_ENV ? 2 : 30;
export const REDIRECT_WINDOW = REDIRECT_WINDOW_MINUTES * MILLISECONDS_IN_MINUTE;

export const ROOT_FOLDER = '00000000-0000-0000-0000-000000000000';
export const FOLDER_ID_QUERY_PARAM = 'folder_id';

export const ORG_USER_SIGN_IN_INITIATED = 'orgUserSignInInitiated';
export const AUTO_SIGN_IN_INITIATED = 'autoSignInInitiated';
export const NEXT_PAGE_AFTER_SIGN_IN = 'nextPageAfterSignIn';
export const START_SIGN_IN_IMMEDIATELY = 'start-sign-in-immediately';
export const FEDERATED_PROVIDER = 'federatedProvider';

export const CURRENT_ORGANIZATION = 'currentOrganization';

export const MFA_ATTEMPTS = 3;

export const ALLOW_FORWARDING = 'allowForwarding';
export const ALLOW_FORWARDING_IN_DOMAIN = 'allowForwardingInDomain';
export const PREVENT_DOWNLOAD_UNPROTECTED = 'preventDownloadUnprotected';
export const PROTECT_ON_DOWNLOAD = 'protectOnDownload';
export const REQUIRED_PHONE_VERIFICATION = 'requirePhoneVerification';
export const REQUIRED_EMAIL_VERIFICATION = 'requireEmailVerification';
export const LANGUAGE_POLICY = 'language';
export const REQUEST_RECIPIENT_DOWNLOAD_APPROVAL = 'requestRecipientApproval';
export const VIEWER_ONLY = 'viewerOnly';
export const APPLY_WATERMARK = 'applyWatermark';
export const DATA_RETENTION = 'dataRetention';
export const DAYS_RETENTION_VALUE = 'dataRetention.Value';
export const AUTO_SHARING_VALUE = 'autoSharing.Value';
export const LIMIT_TO_USERS_VALUE = 'limitToUsers.Value';
export const ALLOW_SIGNUP_EMAIL = 'allowSignupEmails';
export const ADMIN_PANES_IPS_RESTRICTION = 'adminPanesIPRestriction';

export const MAP_ORIGIN_TO_LOCAL_STORAGE_KEY: Record<StorageProvider, string> = {
    googledrive: 'googleState',
    box: 'boxState',
};

export const PUBLIC_ASSETS = '/assets';

export const SPX_SUPPORT_EMAIL = 'support@specterx.com';
export const SPX_INFO_EMAIL = 'info@specterx.com';

export const KILOBYTE = 1024;
export const MEGABYTE = KILOBYTE * KILOBYTE;
export const GIGABYTE = MEGABYTE * KILOBYTE;
