import { useState, useEffect, MutableRefObject } from 'react';

import { VIEWER_ONLY, APPLY_WATERMARK } from '@/consts';
import { useStores } from '../../../hooks';
import PolicyStore from '../../../../stores/PolicyStore';
import appConfig from '../../../../config/env';
import { createBase64Image, DEFAULT_WATERMARK_BASE64_SRC, loadImage } from '../helpers';

const tryLoadLogo = async (): Promise<HTMLImageElement> => {
    let logo: HTMLImageElement;
    try {
        logo = await loadImage(appConfig.HEADER_COMPANY_LOGO);
    } catch (error) {
        console.log('Fail to load image, using default watermark', error);
        logo = createBase64Image(DEFAULT_WATERMARK_BASE64_SRC);
    }
    return logo;
};

const tryCheckHasWatermarkPolicy = async (policyId: string, policyStore: PolicyStore): Promise<boolean> => {
    const policy = await policyStore.tryFindPolicy(policyId);
    if (policy) {
        const accessList = policy.settings?.access;
        const parentViewerPolicy = accessList?.find(({ id }) => id === VIEWER_ONLY);
        return parentViewerPolicy.value as boolean
            && accessList?.find(({ id }) => id === APPLY_WATERMARK)?.value as boolean;
    }
    return false;
};

interface Watermarks {
    logo: HTMLImageElement;
    email: string;
}

interface UseWatermarksParams {
    filePolicyId: string;
    isMountedRef: MutableRefObject<boolean>;
    setIsLoading: (value: boolean) => void;
}

const useWatermarks = ({
    setIsLoading,
    filePolicyId,
    isMountedRef,
}: UseWatermarksParams): Watermarks => {
    const [watermarks, setWatermarks] = useState<Watermarks>(null);

    const { userStore, policyStore } = useStores();

    useEffect(() => {
        (async (): Promise<void> => {
            setIsLoading(true);
            const [logo, hasWatermarkPolicy] = await Promise.all([
                tryLoadLogo(), tryCheckHasWatermarkPolicy(filePolicyId, policyStore),
            ]);
            if (isMountedRef.current) {
                setWatermarks({
                    logo,
                    email: hasWatermarkPolicy ? userStore.currentUserEmail : null,
                });
                setIsLoading(false);
            }
        })();
    }, []);

    return watermarks;
};

export default useWatermarks;
