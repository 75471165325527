import React, { FC } from 'react';

import { Divider } from 'antd';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { CodeAuthenticatorSnapshotProvider } from '@/components/Common/CodeAuthenticator';
import { OTPAuth } from '../../../Common';
import {
    useStores,
    useTargetScreen,
} from '@/components/hooks';

import { FileAccessPopupsProvider } from './FileAccessPopupsContext';
import FilesActionsProvider from './FilesActionsContext';
import FilesRow from './FilesRow';
import OpenSecurely from './FilesRow/ActionsColumn/OpenSecurely';
import FilesCarousel from './FilesCarousel';
import Greeting from './Greeting';
import { MessageView } from './MessageView';
import {
    useVerifyAction,
    useOTPState,
    useOTPToggle,
    useDownloadActionError,
} from './hooks';
import { getContentType } from './helpers';
import styles from './LinkView.module.scss';

const LinkView: FC = observer(() => {
    const isMobile = useTargetScreen('md');

    const {
        authSettingsStore: { API },
        recipientPageStore: { sharedFilesStore, emailMessageStore },
        filesAccessStore,
        userStore,
    } = useStores();

    const {
        allFilesPoliciesAggregate,
        recipientEmail,
        selectedFilePolicyAggregate,
        setIsLoading,
        allFilesCount,
    } = sharedFilesStore;
    const { selectedAccessFileId } = filesAccessStore;
    const { messageId } = emailMessageStore;

    const {
        showOTP,
        closeOTP,
        openOTP,
    } = useOTPState();

    const {
        verifyAccess,
        postAuthAction,
        setAction,
        lastAccessType,
    } = useVerifyAction(sharedFilesStore, API);

    useOTPToggle({
        showOTP,
        closeOTP,
        openOTP,
        filesAccessStore,
        sharedFilesStore,
        userStore,
        lastAccessType,
        postAuthAction,
        messageId,
    });

    useDownloadActionError(filesAccessStore, sharedFilesStore, openOTP);

    const policiesAggregate = selectedFilePolicyAggregate && lastAccessType !== 'batchDownload'
        ? selectedFilePolicyAggregate
        : allFilesPoliciesAggregate;

    const contentType = getContentType({
        showOTP,
        selectedFileId: selectedAccessFileId === messageId ? null : selectedAccessFileId,
        isAuthorized: userStore.isUserSetUp,
    });

    const showOpenViewerButton = contentType !== 'fileView';

    return (
        <FilesActionsProvider
            openOTP={openOTP}
            setAction={setAction}
        >
            <FileAccessPopupsProvider>
                <CodeAuthenticatorSnapshotProvider>
                    <div className={styles.container}>
                        {contentType === 'fileView' ? (
                            <FilesCarousel />
                        ) : (
                            <div className={styles['main-content-wrapper']}>
                                <div className={styles['main-content-column']}>
                                    <Greeting />
                                    <Divider
                                        className={classNames({
                                            [styles.divider]: !isMobile,
                                            [styles['mobile-divider']]: isMobile,
                                        })}
                                    />
                                    {contentType === 'message' && <MessageView />}
                                    {contentType === 'OTP' && (
                                        <OTPAuth
                                            OTPType="multipleFiles"
                                            recpEmail={userStore.currentUserEmail || recipientEmail}
                                            setIsLoading={setIsLoading}
                                            closeOTP={closeOTP}
                                            onSuccess={closeOTP}
                                            policyAggregate={policiesAggregate}
                                            openFile={verifyAccess}
                                            onMFASuccess={closeOTP}
                                            hasBackButton={false}
                                            hideTitleOnMobile={isMobile}
                                        />
                                    )}
                                </div>
                                {isMobile && !!allFilesCount && <OpenSecurely />}
                            </div>
                        )}
                        <FilesRow
                            showOpenViewerButton={showOpenViewerButton}
                            contentType={contentType}
                        />
                    </div>
                </CodeAuthenticatorSnapshotProvider>
            </FileAccessPopupsProvider>
        </FilesActionsProvider>

    );
});

export default LinkView;
