import { AccessApps, Operations, PageSize } from '@/stores/AuditsStore/interaces';
import { OpenOptions } from '@/config/openOptions';
import { DropdownItem } from '@/types/types';

export const MAP_OPERATION_TYPE_TO_COLOR: Partial<Record<Operations, string>> = {
    [Operations.open]: 'green',
    [Operations.login]: 'geekblue',
    [Operations.download]: 'green',
    [Operations.upload]: 'magenta',
    [Operations.export]: 'magenta',
    [Operations.signup]: 'geekblue',
    [Operations.logout]: 'geekblue',
    [Operations.create]: 'magenta',
    [Operations.delete]: 'cyan',
    [Operations.permissionsadded]: 'blue',
    [Operations.createpolicy]: 'geekblue',
    [Operations.setpolicy]: 'purple',
    [Operations.editpolicy]: 'purple',
    [Operations.permissionsremoved]: 'blue',
    [Operations.deleteuser]: 'red',
    [Operations.modifyuserrole]: 'orange',
    [Operations.loginfailed]: 'red',
    [Operations.possibleleak]: 'red',
    [Operations.blocked]: 'cyan',
    [Operations.removeusersfromgroup]: 'red',
    [Operations.emailsent]: 'blue',
    [Operations.emailprocessing]: 'blue',
    [Operations.emailerror]: 'red',
    [Operations.workspacecreated]: 'magenta',
    [Operations.foldercreated]: 'magenta',
};

export const MAP_ACCESS_APP_TO_COLOR: Partial<Record<AccessApps, string>> = {
    [OpenOptions.googledrive]: 'blue',
    [OpenOptions.download]: 'magenta',
    [OpenOptions.office365]: 'geekblue',
    Salesforce: 'cyan',
    sharepoint: '#87d068',
};

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const PAGE_SIZES: PageSize[] = [10, 15, 25, 50];
export const PAGE_SIZES_TO_DROPDOWN: DropdownItem<number>[] = (
    PAGE_SIZES.map((value) => ({ value, label: String(value) }))
);

export const RECORDS_TO_CSV = 500;

export const NAME_SPACE = 'auditLogs';
