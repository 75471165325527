import React, { FC, memo } from 'react';

import { Trans } from 'react-i18next';

import styles from './FilesTypeDisclaimer.module.scss';

interface FilesTypeDisclaimerProps {
    fieldName: string;
}

const FilesTypeDisclaimer: FC<FilesTypeDisclaimerProps> = ({ fieldName }) => (
    <div className={styles['disclaimer-style']}>
        <Trans i18nKey={`policyEditor.${fieldName}.description`}>
            <strong>supported file extension</strong>
            extensions
            <strong>condition for unsupported</strong>
        </Trans>
    </div>
);

export default memo(FilesTypeDisclaimer);
