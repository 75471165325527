import isEmpty from 'lodash/isEmpty';
import { TFunction } from 'i18next';

import { I18Payload } from '@/content';
import { BASEURL, ENDPOINTS, SPXAPI } from '../../../api';
import {
    ErrorResult,
    FileAccessParams,
    RequestResult,
    ResultDescription,
    ResultType,
    ShareRequestParams,
} from './interfaces';
import { captureUnexpectedNetworkError, getErrorResponse } from '../../utils';
import { EMPTY_RESULT_DESCRIPTION, MAP_RESULT_TYPE_TO_CONTENT, nameSpace } from './constants';

export const getFileAccessParams = (search: string): FileAccessParams => {
    const queryParams = new URLSearchParams(search);
    const fileId = queryParams.get('file_id');
    const subjectEmail = queryParams.get('subject_email');
    const filename = queryParams.get('filename');
    return { fileId, filename, subjectEmail };
};

const checkI18nOptionsPlaceholder = (i18nPayload: I18Payload): boolean => i18nPayload && isEmpty(i18nPayload[1]);

export const getResultContent = (
    resultType: ResultType,
    i18nOptions: Record<string, string | number>,
): ResultDescription => {
    const { titlePayload, subTitlePayload } = (
        MAP_RESULT_TYPE_TO_CONTENT[resultType]
        || EMPTY_RESULT_DESCRIPTION
    );
    const result: ResultDescription = { titlePayload, subTitlePayload };
    if (i18nOptions) {
        if (checkI18nOptionsPlaceholder(subTitlePayload)) {
            result.subTitlePayload[1] = i18nOptions;
        }
        if (checkI18nOptionsPlaceholder(titlePayload)) {
            result.titlePayload[1] = i18nOptions;
        }
    }
    return result;
};

const getErrorFromStatusCode = (statusCode: number, isShareAction: boolean): ErrorResult => {
    let result: ErrorResult = isShareAction ? 'shareFail' : 'upgradeFail';
    if (statusCode === 403) {
        result = isShareAction ? 'shareForbidden' : 'upgradeForbidden';
    } else if (statusCode === 404) {
        result = 'notFound';
    }
    return result;
};

export const tryShareFile = async (
    API: SPXAPI,
    { subjectEmail, fileId, readOnly }: ShareRequestParams,
): Promise<RequestResult> => {
    let result: RequestResult;
    try {
        await API.put(
            BASEURL.backend(),
            ENDPOINTS.shareFile(fileId),
            { body: { email: subjectEmail.toLowerCase(), readOnly } },
        );
        result = readOnly ? 'shareSuccess' : 'upgradeSuccess';
    } catch (error) {
        console.log('could not share file', error);
        captureUnexpectedNetworkError(error, 'GiveAccess.tryShareFile');
        const { statusCode } = getErrorResponse(error);
        result = getErrorFromStatusCode(statusCode, readOnly);
    }
    return result;
};

export const getShareActionButtonTitle = (result: ResultType, t: TFunction): string => (
    t(result === 'shareSuccess' ? `${nameSpace}.giveWriteAccess` : 'general.buttons.tryAgain')
);
