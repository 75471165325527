import React from 'react';

import { Auth, Hub } from 'aws-amplify';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { HubCapsule, HubPayload } from '@aws-amplify/core/src/Hub';
import { GoogleOAuthProvider } from '@react-oauth/google';

import i18n from '@/content';
import {
    AUTO_SIGN_IN_INITIATED,
    MAP_ORIGIN_TO_LOCAL_STORAGE_KEY,
    NEXT_PAGE_AFTER_SIGN_IN,
    ORG_USER_SIGN_IN_INITIATED,
} from '@/consts';
import { App } from '../App';
import AuthContainer from './AuthContainer/AuthContainer';
import { Loading } from '../Common/Loading';
import MiniAppsContainer from '../MiniApps';
import ErrorMessage from '../Common/ErrorMessage/ErrorMessage';
import CrossOriginAuthenticator from './CrossOriginAuthenticator';
import BlockingPopup from './BlockingPopup';
import { withRouter, WithRouterProps } from '@/components/HOC';
import { AppAuthenticatorProps, CognitoAuthEvents } from './interfaces';
import ability, { tryUpdateRulesFromToken, WSO_RULES } from '../../config/ability';
import {
    AuthMessage,
    captureCognitoError,
    captureErrorForSentry,
    checkIsSignedIn,
    getAuthI18nPayload,
    parseAuthErrorType,
    postAuthMessage,
    SubscribablePromise,
    subscribeOnAuthMessage,
    tryGetTimelyRedirectPath,
    toTitleCase,
} from '../utils';
import appConfig, { ALL_SSO, IS_LOCAL_ENV, IS_NON_PROD_ENV } from '../../config/env';
import {
    ALLOWED_ROUTES_FOR_UNAUTHORIZED,
    AppRoutes,
    CROSS_ORIGIN_OAUTH,
    ExternalStorageRoutes,
    MINI_APPS_PATH_NAMES_LIST,
    MiniAppRoutes,
} from '@/config/appRoutes';
import {
    AuthStates,
    CognitoGroups,
    GdriveExportState,
    SyncCallback,
} from '@/types/types';
import { BASEURL, ENDPOINTS } from '@/api';
import configureAmplify from '@/config/amplify';
import {
    checkIsSignInPath,
    getSingInQueryParam,
    getUrlParams,
    getWSOAuthErrors,
    handleAuthTopic,
    hashToSearch,
    refreshCognitoSession,
} from './helpers';
import styles from './AppAuthenticator.module.scss';
import { ConfigProvider } from 'antd';

const TOKEN_REFRESH_ATTEMPTS_LIMIT = 1;

const ORGANIZATIONS_NAMESPACE = 'organizations';

export const IS_TEST_MODE = new URLSearchParams(window.location.search).has('testMode');

interface AuthError {
    hasError: boolean;
    title: string;
    subtitle: string;
}

export interface AuthenticatorState {
    isLoading: boolean;
    isAutoSignPreviouslyInitiated: boolean;
    isRedirectToMainAppAllowed: boolean;
    WSOAuthError?: AuthError;
    clientId: string;
}

@inject('appStore')
@observer
class AppAuthenticator extends React.Component<AppAuthenticatorProps & WithRouterProps, AuthenticatorState> {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isAutoSignPreviouslyInitiated: false,
            isRedirectToMainAppAllowed: true,
            clientId: '',
            WSOAuthError: {
                hasError: false,
                title: '',
                subtitle: '',
            },
        };
        this.cognitoUserSetter = new SubscribablePromise<void>();
    }

    private readonly cognitoUserSetter: SubscribablePromise<void>;

    private tokenRefreshAttempts = 0;

    private authChannelDisposer: SyncCallback;

    async componentDidMount(): Promise<void> {
        const { location, appStore } = this.props;
        const { IS_WSO } = appStore.authSettingsStore;
        this.authChannelDisposer = subscribeOnAuthMessage(this.storageListener);
        // TODO: uncomment it when organizations will be ready
        // this.runUserOrganizationsReaction();
        if (IS_WSO) {
            await this.setWSOUser();
            this.stopLoading();
        } else {
            const searchParams = new URLSearchParams(location.search);
            const hasCode = searchParams.has('code');
            const errorDescription = searchParams.get('error_description');
            this.setUpBrowserStorage(searchParams, hasCode);
            this.tryLockMainApp();
            configureAmplify(true);
            Hub.listen('auth', this.onCognitoAuthChange);
            this.handleAutoSignIn();
            if (errorDescription) {
                this.handleCognitoAuthError(errorDescription);
                this.stopLoading();
            } else if (!hasCode) {
                await this.trySetUpInCognitoEnv();
            }
        }
    }

    componentDidUpdate(): void {
        const { location, appStore: { authSettingsStore } } = this.props;
        const { appStore: { userStore: { authState } } } = this.props;
        const searchParams = new URLSearchParams(location.search);

        const isSignInPage = checkIsSignInPath(location.pathname);
        const { isAutoSignPreviouslyInitiated } = this.state;
        const isUserSignedIn = checkIsSignedIn(authState);
        const isUserSignedOut = authState === AuthStates.unauthenticated;
        const hasError = searchParams.has('error_description');
        const isAutoSignUncompleted = isUserSignedOut && isAutoSignPreviouslyInitiated && !hasError;
        if (isUserSignedIn && isSignInPage) {
            this.runSignedInUserFlow(searchParams);
        } else if (isAutoSignUncompleted && !authSettingsStore.HAS_API_PROXY) {
            this.showAllSSO();
        }
        if (isUserSignedOut && isSignInPage) {
            this.tryLockMainApp();
        }
    }

    componentWillUnmount(): void {
        const { appStore: { authSettingsStore } } = this.props;
        this.authChannelDisposer?.();
        if (!authSettingsStore.IS_WSO) {
            Hub.remove('auth', this.onCognitoAuthChange);
        }
    }

    storageListener = (message: AuthMessage): void => {
        const { appStore: { userStore: { authState } } } = this.props;
        handleAuthTopic(message, authState);
    }

    private setUpBrowserStorage(queryParams: URLSearchParams, hasCode: boolean): void {
        try {
            const { location } = this.props;
            const state = queryParams.get('state');
            const isGoogleDriveRedirect = location.pathname === ExternalStorageRoutes.gdrive;
            if (isGoogleDriveRedirect && !hasCode) {
                const stateObject: GdriveExportState = JSON.parse(state);
                if (stateObject.exportIds) {
                    stateObject.ids = stateObject.exportIds;
                    delete stateObject.exportIds;
                }
                sessionStorage.setItem(
                    MAP_ORIGIN_TO_LOCAL_STORAGE_KEY.googledrive,
                    JSON.stringify(stateObject),
                );
            }

            const isBoxRedirect = location.pathname === ExternalStorageRoutes.box;
            if (isBoxRedirect) {
                const boxUserId = queryParams.get('user_id');
                const boxFileIds = queryParams.get('ids');
                if (boxUserId && boxFileIds) {
                    sessionStorage.setItem(
                        MAP_ORIGIN_TO_LOCAL_STORAGE_KEY.box,
                        `{"userId": "${boxUserId}", "ids": ["${boxFileIds}"]}`,
                    );
                }
            }
        } catch (error) {
            console.log('Set up browser storage error', error);
            captureErrorForSentry(error, 'Apps.setUpBrowserStorage');
        }
    }

    private stopLoading(): void {
        this.setState({ isLoading: false });
    }

    private async tryCheckIPAccess(): Promise<void> {
        const { appStore } = this.props;
        const { API } = appStore.authSettingsStore;
        try {
            const { is_allowed: isAllowed } = await API.get(BASEURL.backend(), ENDPOINTS.checkIPAccess(), {});
            appStore.setIsIPAccessAllowed(isAllowed);
        } catch (error) {
            captureErrorForSentry(error, 'AppAuthenticator.tryCheckIPAccess');
            console.log('could not get user IP', error);
        }
    }

    private async saveCognitoUser(cognitoUser): Promise<void> {
        const { appStore: { userStore } } = this.props;
        const idToken = cognitoUser.signInUserSession?.idToken;
        if (idToken) {
            tryUpdateRulesFromToken(idToken);
            await userStore.setUserInformation(cognitoUser);
        } else {
            console.error('Trying to set user with empty session', cognitoUser);
        }
    }

    private async setCognitoUser(onReceiveUser?: (cognitoUser) => void | Promise<void>): Promise<void> {
        const { appStore: { userStore } } = this.props;
        await this.cognitoUserSetter.call(async () => {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            await onReceiveUser?.(cognitoUser);
            userStore.setAuthState(AuthStates.authenticated);
            await this.saveCognitoUser(cognitoUser);
        });
    }

    private clearUserData(): void {
        const { appStore } = this.props;
        appStore.clear();
        ability.update([]);
    }

    private tryReloadUser(): void {
        const { appStore: { userStore: { hasDoubleAuthError } } } = this.props;
        const federatedProvider = hasDoubleAuthError ? localStorage.getItem('federatedProvider') : getSingInQueryParam();
        Auth.federatedSignIn({ customProvider: toTitleCase(federatedProvider || 'Google')})
    }

    private handleCognitoAuthEvent({ event, data, message }: HubPayload): void {
        const { appStore: { userStore: { authState } } } = this.props;
        console.log('auth event', event);
        console.log('auth state', authState);
        switch (event) {
        case CognitoAuthEvents.signIn:
            this.trySetUpInCognitoEnv();
            break;
        case CognitoAuthEvents.cognitoHostedUI_failure:
        case CognitoAuthEvents.customState_failure:
            setTimeout(() => this.tryReloadUser(), 3000);
            break;
        case CognitoAuthEvents.signOut:
            this.clearUserData();
            break;
        case CognitoAuthEvents.tokenRefresh:
            this.setCognitoUser();
            break;
        case CognitoAuthEvents.tokenRefresh_failure:
            this.handleCognitoTokenRefreshFailure({ data, event, message });
            break;
        default:
            console.log('no handlers for event:', event);
        }
    }

    private handleCognitoAuthError(errorDescription: string): void {
        const { appStore: { setErrorStatus, userStore } } = this.props;
        const errorType = parseAuthErrorType(errorDescription);
        if (errorType === 'oauthSignUp') {
            userStore.setDoubleAuthError(true);
        } else {
            let parsedErrorMessage = '';
            if (errorType) {
                const [key, options] = getAuthI18nPayload(errorType, errorDescription);
                parsedErrorMessage = i18n.t(key, options);
                // TODO: explore all kind of such errors on BE
            } else if (errorDescription.includes('PreSignUp')) {
                parsedErrorMessage = errorDescription
                    .split('PreSignUp failed with error ')
                    .filter((substr) => !!substr)
                    .pop();
            } else if (!errorDescription.includes('User does not')) {
                parsedErrorMessage = i18n.t('auth.errors.serverIssue');
            }
            setErrorStatus(true, '', parsedErrorMessage || errorDescription);
            userStore.setAuthState(AuthStates.unauthenticated);
        }
    }

    onCognitoAuthChange = async ({ payload }: HubCapsule) : Promise<void> => {
        const urlParams = new URLSearchParams(window.location.search);
        const errorDescription = urlParams.get('error_description');
        this.handleCognitoAuthEvent(payload);
        if (errorDescription) {
            this.handleCognitoAuthError(errorDescription);
        }
    }

    onWSOAuthError = (error: unknown): void => {
        const { appStore: { userStore } } = this.props;
        userStore.setAuthState(AuthStates.unauthenticated);
        const { title, subtitle } = getWSOAuthErrors(error);
        this.setState({ WSOAuthError: { hasError: true, title, subtitle } });
        postAuthMessage({ type: 'signOut' });
    }

    WSOSignIn = async (initPathName: string): Promise<void> => {
        const { navigate, appStore: { userStore } } = this.props;
        const urlParams = getUrlParams();
        const state = urlParams.get('state');
        const code = urlParams.get('code');
        const accessToken = urlParams.get('access_token');
        const errorDescription = urlParams.get('error_description');
        userStore.setAuthState(AuthStates.loading);
        try {
            if (errorDescription) {
                throw new Error(errorDescription);
            } else if (!state) {
                await userStore.login();
            } else if (state && (code || accessToken)) {
                const locationHash = window.location.hash;
                if (locationHash) {
                    window.location.hash = hashToSearch(locationHash);
                }
                await userStore.continueLogin();
                ability.update(WSO_RULES);
                window.location.hash = '';
                const locationPath = initPathName !== '' ? initPathName : AppRoutes.sharedWithMe;
                navigate(locationPath);
            }
        } catch (error) {
            this.onWSOAuthError(error);
        }
    }

    renewWSOToken = async (): Promise<void> => {
        const { appStore: { userStore } } = this.props;
        try {
            await userStore.renewToken();
            ability.update(WSO_RULES);
        } catch (error) {
            console.log('error while token renew', error);
            await userStore.onSilentRenewError();
            await userStore.login();
        }
    }

    private async setWSOUser(): Promise<void> {
        const { location, appStore: { userStore } } = this.props;
        const user = await userStore.userManager.getUser();
        if (user) {
            await this.renewWSOToken();
            const newUser = await userStore.userManager.getUser();
            console.log('user object is ', newUser);
        } else {
            const initPathName = location.pathname;
            const isGettingFilePage = ALLOWED_ROUTES_FOR_UNAUTHORIZED.includes(initPathName as MiniAppRoutes);
            const shouldStartSignIn = !isGettingFilePage;
            if (shouldStartSignIn) {
                await this.WSOSignIn(initPathName);
            }
        }
        postAuthMessage({ type: 'signIn' });
    }

    private tryRedirectToMiniApp(searchParams: URLSearchParams, miniAppSavedURL: string): void {
        const { navigate } = this.props;
        const { isRedirectToMainAppAllowed } = this.state;
        const shouldTryRedirect = searchParams.has('code') || !isRedirectToMainAppAllowed;
        if (shouldTryRedirect) {
            sessionStorage.removeItem(NEXT_PAGE_AFTER_SIGN_IN);
            navigate(miniAppSavedURL);
        }
    }

    private async trySetUpInCognitoEnv(): Promise<void> {
        const { appStore: { userStore, organizationsStore }, location } = this.props;
        try {
            organizationsStore.setUp();
            await this.setCognitoUser();
            postAuthMessage({ type: 'signIn' });
            const postSaveCalls: Promise<boolean | void>[] = [];
            const hasIPCheck = appConfig.ENABLE_IP_RESTRICTION
                && userStore.userGroupsSet.has(CognitoGroups.Administrators);
            const isGoogleDriveRedirect = location.pathname === ExternalStorageRoutes.gdrive;
            if (hasIPCheck && !isGoogleDriveRedirect) {
                const tryCheckIpPromise = this.tryCheckIPAccess();
                if (!MINI_APPS_PATH_NAMES_LIST.includes(location.pathname as MiniAppRoutes)) {
                    postSaveCalls.push(tryCheckIpPromise);
                }
            }
            await Promise.all(postSaveCalls);
        } catch (error) {
            if (IS_LOCAL_ENV) {
                console.log('auth error', error);
            }
            organizationsStore.clear();
            userStore.setAuthState(AuthStates.unauthenticated);
        }
        this.stopLoading();
    }

    private async tryLogoutOnRefreshFailure(authState: string, error: unknown): Promise<void> {
        const { appStore: { userStore } } = this.props;
        captureCognitoError(error, 'AppAuthenticator.refreshCognitoSession');
        if (authState !== AuthStates.unauthenticated) {
            await userStore.cognitoSignOut();
        }
    }

    private async handleCognitoTokenRefreshFailure(payload: HubPayload): Promise<void> {
        const { appStore: { userStore: { authState } } } = this.props;
        if (this.tokenRefreshAttempts < TOKEN_REFRESH_ATTEMPTS_LIMIT) {
            this.tokenRefreshAttempts += 1;
            try {
                if (IS_NON_PROD_ENV) {
                    console.log('before retry refresh, auth state is', authState);
                }
                await refreshCognitoSession();
                this.tokenRefreshAttempts = 0;
            } catch (error) {
                if (IS_NON_PROD_ENV) {
                    console.log('token refresh failure', error);
                    console.log('authState is', authState);
                }
                await this.tryLogoutOnRefreshFailure(authState, error);
            }
        } else {
            if (IS_NON_PROD_ENV) {
                console.log('token refresh attempts limit reached', payload);
            }
            await this.tryLogoutOnRefreshFailure(authState, payload);
        }
    }

    private handleAutoSignIn(): void {
        const isAutoSignInInitiated = !!sessionStorage.getItem(AUTO_SIGN_IN_INITIATED);
        sessionStorage.removeItem(AUTO_SIGN_IN_INITIATED);
        this.setState({
            isAutoSignPreviouslyInitiated: isAutoSignInInitiated,
        });
    }

    private tryLockMainApp(): void {
        const { location } = this.props;
        const { isRedirectToMainAppAllowed } = this.state;
        if (
            tryGetTimelyRedirectPath()
            && checkIsSignInPath(location.pathname)
            && isRedirectToMainAppAllowed
        ) {
            this.setState({ isRedirectToMainAppAllowed: false });
        }
    }

    private runSignedInUserFlow(searchParams: URLSearchParams): void {
        const { isRedirectToMainAppAllowed } = this.state;
        localStorage.removeItem(ORG_USER_SIGN_IN_INITIATED);
        const nextPageURL = tryGetTimelyRedirectPath();
        if (nextPageURL) {
            this.tryRedirectToMiniApp(searchParams, nextPageURL);
        }
        if (!isRedirectToMainAppAllowed) {
            this.setState({ isRedirectToMainAppAllowed: true });
        }
    }

    private showAllSSO(): void {
        const { navigate } = this.props;
        navigate(`signin?auth=${ALL_SSO.join(',')}`);
        this.setState({ isAutoSignPreviouslyInitiated: false });
    }

    render(): JSX.Element {
        // TODO: refactor these condition through the react-router
        const urlParams = new URLSearchParams(window.location.search);
        const { props } = this;
        const {
            location,
            appStore,
            navigate,
            ...authenticatorProps
        } = props;
        const {
            errorStatus,
            isFullSetUp,
            blockingPopup,
            authSettingsStore: { IS_WSO },
            userStore: { authState },
        } = appStore;
        const {
            WSOAuthError, isLoading: stateIsLoading, isAutoSignPreviouslyInitiated, isRedirectToMainAppAllowed, clientId
        } = this.state;
        const hasCode = urlParams.has('code');
        const hasRedirectToMiniAppPrecondition = checkIsSignInPath(location.pathname) && !isRedirectToMainAppAllowed;
        const isSignedOut = authState === AuthStates.unauthenticated;
        const isSignedIn = checkIsSignedIn(authState);
        const isLoading = stateIsLoading && !errorStatus.hasError;
        const isMiniAppRoute = MINI_APPS_PATH_NAMES_LIST.includes(location.pathname as MiniAppRoutes);
        const showMiniApps = isMiniAppRoute;
        const showAuthPage = !IS_WSO
            && isSignedOut
            && !isMiniAppRoute
            && !hasCode
            && !isAutoSignPreviouslyInitiated;
        const showMainApp = isSignedIn
            && isFullSetUp
            && !isMiniAppRoute
            && !isLoading
            && !hasRedirectToMiniAppPrecondition;
        const showWSOError = IS_WSO && WSOAuthError.hasError;
        const isCrossOriginAuth = location.pathname === CROSS_ORIGIN_OAUTH;

        const isNothingToShow = !showMiniApps && !showAuthPage && !showMainApp && !showWSOError && !blockingPopup;
        const showLoader = (isLoading && !showMiniApps) || isNothingToShow;

        return (
            <div className="app-wrapper">
                { showLoader && <Loading fullScreen loadingText="Please wait..." />}
                { isCrossOriginAuth
                    ? <CrossOriginAuthenticator />
                    : (
                        <>
                            {showAuthPage && (
                                <div className={styles['auth-page-wrapper']}>
                                    <GoogleOAuthProvider clientId={clientId}>
                                        <AuthContainer {...authenticatorProps} />
                                    </GoogleOAuthProvider>
                                </div>
                            )}
                            {showMainApp &&
                                <ConfigProvider theme={{ hashed: false }}>
                                    <App />
                                </ConfigProvider>
                            }
                            {showMiniApps && (
                                <MiniAppsContainer
                                    isRootLoading={stateIsLoading}
                                />
                            )}
                            {showWSOError && (
                                <ErrorMessage
                                    className="app-screen"
                                    title={i18n.t(WSOAuthError.title)}
                                    subtitle={i18n.t(WSOAuthError.subtitle)}
                                />
                            )}
                        </>
                    )}
                <BlockingPopup />
            </div>
        );
    }
}

export default withRouter(AppAuthenticator);
