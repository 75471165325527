const FULL_PERCENTS = 100;
const MILLISECONDS_IN_SECOND = 1000;

export const getProgressDelta = (speed: number, frequency: number): number => (
    (speed * frequency) / MILLISECONDS_IN_SECOND
);

// eslint-disable-next-line arrow-body-style
export const calculateDecreaseCoefficient = (breakpointPercents: number, progressDelta: number): number => {
    /*
     * Calculates decrease coefficient for progress speed using geometry progression
     * S = a1 / (1 - coefficient), where S == 100 - (breakpointPercents - progressDelta), a1 == progressDelta
     * We need to calculate coefficient
     * */
    return 1 - progressDelta / (FULL_PERCENTS - (breakpointPercents - progressDelta));
};

export const random = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1) + min);

export const avg = (...args: number[]): number => {
    const sum = args.reduce((prev, current) => prev + current, 0);
    return sum / args.length;
};