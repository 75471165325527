import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AppRoutes } from '../../../../config/appRoutes';
import { ENDPOINTS } from '../../../../api/endpoints';
import styles from './PublicHeader.module.scss';
import config from '../../../../config/env';

interface PublicHeaderProps {
    children?: ReactElement;
}

const PublicHeader: FC<PublicHeaderProps> = ({ children }) => {
    const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const linkId = queryParams.get('linkId');

        const fetchLogoUrl = async () => {
            try {
                if (linkId) {
                    const url = `${config.API_URL}${ENDPOINTS.getLinkDetails(linkId)}`;
                    const response = await fetch(url);
                    const data = await response.json();

                    if (data.branding_meta && data.branding_meta.logo) {
                        setLogoUrl(data.branding_meta.logo);
                    } else {
                        const brandingResponse = await fetch(`${config.API_URL}${ENDPOINTS.getBranding(data.src_email)}`);
                        const brandingData = await brandingResponse.json();
                        setLogoUrl(brandingData.logo);
                    }
                } else {
                    setLogoUrl(config.HEADER_SMALL_COMPANY_LOGO);
                }
            } catch (error) {
                console.warn('Error fetching logo:', error);
                setLogoUrl(config.HEADER_SMALL_COMPANY_LOGO);
            }
        };

        fetchLogoUrl();
    }, [location.search]);

    return (
        <header className={styles.header}>
            <Link to={AppRoutes.myFiles}>
                {logoUrl && (
                    <img
                        alt="x-logo"
                        src={logoUrl}
                        className={styles.logo}
                        data-testid="recipientPage_MyFiles"
                    />
                )}
            </Link>
            {children}
        </header>
    );
};

export default PublicHeader;
