import {
    action,
    computed,
    observable,
    makeObservable,
    runInAction,
} from 'mobx';

import AuthSettingsStore from '../AuthSettingsStore';
import { SingleCallAPI, BASEURL, ENDPOINTS } from '../../api';
import {
    AuditsRequestConfig,
    AuditsResponse,
    FormFieldsChange,
    GetAuditsParams,
    LastAuditLogQuery,
    LogRecord,
    PageSize,
    PaginationState,
    SelectedOperation,
} from './interaces';
import { captureErrorForSentry, getErrorResponse } from '../../components/utils';
import { RemoveResult } from '..';
import { FIVE_SECONDS_IN_MILLI } from './options';

const getLogsForPage = (logsList: LogRecord[], targetPage: number, pageSize: PageSize): LogRecord[] => (
    logsList.slice((targetPage - 1) * pageSize, targetPage * pageSize)
);

const getRecordsCountForPage = (logsList: LogRecord[], targetPage: number, pageSize: PageSize): number => {
    const recordsOnRequestedPage = getLogsForPage(logsList, targetPage, pageSize).length;
    return pageSize - recordsOnRequestedPage;
};

const INIT_PAGINATION: PaginationState = {
    pageSize: 25,
    currentPage: 1,
    nextPageToken: '',
};

class AuditsStore {
    constructor(authSettingsStore: AuthSettingsStore) {
        this.singleCallAPI = new SingleCallAPI(authSettingsStore, false);
        this.authSettingsStore = authSettingsStore;
        makeObservable(this);
    }

    private readonly singleCallAPI: SingleCallAPI;

    private readonly authSettingsStore: AuthSettingsStore;

    @observable logsList: LogRecord[] = [];

    @observable lastAuditLogQuery: LastAuditLogQuery = new LastAuditLogQuery();

    @observable pagination: PaginationState = { ...INIT_PAGINATION };

    @observable fileId = '';

    @observable searchedUser = '';

    @observable searchedFilename = '';

    @observable searchedFileId = '';

    @observable selectedOperation: SelectedOperation = '';

    @observable hasError = false;

    @observable isLoading = false;

    @observable isEmailBecomeLicensedUserSentSuccess: RemoveResult = 'unset';

    @computed
    get logsToDisplay(): LogRecord[] {
        return getLogsForPage(this.logsList, this.pagination.currentPage, this.pagination.pageSize);
    }

    @action
    setFileId = (newValue: string): void => {
        this.fileId = newValue;
        this.searchedFileId = newValue;
    };

    @action
    setLogsList = (logsList: LogRecord[]): void => {
        this.logsList = logsList;
    };

    @action
    setIsEmailBecomeLicensedUserSent = (newValue: RemoveResult): void => {
        this.isEmailBecomeLicensedUserSentSuccess = newValue;
    };

    @action
    setSelectedOperation = (newValue: SelectedOperation): void => {
        this.selectedOperation = newValue;
    };

    @action
    setSearchedFilename = (newValue: string): void => {
        this.searchedFilename = newValue;
    };


    @action
    setSearchedFileId= (newValue: string): void => {
        this.searchedFileId = newValue;
        this.fileId = newValue;
    };

    @action
    setSearchedUser = (newValue: string): void => {
        this.searchedUser = newValue;
    };

    @action
    setFilter = (filter: Partial<FormFieldsChange>): void => {
        runInAction(() => {
            Object.entries(filter).forEach(([key, value]) => {
                this[key] = value;
            });
        });
    };

    @action
    clearFilters = (): void => {
        this.setSearchedFilename('');
        this.setSearchedUser('');
        this.selectedOperation = '';
        this.fileId = '';
        this.setSearchedFileId('');
    };

    @action
    setPagination = (value: PaginationState): void => {
        this.pagination = value;
    };

    @action
    setHasError = (value: boolean): void => {
        this.hasError = value;
    };

    @action
    setIsLoading = (value: boolean): void => {
        this.isLoading = value;
    };

    @action
    setLastAuditLogQuery = (value: LastAuditLogQuery): void => {
        this.lastAuditLogQuery = { ...value };
    };

    updatePagination = (value: Partial<PaginationState>): void => {
        this.setPagination({ ...this.pagination, ...value });
    };

    async getAudits({
                        recordsCount,
                        allowInParallel,
                        next = '',
                    }: AuditsRequestConfig): Promise<AuditsResponse> {
        try {
            const {
                fileId,
                searchedUser,
                searchedFilename,
                searchedFileId,
                selectedOperation,
                authSettingsStore: { API },
            } = this;

            const APIInstance = allowInParallel ? API : this.singleCallAPI;
            const user = searchedUser.toLowerCase();
            return APIInstance.get(BASEURL.backend(), ENDPOINTS.getAudits(recordsCount, {
                next,
                file_id: fileId || searchedFileId,
                user,
                operation: selectedOperation,
                file_name: searchedFilename,
            }), {});
        } catch (error) {
            if (!this.logsList.length) {
                captureErrorForSentry(error, 'AuditsStore.getAudits');
            }
        }
    }

    // New function to fetch audits in the background
    fetchAuditsInBackground = (auditResponse): void => {
        const page = this.pagination.currentPage;
        const lastAuditQuery: LastAuditLogQuery = new LastAuditLogQuery(
            auditResponse.next,
            page,
            auditResponse.pageSize,
            this.searchedUser,
            this.searchedFilename,
            this.searchedFileId,
            new Date().getTime());
        this.setLastAuditLogQuery(lastAuditQuery);
    };

    tryFetchAudits = async ({ page, restRecords }: GetAuditsParams = { page: 1 }): Promise<void> => {
        const isFirstPage = page === 1;
        const next = isFirstPage && !restRecords ? '' : this.pagination.nextPageToken;
        const requestedRecordsCount = restRecords || (
            isFirstPage ? this.pagination.pageSize : getRecordsCountForPage(this.logsList, page, this.pagination.pageSize)
        );

        runInAction(() => {
            this.isLoading = true;
            this.hasError = false;
        });
        try {
            if (!isFirstPage && !next) {
                throw Error(
                    `Inconsistent getAudits call: page is ${page},
                     requestedRecordsCount is ${requestedRecordsCount},
                     next is ${next}`,
                );
            }
            let auditResponse;
            const currentTime = new Date().getTime();
            const timeSinceLastFetch = Math.abs(currentTime - this.lastAuditLogQuery.lastFetchTime); // Difference in milliseconds
            if (timeSinceLastFetch >= FIVE_SECONDS_IN_MILLI
                || this.logsList.length <= 0
                || this.searchedUser !== this.lastAuditLogQuery.lastSearchedUser
                || this.searchedFilename !== this.lastAuditLogQuery.lastSearchedFilename
                || this.searchedFileId !== this.lastAuditLogQuery.lastSearchedFileId
                || requestedRecordsCount !== this.lastAuditLogQuery.lastPageSize
                || next !== this.lastAuditLogQuery.lastNextToken) {
                auditResponse = await this.getAudits({
                    recordsCount: requestedRecordsCount,
                    allowInParallel: true,
                    next,
                });
            }
            // this.fetchAuditsInBackground(auditResponse);
            const newPagination: PaginationState = {
                nextPageToken: auditResponse.next,
                currentPage: page,
                pageSize: this.pagination.pageSize,
            };
            const shouldReplaceCurrentRecords = isFirstPage && !restRecords;
            const currentLogsList = shouldReplaceCurrentRecords ? [] : this.logsList;

            runInAction(() => {
                this.pagination = newPagination;
                this.logsList = [...currentLogsList, ...auditResponse.results];
                this.isLoading = false;
            });
        } catch (error) {
            if (!this.singleCallAPI.checkIsCancel(error)) {
                console.log('could not load records', error);
                captureErrorForSentry(error, 'AuditsStore.tryFetchAudits');
                runInAction(() => {
                    this.hasError = true;
                    this.isLoading = false;
                });
            }
        }
    };

    // TODO: create a separate store for this method or add a new loader
    generateBecomeLicensedUserEmail = async (
        newLeadEmail: string,
        newLeadRealLoginEmail: string,
    ): Promise<void> => {
        try {
            const {
                authSettingsStore: { API },
            } = this;
            this.setIsLoading(true);
            this.setIsEmailBecomeLicensedUserSent('unset');
            await API.post(
                BASEURL.backend(),
                ENDPOINTS.generateEmailBecomeLicensedUser(),
                {
                    body: {
                        notification_id: 'send_new_lead_notification',
                        new_lead_email: newLeadEmail,
                        new_lead_real_login_email: newLeadRealLoginEmail,
                    },
                },
            );
            this.setIsEmailBecomeLicensedUserSent('success');
        } catch (error) {
            const { statusCode } = getErrorResponse(error);
            if (statusCode === 404) {
                this.setIsEmailBecomeLicensedUserSent('error');
            } else {
                captureErrorForSentry(error, 'UploadFilesStore.deleteFile');
                this.setIsEmailBecomeLicensedUserSent('error');
            }
        } finally {
            this.setIsLoading(false);
        }
    };

    handlePageChange = async (page: number): Promise<void> => {
        if (page === 1) {
            this.tryFetchAudits();
        } else if (this.pagination.nextPageToken && (page > this.logsList.length / this.pagination.pageSize)) {
            this.tryFetchAudits({ page });
        } else {
            this.updatePagination({ currentPage: page });
        }
    };

    handlePageSizeChange = (size: number): void => {
        const recordsCount = this.logsList.length;
        const lastPage = Math.ceil(recordsCount / size);
        const newCurrentPage = this.pagination.currentPage * size > recordsCount ? lastPage : this.pagination.currentPage;
        this.updatePagination({ currentPage: newCurrentPage, pageSize: size as PageSize });
        const isFirstOrLast = newCurrentPage === 1 || newCurrentPage === lastPage;
        const recordsToFullPage = newCurrentPage * size - recordsCount;
        if (this.pagination.nextPageToken && isFirstOrLast && recordsToFullPage > 0) {
            this.tryFetchAudits({ page: newCurrentPage, restRecords: recordsToFullPage });
        }
    };
}

export default AuditsStore;
