import React, { FC, ReactElement, useMemo } from 'react';

import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { ClockMismatch, YogaIcon } from '../../../../../../../Common/StatusIcon';
import {
    createDefaultExtraPropsMap,
    DebugInfoProps,
    errorsNameSpace,
    ErrorResult,
    ExtraCreator,
    I18nOptions,
    MapErrorTypeToDefaultExtra,
    MAP_ERROR_TYPE_TO_TITLE_CREATOR as MAP_GENERIC_ERROR_TYPE_TO_TITLE_CREATOR,
    MAP_ERROR_TYPE_TO_ANT_RESULT_STATUS,
    MAP_ERROR_TYPE_TO_SUBTITLE_I18_KEY as MAP_GENERIC_ERROR_TYPE_TO_SUBTITLE_I18_KEY,
    MAP_ERROR_TYPE_TO_EXTRA as MAP_GENERIC_ERROR_TYPE_TO_EXTRA,
    PermissionsErrorExplainProps,
    TitleCreator,
} from '../../../../../../Common';
import { OpenFileButtonProps } from './OpenFileButton';
import { useStores } from '@/components/hooks';
import { useFilesActionsContext } from '../../../hooks/useFilesActionContext';
import { FileAccessError } from '@/types/types';
import { getDebugInfo } from '@/stores';
import { TIME_MISMATCH_REGEXP } from '@/regExp';
import { LinkBasedOpenOptions, OpenOptions } from '@/config/openOptions';
import
OpenFileButtonContainer
    from '../../../FilesRow/FilesPicker/FilePopup/FileOpenOptions/OpenFileButtonContainer/OpenFileButtonContainer';
import { SPX_SUPPORT_EMAIL } from '@/consts';
import styles from './ErrorView.module.scss';

type VisualErrorType = FileAccessError | 'clockMismatch';

const MAP_ERROR_TYPE_TO_I18_TITLE_CREATOR: Partial<Record<VisualErrorType, TitleCreator>> = {
    ...MAP_GENERIC_ERROR_TYPE_TO_TITLE_CREATOR,
    unsupportedAccessType: (options: I18nOptions) => [
        `${errorsNameSpace}.supportedAccessType`,
        options,
    ],
    clockMismatch: () => [`${errorsNameSpace}.clockMismatch`],
    fileBlocked: () => [`${errorsNameSpace}.fileIsBlocked`],
    fileScanning: () => [`${errorsNameSpace}.fileScanning`]
};

const MAP_ERROR_TYPE_TO_SUBTITLE_I18_KEY: Partial<Record<VisualErrorType, string>> = {
    ...MAP_GENERIC_ERROR_TYPE_TO_SUBTITLE_I18_KEY,
    unsupportedAccessType: `${errorsNameSpace}.supportedAccessType_subtitle`,
    clockMismatch: `${errorsNameSpace}.clockMismatch_subtitle`,
    fileBlocked: `${errorsNameSpace}.fileIsBlocked_subtitle`,
    fileScanning: `${errorsNameSpace}.fileScanning_subtitle`,
};

const MAP_ERROR_TYPE_TO_ICON: Partial<Record<VisualErrorType, ReactElement>> = {
    unsupportedAccessType: <YogaIcon iconSize="large" />,
    clockMismatch: <ClockMismatch iconSize="large" />,
    fileBlocked: <ClockMismatch iconSize="large" />,
    fileScanning: <ClockMismatch iconSize="large" />
};

interface ClockMismatchExtraProps {
    t: TFunction;
}

interface ErrorResultExtraCreators extends MapErrorTypeToDefaultExtra {
    unsupportedAccessType: ExtraCreator<OpenFileButtonProps>;
    clockMismatch: ExtraCreator<ClockMismatchExtraProps>;
}

const MAP_EXTRA_TYPE_TO_EXTRA: ErrorResultExtraCreators = {
    ...MAP_GENERIC_ERROR_TYPE_TO_EXTRA,
    unsupportedAccessType:
    (props: OpenFileButtonProps, optionsForExtra: LinkBasedOpenOptions[]) => (
        <OpenFileButtonContainer
            {...props}
            allowedApps={optionsForExtra}
        />
    ),
    clockMismatch: ({ t }: ClockMismatchExtraProps) => <>{t(`${errorsNameSpace}.clockMismatch_extra`)}</>,
};

type ExtraPropsUnion = PermissionsErrorExplainProps
| OpenFileButtonProps
| DebugInfoProps
| ClockMismatchExtraProps;

const getVisualErrorType = (errorType: FileAccessError, debugInfo: Record<string, string>): VisualErrorType => {
    if (errorType !== 'permissionCheckFail') {
        return errorType;
    }
    return TIME_MISMATCH_REGEXP.test(debugInfo?.message) ? 'clockMismatch' : errorType;
};

interface ErrorViewProps {
    errorType: FileAccessError;
}

const ErrorView: FC<ErrorViewProps> = observer(({
    errorType,
}) => {
    const { actionHandler } = useFilesActionsContext();
    const { t } = useTranslation();

    const {
        recipientPageStore: {
            sharedFilesStore: {
                baseDebugInfo,
                selectedFile,
                selectedFilePermissions,
            },
        },
    } = useStores();
    const {
        is_folder: isFolder,
        file_id: fileId,
        owner_email: ownerEmail,
        filename,
        policy,
    } = selectedFile;

    const item = t(`general.specterxCommon.${isFolder ? 'folder' : 'file'}`);
    const accessType = t('general.specterxCommon.fileAccessApp.viewer');

    const options = selectedFile.allowed_apps;

    const optionsForExtra = options.filter((option) => option !== OpenOptions.viewer) as LinkBasedOpenOptions[];

    const isAvailableOptions = !!optionsForExtra.length;

    const debugInfo = useMemo<Record<string, string>>(() => getDebugInfo(
        selectedFilePermissions?.errorObject,
        { ...baseDebugInfo, fileId },
    ), [selectedFilePermissions, baseDebugInfo, fileId]);

    const visualErrorType = getVisualErrorType(errorType, debugInfo);

    const MAP_ERROR_TYPE_TO_EXTRA_PROPS: Partial<Record<VisualErrorType, ExtraPropsUnion>> = {
        ...createDefaultExtraPropsMap(item, ownerEmail, debugInfo),
        unsupportedAccessType: {
            fileId,
            actionHandler,
            policy,
            ownerEmail,
            filename,
        },
        clockMismatch: { t },
    };

    return (
        <div className={styles.container}>
            <ErrorResult
                title={!isAvailableOptions && visualErrorType === 'unsupportedAccessType'
                    ? (
                        <Trans i18nKey="recipientPage.errors.nonAvailableOptionsTitle">
                            Please contact
                            <a className={styles['support-email']} href={`mailto:${SPX_SUPPORT_EMAIL}`}>
                                support@specterx.com
                            </a>
                        </Trans>
                    )
                    : t(...MAP_ERROR_TYPE_TO_I18_TITLE_CREATOR[visualErrorType](
                        { item, accessType }),
                    )}
                subTitle={t(visualErrorType === 'unsupportedAccessType' && !isAvailableOptions
                    ? `${errorsNameSpace}.nonAvailableOptionsSubtitle`
                    : MAP_ERROR_TYPE_TO_SUBTITLE_I18_KEY[visualErrorType], { accessType })}
                status={MAP_ERROR_TYPE_TO_ANT_RESULT_STATUS[visualErrorType]}
                icon={MAP_ERROR_TYPE_TO_ICON[visualErrorType]}
                extra={MAP_EXTRA_TYPE_TO_EXTRA[visualErrorType]?.(
                    MAP_ERROR_TYPE_TO_EXTRA_PROPS[visualErrorType], optionsForExtra,
                )}
            />

        </div>
    );
});

export default ErrorView;
