import { BASEURL, CancellableAPI, ENDPOINTS } from '../../../api';
import AuthSettingsStore from '../../AuthSettingsStore';
import { DeferredTask, RequestsQueue } from '../../../components/utils';
import { CreateLinkPayload, LinkResponse } from './interfaces';
import { createRequestKey, getRequestBody } from './utils';
import { LINKS_PARALLEL_REQUESTS_LIMIT } from '../constants';

class LinksLoader {
    private readonly APIInstance: CancellableAPI;

    private readonly requestsQueue: RequestsQueue<DeferredTask>;

    constructor(authSettingsStore: AuthSettingsStore) {
        this.APIInstance = new CancellableAPI(authSettingsStore);
        this.requestsQueue = new RequestsQueue<DeferredTask>(LINKS_PARALLEL_REQUESTS_LIMIT);
    }

    async createLink({
        recipientIdentity,
        ...rest
    }: CreateLinkPayload): Promise<string | null> {
        const requestKey: string = createRequestKey(recipientIdentity);
        try {
            await this.requestsQueue.enqueueAsync({ id: requestKey });
            const recipient = recipientIdentity.toLowerCase();
            const { result } = await this.APIInstance.post<LinkResponse>(
                BASEURL.backend(),
                ENDPOINTS.createMultipleFilesLink(),
                requestKey,
                {
                    body: getRequestBody({ ...rest, recipientIdentity: recipient }),
                },
            );
            this.requestsQueue.finish(requestKey, true);
            return result?.link;
        } catch (error) {
            if (!this.APIInstance.checkIsCancel(error)) {
                this.requestsQueue.finish(requestKey, false);
            }
            return null;
        }
    }

    cancelRequest(recipientEmail: string): void {
        const requestKey: string = createRequestKey(recipientEmail);
        this.APIInstance.cancelRequest(requestKey);
        this.requestsQueue.finish(requestKey, false);
    }

    cancelAll(): void {
        this.APIInstance.cancelAll();
        this.requestsQueue.clear();
    }
}

export default LinksLoader;
