import React, {
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { Alert } from 'antd';
import { observer } from 'mobx-react';

import MfaComp from '../../../AppAuthenticator/MfaComp';
import AllowPopupContent from '../../../Common/AllowPopupContent';
import { ConfirmDownloadContent } from '../../../Common/ConfirmDownload';
import i18n from '@/content';
import { MFA_ATTEMPTS } from '@/consts';
import { SecuredFile } from '@/types/types';
import { ModalContentProps } from '../../../Common/Modal';
import { useMounted, useStores } from '../../../hooks';
import { checkIsWindowPopupBlocked, downloadFile } from '../../../utils';
import { OpenOptions } from '@/config/openOptions';
import { MAP_ERROR_TYPE_TO_STATUS_CODE } from '@/stores';
import './index.scss';

type Step = 'openFile' | 'MFA' | 'confirmDownload' | 'allowPopup';

const getRemainsAttemptMessage = (counter: number): string => (
    `Provided code was not valid. ${counter} ${counter === 1 ? ' try remaining' : ' tries remaining'}`
);

const getNextStep = (accessLink: string | undefined, isDownload: boolean): Step | null => {
    let nextStep: Step = null;
    if (accessLink && isDownload) {
        nextStep = 'confirmDownload';
    } else if (accessLink) {
        const newWin = window.open(accessLink);
        if (checkIsWindowPopupBlocked(newWin)) {
            nextStep = 'allowPopup';
        }
    }
    return nextStep;
};

interface OpenFilesProps extends ModalContentProps{
    fileSelected: SecuredFile;
    app: OpenOptions.download | OpenOptions.office365 | OpenOptions.googledrive,
    closeModal: () => void;
}

const OpenFile: FC<OpenFilesProps> = observer(({
    setAdditionalWrapClassName,
    setWidth,
    resetParentModalState,
    closeModal: closeModalProp,
    setLoadingStatus,
    setErrorStatus,
    fileSelected,
    app,
}) => {
    const [step, setStep] = useState<Step>('openFile');
    const { filesAccessStore } = useStores();

    const { selectedItemState, setProtectedFileInfo } = filesAccessStore;
    const link = selectedItemState?.accessTypesState?.[app]?.result?.link;
    const counter = selectedItemState?.MFAState?.attemptsCounter;
    const isDownload = app === OpenOptions.download;

    const isMountedRef = useMounted();

    const closeModal = (): void => {
        closeModalProp();
        resetParentModalState?.();
    };

    const fetchFileLink = async (code?: string): Promise<boolean> => {
        const waitMessageType = isDownload ? 'downloadingFile' : 'preparingFile';
        setLoadingStatus(true, i18n.t(`general.waitMessages.${waitMessageType}`));
        const isSucceed = await filesAccessStore.tryFetchAccessLink({
            fileId: fileSelected.file_id,
            filename: fileSelected.filename,
            accessType: app,
            code,
        });
        setLoadingStatus(false);
        return isSucceed;
    };

    const onSuccess = (): void => {
        const accessLink = filesAccessStore.selectedItemAccessTypesState?.[app]?.result?.link;
        const nextStep = getNextStep(accessLink, isDownload);
        if (nextStep) {
            setStep(nextStep);
        } else {
            closeModal();
        }
    };

    const onError = (): void => {
        const accessError = filesAccessStore.selectedItemAccessTypesState?.[app]?.errorType;
        const statusCode = MAP_ERROR_TYPE_TO_STATUS_CODE[accessError];
        if (accessError === 'MFAFailed' || accessError === 'MFARequired') {
            setStep('MFA');
        } else {
            if (accessError === 'fileBeingPrepared') {
                setProtectedFileInfo({
                    fileId: fileSelected.file_id,
                    filename: fileSelected.filename,
                    accessType: app,
                });
            }
            const statusCode = MAP_ERROR_TYPE_TO_STATUS_CODE[accessError];
            const messageKey: string | string[] = statusCode
                ? `general.errors.${statusCode}`
                : 'general.errors.1';
            setErrorStatus(true, i18n.t(messageKey, { type: 'file' }));
        }
    };

    const tryOpenFile = async (code?: string): Promise<void> => {
        const isSucceed = await fetchFileLink(code);
        if (isMountedRef.current) {
            if (isSucceed) {
                onSuccess();
            } else {
                onError();
            }
        }
    };

    useEffect(() => {
        filesAccessStore.setSelectedAccessFileId(fileSelected.fid);
        tryOpenFile();
        return () => filesAccessStore.clearSelectedItem();
    }, []);

    const handleConfirmDownload = useCallback((isConfirmed: boolean): void => {
        if (isMountedRef?.current) {
            if (isConfirmed) {
                downloadFile(link);
            }
            closeModal();
        }
    }, [link]);

    return (
        <div className="content-wrap">
            {step === 'MFA' && (
                <>
                    {counter < MFA_ATTEMPTS && (
                        <Alert
                            message={getRemainsAttemptMessage(counter)}
                            type="warning"
                        />
                    )}
                    <MfaComp onMfaClick={tryOpenFile} />
                </>
            )}
            {step === 'allowPopup' && (
                <AllowPopupContent
                    close={closeModal}
                    setAdditionalWrapClassName={setAdditionalWrapClassName}
                    setWidth={setWidth}
                    resetParentModalState={resetParentModalState}
                    link={link}
                />
            )}
            {step === 'confirmDownload' && (
                <ConfirmDownloadContent
                    filename={fileSelected.filename}
                    ownerEmail={fileSelected.owner_email}
                    onFinish={handleConfirmDownload}
                    setAdditionalWrapClassName={setAdditionalWrapClassName}
                    setLoadingStatus={setLoadingStatus}
                    resetParentModalState={resetParentModalState}
                    filePolicyId={fileSelected.policy?.id}
                />
            )}
        </div>
    );
});

export default OpenFile;
