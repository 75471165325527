import {
    Component,
    ContextType,
    createRef,
    forwardRef,
    MutableRefObject,
    ReactElement,
    ReactNode,
    RefObject,
} from 'react';

import { Form } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import OtpInput from 'react-otp-input';
import { useTranslation, WithTranslation } from 'react-i18next';
import classNames from 'classnames';

import type { ReactRef } from '@/types/types';

import { SnapshotContext } from './CodeAuthenticatorSnapshotContext';
import SendButtons from './SendButtons';
import StepBox from './StepBox';
import CheckCodeError from './CheckCodeError';
import TimeoutCleaner from './TimeoutCleaner';
import {
    IdentityType,
    InitFormValues,
    ITimeoutCleaner,
    StatusMessage,
    TargetButton,
} from './interfaces';
import styles from './CodeAuthenticator.module.scss';
import './antOverride.scss';

type SendCodeCallback<T> = (value: T) => Promise<void>;

interface Props<IdentityValueType> extends WithTranslation {
    firstStepTitle: string;
    identityType: IdentityType;
    initIdentityValues: InitFormValues;
    getIdentity: (form: FormInstance) => IdentityValueType;
    sendCode: SendCodeCallback<IdentityValueType>;
    startingStep?: number;
    resendTimeout?: number;
    firstStepMessages?: StatusMessage[];
    onSendCodeError?: (error?: Error) => void | Promise<void>;
    secondStepTitle: ReactNode;
    codeLength: number;
    checkCode: (code: string) => void | Promise<void>;
    retryLimit?: number;
    secondStepMessages?: StatusMessage[];
    onCheckCodeError?: (error?: Error) => void | Promise<void>;
    checkIsWrongCode?: (error: Error) => boolean;
    onRetryLimitReached?: () => void;
    authSucceed: boolean;
    autoSendAllowed?: boolean;
    renderWithRef?: (ref: MutableRefObject<FormInstance>, forceDisabled: boolean) => ReactElement;
    render?: (forceDisabled: boolean) => ReactElement;
    renderExtraStep?: (forceDisabled: boolean) => ReactElement;
    renderRadioButtons?: () => ReactElement;
    timerRef?: MutableRefObject<ITimeoutCleaner>;
    autoCodeSend?: boolean;
}

interface State {
    isSendCodeLoading: boolean;
    resendRestricted: boolean;
    showTimeout: boolean;
    targetButton: TargetButton;
    hasSuccessSending: boolean;
    codeInputDisabled: boolean;
    codeValue: string;
    attemptsRemain: number;
    showSecondStepInternalErrors: boolean;
    isWrongCodeError: boolean;
}

type FinalSendButtonsState = Pick<State, 'isSendCodeLoading' | 'targetButton'>;
type InitMainState = Pick<State, 'showSecondStepInternalErrors' | 'attemptsRemain' | 'codeValue'>;

const RESEND_TIMEOUT_DEFAULT = 60;
const RETRY_LIMIT_DEFAULT = 3;
const OTP_INPUT_FIRST_NUM_SELECTOR = `.${styles['OTP-input']} .${styles['code-input-box']}`;

class CodeAuthenticator<IdentityValueType> extends Component<Props<IdentityValueType>, State> {
    private readonly formRef: RefObject<FormInstance>;

    private readonly codeInputRef: RefObject<HTMLDivElement>;

    private readonly nameSpace = 'codeAuth';

    private readonly retryLimit;

    private readonly resendTimeoutValue: number;

    private readonly timeoutCleaner: ITimeoutCleaner;

    private mounted: boolean;

    constructor(props: Props<IdentityValueType>, context: ContextType<typeof SnapshotContext>) {
        super(props, context);
        const { handleForceClearTimer, typedContext } = this;
        const { resendTimeout, retryLimit, identityType } = this.props;

        this.formRef = createRef();
        this.codeInputRef = createRef();
        this.resendTimeoutValue = resendTimeout || RESEND_TIMEOUT_DEFAULT;
        this.retryLimit = retryLimit || RETRY_LIMIT_DEFAULT;
        this.timeoutCleaner = new TimeoutCleaner(handleForceClearTimer);

        this.state = {
            ...this.getInitMainState(),
            isSendCodeLoading: false,
            resendRestricted: false,
            showTimeout: false,
            codeInputDisabled: !typedContext?.codeSentMap[identityType],
            targetButton: 'send',
            hasSuccessSending: false,
            isWrongCodeError: false,
        };
    }

    private get typedContext(): ContextType<typeof SnapshotContext> {
        // context field has type any, so we use this getter for working with correct type
        return this.context as ContextType<typeof SnapshotContext>;
    }

    componentDidMount(): void {
        const { timerRef } = this.props;
        this.mounted = true;
        if (timerRef) {
            timerRef.current = this.timeoutCleaner;
        }
    }

    componentDidUpdate(prevProps: Readonly<Props<IdentityValueType>>): void {
        const {
            typedContext,
            props: {
                autoSendAllowed,
                authSucceed,
                identityType,
                autoCodeSend,
            },
        } = this;
        if (autoSendAllowed
            && !typedContext?.codeSentMap[identityType]
            && !prevProps.autoSendAllowed
            && !authSucceed
            && this.checkFormValid()) {
            this.sendCode();
        }
        if (autoCodeSend && autoCodeSend !== prevProps.autoCodeSend) {
            this.sendCode();
        }
    }

    componentWillUnmount(): void {
        this.mounted = false;
    }

    toggleResendButton = (isActive: boolean): void => {
        this.setState({
            showTimeout: !isActive,
            resendRestricted: !isActive,
        });
    };

    enableResendButton = (): void => {
        this.toggleResendButton(true);
    };

    handleForceClearTimer = (): void => {
        this.setState({
            showTimeout: false,
            resendRestricted: false,
        });
    };

    private getInitMainState(): InitMainState {
        return {
            showSecondStepInternalErrors: false,
            attemptsRemain: this.retryLimit,
            codeValue: '',
        };
    }

    private async requestCode(): Promise<void> {
        const { getIdentity, sendCode: sendCodeProp } = this.props;
        const formInstance = this.formRef.current;

        const identity: IdentityValueType = getIdentity(formInstance);
        await sendCodeProp(identity);
    }

    private setFocus(): void {
        const { current } = this.codeInputRef;
        const codeInput = current && current.querySelector<HTMLInputElement>(OTP_INPUT_FIRST_NUM_SELECTOR);
        if (codeInput) {
            codeInput.focus();
        }
    }

    private handleSendCodeSuccess(finalButtonsState: FinalSendButtonsState): void {
        this.setState({
            codeInputDisabled: false,
            showTimeout: true,
            resendRestricted: true,
            hasSuccessSending: true,
            ...finalButtonsState,
        }, () => {
            this.setFocus();
        });
    }

    private handleSendCodeError(finalButtonsState: FinalSendButtonsState, error: Error): void {
        const { onSendCodeError } = this.props;

        this.setState(finalButtonsState);
        if (onSendCodeError) {
            onSendCodeError(error);
        }
    }

    sendCode = async (): Promise<void> => {
        const { typedContext, props: { identityType } } = this;
        const finalState: FinalSendButtonsState = {
            isSendCodeLoading: false,
            targetButton: 'resend',
        };
        try {
            this.timeoutCleaner.recharge();
            typedContext?.onCodeSend(identityType);
            this.setState({
                isSendCodeLoading: true,
                codeInputDisabled: true,
                ...this.getInitMainState(),
            });
            await this.requestCode();
            if (this.mounted) {
                this.handleSendCodeSuccess(finalState);
            }
        } catch (error) {
            console.log('send code error', error);
            if (this.mounted) {
                this.handleSendCodeError(finalState, error as Error);
            }
        }
    };

    private checkFormValid(): boolean {
        const { identityType } = this.props;
        const formInstance = this.formRef.current;

        if (!formInstance) {
            return false;
        }

        const { getFieldError, getFieldValue } = formInstance;
        if (!getFieldValue(identityType)) {
            return false;
        }
        return !!getFieldValue(identityType) && !getFieldError([identityType]).length;
    }

    private handleCheckCodeError(error: Error): void {
        const { onCheckCodeError, onRetryLimitReached, checkIsWrongCode } = this.props;
        const isWrongCodeError = checkIsWrongCode?.(error) ?? error instanceof CheckCodeError;
        this.setState(
            ({ attemptsRemain }) => {
                let newAttemptsCount = isWrongCodeError ? attemptsRemain - 1 : attemptsRemain;
                if (newAttemptsCount < 0) {
                    newAttemptsCount = 0;
                }
                const isLimitReached = newAttemptsCount <= 0;
                return ({
                    isWrongCodeError,
                    showSecondStepInternalErrors: true,
                    attemptsRemain: newAttemptsCount,
                    codeValue: '',
                    resendRestricted: false,
                    showTimeout: false,
                    codeInputDisabled: isLimitReached,
                });
            },
            () => {
                const { attemptsRemain } = this.state;
                if (attemptsRemain === 0) {
                    onRetryLimitReached?.();
                }
            },
        );
        if (onCheckCodeError) {
            onCheckCodeError(error);
        }
    }

    private handleCheckCodeSuccess(): void {
        this.setState({ showTimeout: false });
    }

    private async checkCode(codeValue: string): Promise<void> {
        const { checkCode } = this.props;

        try {
            // Disabling necessary to prevent next calls if user continue typing
            this.setState({ showSecondStepInternalErrors: false, codeInputDisabled: true });
            await checkCode(codeValue);
            if (this.mounted) {
                this.handleCheckCodeSuccess();
            }
        } catch (error) {
            console.log('check code error', error);
            if (this.mounted) {
                this.handleCheckCodeError(error as Error);
            }
        }
    }

    private getSecondStepMessages(): StatusMessage[] {
        const { secondStepMessages, authSucceed } = this.props;
        const { attemptsRemain, showSecondStepInternalErrors, isWrongCodeError } = this.state;
        let messages: StatusMessage[] = secondStepMessages || [];
        if (!authSucceed && showSecondStepInternalErrors) {
            const secondStepNameSpace = `${this.nameSpace}.secondStepCommon`;
            if (isWrongCodeError && attemptsRemain < this.retryLimit) {
                const messageKey = attemptsRemain === 0 ? 'incorrectCode' : 'attemptsRemain';
                messages = [
                    {
                        hasError: true,
                        options: { attemptsRemain },
                        message: `${secondStepNameSpace}.${messageKey}`,
                    },
                    ...messages,
                ];
            } else {
                messages = [
                    {
                        hasError: true,
                        message: `${secondStepNameSpace}.somethingWrong`,
                    },
                    ...messages,
                ];
            }
        }
        return messages;
    }

    onChangeCode = async (codeValue: string): Promise<void> => {
        const { codeLength } = this.props;
        const reg = /^\d+$/;
        if (reg.test(codeValue)) {
            this.setState({ codeValue });

            if (codeValue.length === codeLength) {
                await this.checkCode(codeValue);
            }
        }
    };

    render(): JSX.Element {
        const {
            firstStepMessages, firstStepTitle, initIdentityValues,
            secondStepTitle, codeLength, authSucceed,
            render, renderExtraStep, renderWithRef, renderRadioButtons,
            startingStep = 1,
        } = this.props;
        const {
            showTimeout, isSendCodeLoading, resendRestricted, targetButton,
            hasSuccessSending, codeValue, codeInputDisabled, attemptsRemain,
        } = this.state;
        const { resendTimeoutValue } = this;

        const firstStepMessagesToRender = firstStepMessages || [];
        const secondStepMessagesToRender = this.getSecondStepMessages();

        const codeDisabled = codeInputDisabled || isSendCodeLoading || authSucceed;
        const forceDisabled = isSendCodeLoading || authSucceed;
        const mainChildren = renderWithRef ? renderWithRef(this.formRef, forceDisabled) : render?.(forceDisabled);
        const extraChildren = renderExtraStep?.(forceDisabled);
        const radioButtonsChildren = renderRadioButtons?.();
        const disabledOnSucceed = { [styles.disabled]: authSucceed };

        return (
            <Form
                initialValues={initIdentityValues}
                ref={this.formRef}
                className={classNames(styles['code-auth-form'], 'code-form-override')}
            >
                {extraChildren}
                <StepBox
                    isSendingStep
                    disabled={authSucceed}
                    stepNumber={startingStep}
                    title={firstStepTitle}
                    messages={firstStepMessagesToRender}
                >
                    <div className={styles['send-code-wrapper']}>
                        <div className={classNames(styles['identity-input-wrapper'], disabledOnSucceed)}>
                            {mainChildren}
                        </div>
                        <div>
                            {radioButtonsChildren}
                        </div>
                        <Form.Item shouldUpdate>
                            {() => {
                                const generalDisabled = authSucceed || !this.checkFormValid();
                                return (
                                    <SendButtons
                                        generalDisabled={generalDisabled}
                                        isLoading={isSendCodeLoading}
                                        showTimeout={showTimeout}
                                        resendRestricted={resendRestricted}
                                        resentTimeoutValue={resendTimeoutValue}
                                        hasSuccessSending={hasSuccessSending}
                                        targetButton={targetButton}
                                        onClick={this.sendCode}
                                        onTimeout={this.enableResendButton}
                                    />
                                );
                            }}
                        </Form.Item>
                    </div>
                </StepBox>

                <StepBox
                    disabled={authSucceed}
                    stepNumber={startingStep + 1}
                    title={secondStepTitle}
                    messages={secondStepMessagesToRender}
                    isCodeInputStep
                >
                    <div ref={this.codeInputRef}>
                        <OtpInput
                            containerStyle={classNames(styles['OTP-input'], disabledOnSucceed)}
                            inputStyle={classNames(
                                styles['code-input-box'],
                                { [styles['error-otp']]: !authSucceed && (attemptsRemain < this.retryLimit) },
                            )}
                            inputType="tel"
                            renderInput={(props) => (
                                <input {...props} disabled={codeDisabled} />
                            )}
                            value={codeValue}
                            onChange={this.onChangeCode}
                            numInputs={codeLength}
                        />
                    </div>
                </StepBox>
            </Form>
        );
    }
}

CodeAuthenticator.contextType = SnapshotContext;

type PublicProps<T> = Omit<Props<T>, keyof WithTranslation | 'timerRef'>;

type RefType = ReactRef<ITimeoutCleaner>;

// eslint-disable-next-line @typescript-eslint/comma-dangle
const I18nWrapper = <IdentityValueType, >(
    props: PublicProps<IdentityValueType>,
    ref: RefType,
): ReactElement<PublicProps<IdentityValueType>> => {
    const { i18n, t, ready } = useTranslation();

    return (
        <CodeAuthenticator<IdentityValueType>
            i18n={i18n}
            t={t}
            tReady={ready}
            timerRef={ref as MutableRefObject<ITimeoutCleaner>}
            {...props}
        />
    );
};

export default forwardRef(I18nWrapper) as <T>(props: PublicProps<T> & { ref?: RefType }) => ReactElement;
