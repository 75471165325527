import React from 'react';

import type { ExceptionStatusType, ResultProps } from 'antd/lib/result';
import { TFunction } from 'i18next';

import { I18Payload } from '@/content';
import PermissionsErrorExplain, { PermissionsErrorExplainProps } from './PermissionsErorrExplain';
import DebugInfo, { DebugInfoProps } from './DebugInfo';
import type { EntityLoadErrorType } from '@/types/types';
import { errorsNameSpace } from '../constants';
import { LinkBasedOpenOptions } from '@/config/openOptions';

export type AntResultProps = Pick<ResultProps, 'title' | 'subTitle' | 'status' | 'icon' | 'extra'>;

export const MAP_ERROR_TYPE_TO_ANT_RESULT_STATUS: Record<EntityLoadErrorType | 'fileBlocked' | 'fileScanning', ExceptionStatusType> = {
    noPermissions: 403,
    fileBlocked: 403,
    fileScanning: 403,
    notFound: 404,
    serverError: 500,
    permissionCheckFail: 500,
};

export interface DefaultI18nOptions {
    item: string;
}

export type I18nOptions = Record<string, string> & DefaultI18nOptions;

export type TitleCreator = (options?: I18nOptions, availableOptions?: boolean) => I18Payload;

export const MAP_ERROR_TYPE_TO_TITLE_CREATOR: Record<EntityLoadErrorType, TitleCreator> = {
    noPermissions: () => [`${errorsNameSpace}.noPermissions`],
    permissionCheckFail: () => [`${errorsNameSpace}.permissionCheckFail`],
    notFound: ({ item }) => ['general.errors.404', { type: item }],
    serverError: ({ item }) => [
        'general.specterxCommon.couldNotLoadItemTryAgain',
        { item },
    ],
};

export const MAP_ERROR_TYPE_TO_SUBTITLE_I18_KEY: Partial<
Record<EntityLoadErrorType, string>> = {
    permissionCheckFail: `${errorsNameSpace}.permissionCheckFail_subtitle`,
};

export type ExtraCreator<P = Record<string, never>> = (props: P, extraOptions: LinkBasedOpenOptions[]) => JSX.Element;

export interface MapErrorTypeToDefaultExtraProps {
    noPermissions: PermissionsErrorExplainProps;
    permissionCheckFail: DebugInfoProps;
}

export type MapErrorTypeToDefaultExtra = {
    [key in keyof MapErrorTypeToDefaultExtraProps]: ExtraCreator<MapErrorTypeToDefaultExtraProps[key]>
};

export const MAP_ERROR_TYPE_TO_EXTRA: MapErrorTypeToDefaultExtra = {
    noPermissions: (props) => <PermissionsErrorExplain {...props} />,
    permissionCheckFail: (props) => <DebugInfo {...props} />,
};

export const createDefaultExtraPropsMap = (
    item: string,
    ownerEmail: string,
    debugInfo?: Record<string, string>,
): MapErrorTypeToDefaultExtraProps => ({
    noPermissions: { item, ownerEmail },
    permissionCheckFail: { data: debugInfo || {} },
});

export const getDefaultErrorResultProps = (
    t: TFunction,
    errorType: EntityLoadErrorType,
    itemKey: string,
    ownerEmail: string,
    debugInfo?: Record<string, string>,
): AntResultProps => {
    const item = t(itemKey);

    return {
        title: errorType !== null ? t(...MAP_ERROR_TYPE_TO_TITLE_CREATOR[errorType]({ item })) : null,
        subTitle: t(MAP_ERROR_TYPE_TO_SUBTITLE_I18_KEY[errorType]),
        status: MAP_ERROR_TYPE_TO_ANT_RESULT_STATUS[errorType],
        extra: MAP_ERROR_TYPE_TO_EXTRA[errorType]?.(
            createDefaultExtraPropsMap(item, ownerEmail, debugInfo)[errorType],
        ),
    };
};
