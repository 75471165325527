import { FC } from 'react';

import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import './index.scss';

const SuccessShareScreen: FC = () => (
    <div className="success-share-screen-container">
        <div className="success-circle">
            <CheckCircleFilled className="success-icon" />
        </div>
        <div className="success-title">
            <span>Files shared successfully</span>
        </div>
        <div className="success-subtitle">
            <span>
                You may now close tab.
                {' '}
                <br />
                Recipients will be notified about shared files
            </span>
        </div>
        <div className="done-button-container">
            <Button
                className="done-button"
                onClick={() => { window.location.href = 'https://drive.google.com/drive/'; }}
            >
                Done
            </Button>
        </div>
    </div>
);

export default SuccessShareScreen;
