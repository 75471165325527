import React, { FC, useEffect } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '@/components/hooks';

import CollapsibleWidget from '../CollapsibleWidget';
import DownloadItem from './DownloadItem';
import nameSpace from './nameSpace';
import { OpenOptions } from '@/config/openOptions';

interface BatchDownloadWidgetProps {
    wrapperClassName?: string;
}

const BatchDownloadWidget: FC<BatchDownloadWidgetProps> = observer(({ wrapperClassName }) => {
    const { batchDownloadStore, filesAccessStore } = useStores();
    const {
        isWidgetOpen, downloadsList, clear, downloadedItemsCount,
    } = batchDownloadStore;

    const { protectedFileInfo, retryOpenFileRequestForWidget } = filesAccessStore;

    const { t } = useTranslation();

    const closeWidget = (): void => {
        clear();
    };

    useEffect(() => {
        if (protectedFileInfo) {
            const { fileId, accessType } = protectedFileInfo;
            if (fileId && accessType === OpenOptions.download) {
                retryOpenFileRequestForWidget();
            }
        }
    }, [protectedFileInfo]);

    const widgetTitle = t(`${nameSpace}.title`, { filesCount: downloadsList.length, downloadedItemsCount });
    /* eslint-disable camelcase */
    return isWidgetOpen && (
        <CollapsibleWidget
            wrapperClassName={wrapperClassName}
            closeWidget={closeWidget}
            title={widgetTitle}
            renderChildren={() => downloadsList.map(({
                id,
                status,
                message,
                link,
                name,
                downloadStatus,
            }) => {
                return <DownloadItem
                    key={id}
                    id={id}
                    status={status}
                    link={link}
                    message={message}
                    name={name}
                    downloadStatus={downloadStatus || status}
                />
            })}
        />
    );
});

export default BatchDownloadWidget;
