import { Auth } from 'aws-amplify';
import type { FormInstance } from 'antd/lib/form';

import { captureException } from '@sentry/react';
import { BASEURL, ENDPOINTS, SPXAPI } from '@/api';
import type {
    CognitoAuthError,
    OTPAuthType,
    PhoneIdentity,
} from '@/types/types';
import { CognitoErrorCodes } from '@/types/types';
import { StatusMessage } from '../../Common/CodeAuthenticator';
import {
    captureErrorForSentry,
    retryRequest,
    checkIsNetworkError,
    authErrorMessageToI18nPayload,
    getErrorResponse,
} from '../../utils';
import { codeAuthNameSpace, COGNITO_CALL_RETRIES, LAMBDA_TIMEOUT_MESSAGE_PARTIAL } from './constants';

const generateRandomPass = (): string => (
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    `${Math.random().toString(36).slice(2)}${Math.random().toString(36).toUpperCase().slice(2)}!`
);

export const generateCode = async (
    APIInstance: SPXAPI, emailLower: string, authType: OTPAuthType, phoneIdentity?: PhoneIdentity,
): Promise<void> => {
    const payload = phoneIdentity ? { body: phoneIdentity } : {};
    const queryParams = new URLSearchParams(window.location.search);
    const linkId = queryParams.get('linkId');
    await retryRequest<void>(
        async (): Promise<void> => (
            APIInstance.post(BASEURL.backend(), ENDPOINTS.generateCode(emailLower, authType, linkId), payload)
        ),
        (error) => {
            captureException(error);
            return checkIsNetworkError(error);
        },
        COGNITO_CALL_RETRIES,
    );
};

export const filterCognitoError = (error: CognitoAuthError): boolean => {
    let result = error.code === CognitoErrorCodes.NetworkError;
    if (!result) {
        result = (
            error.code === CognitoErrorCodes.UnexpectedLambdaException
            && error.message?.toLowerCase().includes(LAMBDA_TIMEOUT_MESSAGE_PARTIAL)
        );
    }
    return result;
};

export const trySignUp = async (usernameLower: string): Promise<void> => {
    try {
        await retryRequest(
            async () => Auth.signUp({
                username: usernameLower,
                password: generateRandomPass(),
                clientMetadata: { autoVerifyUser: 'true' },
            }),
            (error: unknown): boolean => filterCognitoError(error as CognitoAuthError),
            COGNITO_CALL_RETRIES,
        );
    } catch (error) {
        if (!((error as CognitoAuthError).code === CognitoErrorCodes.UsernameExistsException
        || (error as CognitoAuthError).message?.toLowerCase()?.includes('user with this email already exists'))) {
            console.log('sign up fail', error);
            throw error;
        }
    }
};

export const getEmail = (formInstance: FormInstance): string => {
    const { getFieldValue } = formInstance;
    return getFieldValue('email').toLowerCase();
};

const checkIsUserNotFound = (error: unknown): boolean => (
    (error as CognitoAuthError).code === CognitoErrorCodes.UserNotFoundException
);

type ErrorMessagePayload = Omit<StatusMessage, 'hasError'>;

const createErrorState = (error: unknown, isNotFound: boolean): ErrorMessagePayload => {
    const errorMessage = getErrorResponse(error)?.message || (error as CognitoAuthError)?.message;
    const [message, options] = authErrorMessageToI18nPayload(errorMessage, () => [
        `${codeAuthNameSpace}.firstStepCommon.${isNotFound ? 'userNotFound' : 'couldNotSendCode'}`,
    ]);
    return { message, options };
};

export const onSendCodeError = (error: unknown, setErrorMessage: (messages: StatusMessage[]) => void): void => {
    const isNotFound = checkIsUserNotFound(error);
    setErrorMessage([{
        ...createErrorState(error, isNotFound),
        hasError: true,
    }]);
    captureErrorForSentry(error, 'useOTPFlow.onSendCodeError', { severityLevel: isNotFound ? 'warning' : 'error' });
};

export const getPhoneValues = (formInstance: FormInstance): PhoneIdentity => {
    const { getFieldValue } = formInstance;
    return {
        prefix: getFieldValue('prefix').prefix,
        phone: getFieldValue('phone'),
    };
};
