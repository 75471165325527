import {
    action,
    computed,
    IReactionDisposer,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from 'mobx';
import difference from 'lodash/difference';
import differenceWith from 'lodash/differenceWith';

import AuthSettingsStore from '../AuthSettingsStore';
import UsersListStore from '../UsersListStore';
import UserStore from '../UserStore';
import { UsersPhonesStore } from '../UsersPhonesStore';
import { LinksLoader } from './LinksLoader';
import {
    Recipient,
    RecipientRoles,
    RecipientType,
    SharedUser,
    StorageProvider,
} from '@/types/types';
import {
    BulkShareRequestPayload,
    FilesToSharePayload,
    FileShareUsersDict,
    LinkPreloadMode,
    LinksPreloadingConfig,
    RecipientsShareHints,
    RecipientToDisplay,
    ShareLink,
    ShareLinksDict,
    SharedUserToDisplay,
    UserEmailsResponse,
    UserSharedOwner,
} from './interfaces';
import { captureErrorForSentry } from '../../components/utils';
import {
    BASEURL,
    ENDPOINTS,
    getFilePermissions,
} from '../../api';
import {
    BEUserToDisplayUser,
    compareActions,
    displayUserToBE,
    prepareRecipientsPayload,
    roleToPrimaryData,
} from './helpers';
import {
    EMPTY_RECIPIENT,
    INIT_SHARE_LINK_PARTIAL,
    INVITE_AS_DEFAULT,
    SHARING_HINT_TIMEOUT,
    RECIPIENTS_HINTS_LIST,
} from './constants';

export {
    BEUserToDisplayUser,
    compareActions,
    displayUserToBE,
    RECIPIENTS_HINTS_LIST,
    roleToPrimaryData,
};
export type { SharedUserToDisplay };

class SharedUsersStore {
    constructor(
        authSettingsStore: AuthSettingsStore,
        usersListStore: UsersListStore,
        usersPhonesStore: UsersPhonesStore,
        userStore: UserStore,
    ) {
        this.authSettingsStore = authSettingsStore;
        this.usersPhonesStore = usersPhonesStore;
        this.usersListStore = usersListStore;
        this.userStore = userStore;
        this.linksLoader = new LinksLoader(authSettingsStore);
        makeObservable(this);
    }

    @observable hasError = false;

    @observable sharedUsers: SharedUser[] = [];

    @observable recipientsList: Recipient[] = [];

    @observable emailsList: string[] = [];

    @observable currentInputEmail = '';

    @observable inviteAs: RecipientRoles = INVITE_AS_DEFAULT;

    @observable shareHint: RecipientsShareHints;

    @observable shareLinks: ShareLinksDict = new Map<string, ShareLink>();

    @observable fileSharedUsers: FileShareUsersDict = new Map<string, SharedUser[]>();

    @observable filesIds: string[];

    // TODO: add cache invalidation here
    @observable usersSharedOwnersList: UserSharedOwner[] = [];

    @observable notifyRecipients = true;

    @observable customMessage = '';

    @observable encryptionToggle = true;

    @observable loadingGroupMembersModal = false;

    @observable messageId = '';

    private readonly authSettingsStore: AuthSettingsStore;

    private readonly usersPhonesStore: UsersPhonesStore;

    private readonly usersListStore: UsersListStore;

    private readonly userStore: UserStore;

    private readonly linksLoader: LinksLoader;

    private shareHintTimeout: NodeJS.Timeout;

    private recipientsReactionDisposer: IReactionDisposer;

    private singleRecipientReactionDisposer: IReactionDisposer;

    private filesIdsReactionDisposer: IReactionDisposer;

    private linkPreloadMode: LinkPreloadMode;

    private isPreloadFlowActive = false;

    private origin: StorageProvider;

    @computed
    get sharedUsersToDisplay(): SharedUserToDisplay[] {
        return this.sharedUsers.map<SharedUserToDisplay>(BEUserToDisplayUser);
    }

    @computed
    get recipientsToDisplay(): RecipientToDisplay[] {
        return this.recipientsList.map<RecipientToDisplay>((recipient) => {
            const phoneInfo = this.usersPhonesStore.getUserPhone(recipient.email);
            return {
                ...recipient,
                phoneInfo,
            };
        });
    }

    @computed
    get hasShareError(): boolean {
        return this.hasError;
    }

    @computed
    get isOnlyOneRecipient(): boolean {
        return this.recipientsList.length === 1;
    }

    @computed
    get hasRecipients(): boolean {
        return !!this.recipientsList.length;
    }

    @computed
    get singleRecipientIdentity(): string {
        if (this.recipientsList.length === 1) {
            const firstRecipient = this.recipientsList[0];
            return firstRecipient.type === 'user' ? firstRecipient.email : firstRecipient.id;
        }
        return EMPTY_RECIPIENT;
    }

    @computed
    get recipientsEmails(): string[] {
        return this.recipientsList.map<string>(({
            email,
            type,
            id,
        }) => (type === 'user' ? email : id));
    }

    @computed
    get shareLinksToJS(): ShareLinksDict {
        return toJS(this.shareLinks);
    }

    @action
    private setShareHint(value: RecipientsShareHints): void {
        this.shareHint = value;
    }

    clearShareHint = (): void => {
        clearTimeout(this.shareHintTimeout);
        this.setShareHint(null);
    };

    addShareHint = (newValue: RecipientsShareHints): void => {
        clearTimeout(this.shareHintTimeout);
        this.setShareHint(newValue);
        this.shareHintTimeout = setTimeout(
            () => this.setShareHint(null),
            SHARING_HINT_TIMEOUT,
        );
    };

    @action
    setCurrentInputEmail = (newValue: string): void => {
        this.currentInputEmail = newValue;
    };

    @action
    setMessageId = (value: string): void => {
        this.messageId = value;
    };

    @action
    setInviteAs = (newValue: RecipientRoles): void => {
        this.inviteAs = newValue;
    };

    @action
    setEmailsList = (emails: string[]): void => {
        this.emailsList = emails;
    };

    @action
    setOwnersList = (ownersList: UserSharedOwner[]): void => {
        this.usersSharedOwnersList = ownersList;
    };

    @action
    setNotifyRecipients = (value: boolean): void => {
        this.notifyRecipients = value;
    };

    @action
    setCustomMessage = (value: string): void => {
        this.customMessage = value;
    };

    @action
    setEncryptionToggle = (value: boolean): void => {
        this.encryptionToggle = value;
    };

    @action
    setLoadingGroupMembersModal = (value: boolean): void => {
        this.loadingGroupMembersModal = value;
    };

    @action
    setFilesIds = (value: string[]): void => {
        this.filesIds = value;
    };

    fetchEmailsList = async (): Promise<void> => {
        try {
            const { API } = this.authSettingsStore;
            const { isExternal } = this.userStore;
            const endpoint = isExternal ? ENDPOINTS.getEmails() : ENDPOINTS.getOrganizationsEmails();
            const { emails }: UserEmailsResponse = await API.get(BASEURL.backend(), endpoint, {});
            if (!emails) {
                throw Error('Incorrect response from ENDPOINTS.getEmails(): Expected { emails: string[] }');
            }
            const nonEmptyEmails = emails.filter(Boolean);
            this.setEmailsList(nonEmptyEmails);
        } catch (error) {
            captureErrorForSentry(error, 'SharedUsersStore.fetchEmailsList');
            console.log('could not fetch emails', error);
        }
    };

    tryBulkSavePhonesSettings = async (): Promise<boolean> => (
        this.usersPhonesStore.tryBulkSavePhones(this.sharedUsers.map<string>(({ email }) => email))
    );

    @action
    setSharedUsers = (sharedUsers: SharedUser[], fileId: string): void => {
        this.sharedUsers = sharedUsers;
        this.setFileSharedUsersList(fileId, sharedUsers);
    };

    partialUpdateRecipient = (email: string, dataForUpdate: Partial<Recipient>): void => {
        const newRecipientsList = this.recipientsList.map((recipient) => {
            let newRecipient: Recipient = recipient;
            if (newRecipient.email === email) {
                newRecipient = { ...newRecipient, ...dataForUpdate };
            }
            return newRecipient;
        });

        this.setRecipientsList(newRecipientsList);
    };

    removeRecipient = (targetRecipientId: string): void => {
        const filteredList = this.recipientsList.filter(({ id }) => targetRecipientId !== id);
        this.setRecipientsList(filteredList);
    };

    @action
    private setRecipientsList(recipientsList: Recipient[]): void {
        this.recipientsList = recipientsList;
    }

    addRecipients = (newRecipients: Recipient[]): void => {
        this.setRecipientsList([...this.recipientsList, ...newRecipients]);
    };

    fetchSharedUsers = async (fileId: string, shouldFetchPhones = false): Promise<void> => {
        try {
            this.setHasShareError(false);
            let userList = this.fileSharedUsers.get(fileId);
            if(userList) {
                this.getFilePermissionsAndSaveInStore(fileId);
            } else {
                await this.getFilePermissionsAndSaveInStore(fileId);
            }
            if (shouldFetchPhones) {
                userList = this.fileSharedUsers.get(fileId);
                this.usersPhonesStore.fetchSharedUsersPhones(userList);
            }
        } catch (error) {
            captureErrorForSentry(error, 'SharedUsersStore.fetchSharedUsers');
            this.setHasShareError(true);
        }
    };

    getFilePermissionsAndSaveInStore = async (fileId: string): Promise<void> => {
        const { API } = this.authSettingsStore;
        const { users } = await getFilePermissions(API, fileId);
        this.setSharedUsers(users, fileId);
    }

    getFileSharedUsersList = (fileId: string): SharedUser[] => this.fileSharedUsers.get(fileId);

    @action
    setHasShareError = (val: boolean): void => {
        this.hasError = val;
    };

    @action
    setShareLinks = (newValue: ShareLinksDict): void => {
        this.shareLinks = newValue;
    };

    @action
    setFileSharedUsersList = (fileId: string, userList: SharedUser[]): void => {
        this.fileSharedUsers.set(fileId, userList);
    };

    @action
    setLinkForRecipient = (recipientEmail: string, newValue: ShareLink): void => {
        this.shareLinks.set(recipientEmail, newValue);
    };

    @action
    setSingleRecipientLink = (recipientEmail: string, newValue: ShareLink): void => {
        this.shareLinks.clear();
        this.shareLinks.set(recipientEmail, newValue);
    };

    private async tryLoadLink(recipientIdentity: string, recipientType: RecipientType): Promise<void> {
        const linkData = this.shareLinks.get(recipientIdentity);
        if (linkData) {
            this.setLinkForRecipient(recipientIdentity, { ...INIT_SHARE_LINK_PARTIAL, recipientType });
            const link = await this.linksLoader.createLink({
                filesIds: this.filesIds,
                recipientIdentity,
                recipientType,
                messageId: this.messageId,
                origin: this.origin,
            });
            const currentLinkData = this.shareLinks.get(recipientIdentity);
            if (currentLinkData) {
                this.setLinkForRecipient(recipientIdentity, { isLoading: false, link, recipientType });
            }
            this.setMessageId('');
        } else {
            console.error('Link data does not exists: recipient is', recipientIdentity);
        }
    }

    private setInitLinks(preloadMode: LinkPreloadMode): void {
        const linksMap: ShareLinksDict = new Map<string, ShareLink>();
        if (preloadMode === 'general') {
            const singleRecipient = this.singleRecipientIdentity !== EMPTY_RECIPIENT ? this.recipientsList[0] : null;
            const singleRecipientType = singleRecipient?.type || null;
            linksMap.set(
                this.singleRecipientIdentity, { ...INIT_SHARE_LINK_PARTIAL, recipientType: singleRecipientType },
            );
        } else if (preloadMode === 'allCover') {
            this.recipientsList.forEach(({ email, type, id }) => {
                const identity = type === 'user' ? email : id;
                linksMap.set(identity, { ...INIT_SHARE_LINK_PARTIAL, recipientType: type });
            });
            linksMap.set(
                EMPTY_RECIPIENT, { ...INIT_SHARE_LINK_PARTIAL, recipientType: null },
            );
        } else {
            this.recipientsList.forEach(({ email, type, id }) => {
                const identity = type === 'user' ? email : id;
                linksMap.set(identity, { ...INIT_SHARE_LINK_PARTIAL, recipientType: type });
            });
        }
        this.setShareLinks(linksMap);
    }

    forceRequestLink = async (recipientIdentity: string): Promise<void> => {
        const recipientType = this.recipientsList.find(
            ({ id, email }) => id === recipientIdentity || email === recipientIdentity,
        )?.type;
        if (!this.shareLinks.has(recipientIdentity)) {
            this.setLinkForRecipient(recipientIdentity, { ...INIT_SHARE_LINK_PARTIAL, recipientType });
        }
        await this.tryLoadLink(recipientIdentity, recipientType);
    };

    private setIsPreloadFlowActive(newValue: boolean): void {
        this.isPreloadFlowActive = newValue;
    }

    private onSingleRecipientChange = (recipientIdentity: string): void => {
        this.linksLoader.cancelAll();
        const recipientType = recipientIdentity !== EMPTY_RECIPIENT
            ? this.recipientsList[0]?.type
            : null;
        this.setSingleRecipientLink(recipientIdentity, { ...INIT_SHARE_LINK_PARTIAL, recipientType });
        this.tryLoadLink(recipientIdentity, recipientType);
    };

    private runLinksReaction(mode: LinkPreloadMode): void {
        this.linkPreloadMode = mode;
        if (mode === 'general') {
            this.singleRecipientReactionDisposer = reaction<string>(
                () => this.singleRecipientIdentity,
                this.onSingleRecipientChange,
            );
        } else {
            this.runRecipientsReaction();
        }
    }

    private runFilesIdsReaction(): void {
        this.filesIdsReactionDisposer = reaction<string[]>(
            () => this.filesIds,
            this.onFilesIdsChange,
        );
    }

    private onFilesIdsChange = (currentList: string[], prevList: string[]): void => {
        const hasDiff = !!difference(currentList, prevList).length;
        if (hasDiff) {
            this.linksLoader.cancelAll();
            runInAction(() => {
                this.shareLinks.forEach((linkData, recipientEmail) => {
                    this.shareLinks.set(recipientEmail, { ...INIT_SHARE_LINK_PARTIAL, recipientType: linkData.recipientType });
                    this.tryLoadLink(recipientEmail, linkData.recipientType);
                });
            });
        }
    };

    private handleDeletedRecipients(deletedRecipients: Recipient[]): void {
        runInAction(() => {
            deletedRecipients.forEach(({ type, email: recipientEmail, id }) => {
                const isUser = type === 'user';
                if (this.linkPreloadMode === 'personal') {
                    const identity = isUser ? recipientEmail : id;
                    this.linksLoader.cancelRequest(identity);
                    this.shareLinks.delete(identity);
                }
                if (isUser) {
                    this.usersPhonesStore.deletePhone(recipientEmail);
                } else {
                    const group = this.usersListStore.userGroupsList.find(({ groupId }) => groupId === id);
                    (group?.members || []).forEach(({ email }) => this.usersPhonesStore.deletePhone(email));
                }
            });
        });
    }

    handleAddedRecipients(addedRecipients: Recipient[]): void {
        addedRecipients.forEach(({ email, id, type }) => {
            const isUser = type === 'user';
            if (this.linkPreloadMode === 'personal') {
                const identity = isUser ? email : id;
                this.setLinkForRecipient(identity, { ...INIT_SHARE_LINK_PARTIAL, recipientType: type });
                this.tryLoadLink(identity, type);
            }
            if (isUser) {
                this.usersPhonesStore.fillPhones(email);
            } else {
                this.usersPhonesStore.fetchGroupMembersPhones(id);
            }
        });
    }

    private onRecipientsListChange = (newList: Recipient[], prevList: Recipient[]): void => {
        const addedRecipients: Recipient[] = differenceWith(
            newList,
            prevList,
            ({ id: newId }, { id }) => newId === id,
        );
        const deletedRecipients: Recipient[] = differenceWith(
            prevList,
            newList,
            ({ id: newId }, { id }) => newId === id,
        );
        this.handleDeletedRecipients(deletedRecipients);
        this.handleAddedRecipients(addedRecipients);
    };

    private cancelAllLinksRequests(): void {
        this.linksLoader.cancelAll();
    }

    @action
    clearSharedUsers = (): void => {
        this.hasError = false;
        this.sharedUsers.forEach(({ email }) => {
            this.usersPhonesStore.deletePhone(email);
        });
        this.sharedUsers = [];
        this.fileSharedUsers.clear();
    };

    @action
    clearRecipients = (): void => {
        this.recipientsList = [];
        this.currentInputEmail = '';
        this.inviteAs = INVITE_AS_DEFAULT;
        this.notifyRecipients = true;
        this.setCustomMessage('');
        this.usersPhonesStore.clearPhones();
        this.disposeReaction('recipientsReactionDisposer');
    };

    @action
    clearLinks = (): void => {
        this.filesIds = [];
        this.shareLinks = new Map<string, ShareLink>();
        this.origin = null;
    };

    finishRecipientsFlow = (): void => {
        this.cancelLinksPreloading();
        this.clearRecipients();
    };

    private disposeReaction(disposerName: 'recipientsReactionDisposer'
    | 'filesIdsReactionDisposer'
    | 'singleRecipientReactionDisposer'): void {
        this[disposerName]?.();
        this[disposerName] = null;
    }

    cancelLinksPreloading = (): void => {
        if (this.isPreloadFlowActive) {
            this.setIsPreloadFlowActive(false);
            this.disposeReaction('recipientsReactionDisposer');
            this.disposeReaction('singleRecipientReactionDisposer');
            this.disposeReaction('filesIdsReactionDisposer');
            this.cancelAllLinksRequests();
            this.clearLinks();
            this.linkPreloadMode = null;
        }
    };

    runRecipientsReaction = (): void => {
        if (!this.recipientsReactionDisposer) {
            this.recipientsReactionDisposer = reaction<Recipient[]>(
                () => this.recipientsList,
                this.onRecipientsListChange,
            );
        }
    };

    startLinksPreloading = ({
        filesIds = [],
        origin,
        mode,
    }: LinksPreloadingConfig): void => {
        if (!this.isPreloadFlowActive) {
            this.origin = origin;
            this.setIsPreloadFlowActive(true);
            this.setInitLinks(mode);
            this.runLinksReaction(mode);
            this.runFilesIdsReaction();
            this.setFilesIds(filesIds);
        } else {
            console.error('links preloading is already active');
        }
    };

    fetchOwners = async (): Promise<void> => {
        const { API } = this.authSettingsStore;
        const ownersList = await API.get(BASEURL.backend(), ENDPOINTS.getOwnersList(), {});
        this.setOwnersList(ownersList);
    };

    bulkShareFiles = async ({
        files,
        origin,
    }: FilesToSharePayload): Promise<void> => {
        const {
            authSettingsStore: { API },
            notifyRecipients,
            messageId,
            encryptionToggle,
            recipientsToDisplay,
        } = this;
        const recipients = prepareRecipientsPayload(recipientsToDisplay);
        const requestBody: BulkShareRequestPayload = {
            files,
            notify_recipients: notifyRecipients,
            message_id: messageId,
            protect_message: encryptionToggle,
            ...recipients,
        };
        if (origin) {
            requestBody.origin = origin;
        }
        await API.post(BASEURL.backend(), ENDPOINTS.shareFiles(), { body: requestBody });
    };

    createNew = (): SharedUsersStore => (
        new SharedUsersStore(this.authSettingsStore, this.usersListStore, this.usersPhonesStore, this.userStore)
    );
}

export default SharedUsersStore;
