import { REQUIRED_PHONE_VERIFICATION, REQUIRED_EMAIL_VERIFICATION, LANGUAGE_POLICY } from '@/consts';
import { PolicySetting, PolicySettingValue } from '../../../../types/types';
import { PolicyAggregate } from '../../../../components/utils';
import { SharedFile } from '../interfaces';
import { countBy, maxBy } from 'lodash';

type HashableSettingValues = Exclude<PolicySettingValue, string[]>;

interface SettingData {
    policySetting?: PolicySetting;
    valuesSet: Set<HashableSettingValues>;
}

type MapIdToSetting = Record<string, SettingData>;

const filterActiveSameSettings = (settingsList: PolicyAggregate[], settingsIds: string[]): PolicyAggregate => {
    const mapIdToSetting: MapIdToSetting = settingsIds.reduce<MapIdToSetting>((acc, settingId) => {
        acc[settingId] = {
            valuesSet: new Set<HashableSettingValues>(),
        };
        return acc;
    }, {});
    settingsList.forEach((settingsDict) => {
        settingsIds.forEach((settingId) => {
            const setting = settingsDict?.[settingId];
            if (setting && !Array.isArray(setting.value)) {
                mapIdToSetting[settingId].valuesSet.add(setting.value);
                mapIdToSetting[settingId].policySetting = setting;
            }
        });
    });
    return settingsIds.reduce<PolicyAggregate>((acc, settingId) => {
        const { policySetting, valuesSet } = mapIdToSetting[settingId];
        if (valuesSet.size === 1 && policySetting.value) {
            acc[settingId] = policySetting;
        }
        return acc;
    }, {});
};

const attachActiveBooleanSettings = (
    aggregate: PolicyAggregate,
    settings: PolicyAggregate,
    settingsIds: string[],
): PolicyAggregate => {
    const notIncludedSettingsIds = settingsIds.filter((settingId) => !aggregate[settingId]);
    if (notIncludedSettingsIds.length) {
        const enabledSettings = notIncludedSettingsIds.reduce<PolicyAggregate>((acc, settingId) => {
            const setting = settings[settingId];
            if (setting?.value) {
                acc[settingId] = setting;
            }
            return acc;
        }, {});
        return { ...aggregate, ...enabledSettings };
    }
    return aggregate;
};

const getDictBasedSettings = (filesList: SharedFile[]): PolicyAggregate[] => (
    filesList.map<PolicyAggregate>(({ policy }) => {
        const accessSettings = policy?.settings?.access || [];
        return accessSettings.reduce<PolicyAggregate>((acc, setting) => {
            if (setting) {
                acc[setting.id] = setting;
            }
            return acc;
        }, {});
    })
);

const ACTIVE_BOOLEAN_SETTINGS: string[] = [REQUIRED_EMAIL_VERIFICATION, REQUIRED_PHONE_VERIFICATION];
const SAME_SETTINGS: string[] = [LANGUAGE_POLICY];

const FALLBACK_AGGREGATE: PolicyAggregate = {
    [REQUIRED_EMAIL_VERIFICATION]: {
        id: REQUIRED_EMAIL_VERIFICATION,
        type: 'switch',
        value: true,
        description: 'Required email verification',
        public: true,
    },
};

const frequentLanguage = (allLangPolicies: PolicySetting[]): PolicySetting =>  {
    let freq = countBy(allLangPolicies.map(item => item.value));
    let freqLang = maxBy(Object.keys(freq), o => freq[o]);
    return allLangPolicies.find(item => item.value === freqLang);
};

const getAllFilesPoliciesAggregate = (filesList: SharedFile[]): PolicyAggregate => {
    if (!filesList.length) {
        return {};
    }

    const policySettingsList: PolicyAggregate[] = getDictBasedSettings(filesList);
    const frequentLangPolicy = frequentLanguage(policySettingsList.map(item => item['language']));

    const activeBooleanSettings = policySettingsList.reduce<PolicyAggregate>((acc, settingsDict) => {
        if (settingsDict) {
            return attachActiveBooleanSettings(acc, settingsDict, ACTIVE_BOOLEAN_SETTINGS);
        }
        return acc;
    }, {});

    const sameSettingsSubAggregate = filterActiveSameSettings(policySettingsList, SAME_SETTINGS);

    const allSettings = { ...activeBooleanSettings, ...sameSettingsSubAggregate };

    const possibleEmailVerificationSetting: PolicyAggregate = (
        !allSettings[REQUIRED_PHONE_VERIFICATION]
        && !allSettings[REQUIRED_EMAIL_VERIFICATION]
    )
        ? FALLBACK_AGGREGATE
        : {};
    
    return { ...allSettings, ...possibleEmailVerificationSetting, language: frequentLangPolicy };
};

export default getAllFilesPoliciesAggregate;
