import { memo, useContext } from 'react';
import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Select } from '@/components/Common/UIKit';
import { LANGUAGES, SUPPORTED_LANGUAGES } from '@/content/languages';

import styles from './LanguageSwitcher.module.scss';

import { PageLanguageContext } from '../../PageLanguageContextProvider';

const LanguageSwitcher: FC = () => {
    const { i18n } = useTranslation();
    const { chooseLanguage } = useContext(PageLanguageContext);

    return (
        <Select
            popupMatchSelectWidth={false}
            value={i18n.language as LANGUAGES}
            onChange={chooseLanguage}
            options={SUPPORTED_LANGUAGES}
            className={styles['select-language']}
        />
    );
};

export default memo(LanguageSwitcher);
