import React, { FC } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Download from './Download';
import OpenSecurely from './OpenSecurely';
import ViewerHint from './ViewerHint';
import { useStores } from '../../../../../../hooks';
import { getSelectedFileViewState } from '../../FilesCarousel/FileView/helpers';
import styles from './ActionsColumn.module.scss';

interface ActionsColumnProps {
    showOpenViewerButton: boolean;
    collapsed: boolean;
}

const ActionsColumn: FC<ActionsColumnProps> = observer(({
    showOpenViewerButton, collapsed,
}) => {
    const { i18n } = useTranslation();

    const { filesAccessStore, recipientPageStore: { sharedFilesStore } } = useStores();
    const { selectedItemState } = filesAccessStore;
    const {
        allFilesCount,
        selectedFilePermissions,
        selectedFile,
        downloadableFilesIds,
    } = sharedFilesStore;
    const isError = getSelectedFileViewState(
        selectedFilePermissions,
        selectedFile,
        selectedItemState,
    ).contentType === 'error';

    return (
        <div
            className={classNames(
                styles['actions-col'],
                styles[i18n.dir()],
                {
                    [styles['set-collapsed']]: collapsed,
                    [styles['col-direction']]: allFilesCount > 1,
                    [styles['only-child']]: !downloadableFilesIds.length,
                },
            )}
        >
            {!isError && !!allFilesCount && (
                !showOpenViewerButton ? (
                    <ViewerHint allFilesCount={allFilesCount} />
                ) : (
                    <OpenSecurely />
                )
            )}
            <Download />
        </div>
    );
});

export default ActionsColumn;
