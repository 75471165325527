import { action, observable, makeObservable } from 'mobx';

import AuthSettingsStore from '../AuthSettingsStore';
import {
    CustomGroup,
    GroupMember,
    GroupsErrorState,
    GroupMembersChanges,
    GroupMemberBE,
    RoleErrorState,
    UserEntity, NewMember, OrganizationUser,
} from './interfaces';
import { ENDPOINTS, BASEURL } from '../../api';
import { CognitoGroups, CognitoGroupsOrganizations } from '../../types/types';
import { captureErrorForSentry, getErrorResponse } from '../../components/utils';

export type {
    CustomGroup,
    GroupMembersChanges,
    GroupMember,
    UserEntity,
};

class UsersListStore {
    constructor(authSettingsStore: AuthSettingsStore) {
        this.authSettingsStore = authSettingsStore;
        makeObservable(this);
    }

    private readonly authSettingsStore: AuthSettingsStore;

    @observable cognitoGroupsList: CognitoGroups[] = [];

    @observable loadingUserRoleEditor = false;

    @observable loadingCustomGroups = false;

    @observable newGroupsMode = false;

    @observable addMembersModal = false;

    @observable addMembersModalLoading = false;

    @observable usersList: OrganizationUser[] = [];

    @observable userGroupsList: CustomGroup[] = [];

    @observable selectedGroupId: string;

    @observable isMemberInviteSuccess: boolean;

    @observable roleErrorState: RoleErrorState = { hasRoleError: false, errorCode: '', errorMessage: '' };

    @observable groupsErrorState: GroupsErrorState = { hasGroupsError: false, errorCode: '', errorMessage: '' };

    @action
    setUsersList = (usersList: OrganizationUser[]): void => {
        this.usersList = usersList;
    };

    @action
    setIsMemberInviteSuccess = (newValue: boolean): void => {
        this.isMemberInviteSuccess = newValue;
    };

    @action
    setCognitoGroupsList = (cognitoGroupsList: CognitoGroups[]): void => {
        this.cognitoGroupsList = cognitoGroupsList;
    }

    @action
    setLoadingUserRoleEditor = (val: boolean): void => {
        this.loadingUserRoleEditor = val;
    }

    @action
    setLoadingCustomGroups = (val: boolean): void => {
        this.loadingCustomGroups = val;
    }

    fetchUsersAndRoles = async (): Promise<void> => {
        const { API } = this.authSettingsStore;
        try {
            this.setLoadingUserRoleEditor(true);
            const [users, { groups }] = await Promise.all([
                API.get(BASEURL.backend(), ENDPOINTS.getOrganizationsUsers(), {}),
                API.get(BASEURL.backend(), ENDPOINTS.roles(), {}),
            ]);
            this.setUsersList(users);
            this.setSupportedUserGroups(groups);
        } catch (error) {
            console.log('cannot fetch users and roles', error);
            captureErrorForSentry(error, 'UsersListStore.fetchUsersAndRoles');
            const { statusCode } = getErrorResponse(error);
            const errorCode = `${statusCode}` || '500';
            this.setRolesErrorState({ hasRoleError: true, errorCode });
        } finally {
            this.setLoadingUserRoleEditor(false);
        }
    }

    fetchUsersCustomGroups = async (): Promise<void> => {
        const { API } = this.authSettingsStore;
        try {
            this.setLoadingCustomGroups(true);
            const result: Omit<CustomGroup, 'members'>[] = await API.get(BASEURL.backend(), ENDPOINTS.groups(), {});
            this.setUserGroups(
                result.map<CustomGroup>((item) => ({ ...item, members: [] })),
            );
            if (result.length) {
                const { groupId } = result[result.length - 1];
                await this.fetchUsersCustomGroup(groupId);
                this.setSelectedGroupId(groupId);
            }
        } catch (error) {
            captureErrorForSentry(error, 'UsersListStore.fetchUsersAndRoles');
            const { statusCode } = getErrorResponse(error);
            const errorCode = `${statusCode}` || '500';
            this.setGroupErrorState({ hasGroupsError: true, errorCode });
        } finally {
            this.setLoadingCustomGroups(false);
        }
    }

    fetchUsersCustomGroup = async (id: string): Promise<void> => {
        const { API } = this.authSettingsStore;
        try {
            this.setLoadingCustomGroups(true);
            const usersResult: GroupMemberBE[] = await API.get(BASEURL.backend(), ENDPOINTS.groupUsers(id), {});
            const members = usersResult.map<GroupMember>(({
                display_name: displayName,
                user_id: userId,
                email,
                isGroupManager,
            }) => ({
                displayName,
                userId,
                email,
                isGroupManager,
            }));
            this.setUserListInGroup(members, id);
        } catch (error) {
            captureErrorForSentry(error, 'UsersListStore.fetchUsersAndRoles');
            const { statusCode } = getErrorResponse(error);
            const errorCode = `${statusCode}` || '500';
            this.setGroupErrorState({ hasGroupsError: true, errorCode });
        } finally {
            this.setLoadingCustomGroups(false);
        }
    }

    trySaveChangedUsersCustomGroup = async (
        groupId: string,
        changedUsers: GroupMembersChanges,
        newMembers: GroupMember[],
        groupName: string,
    ): Promise<boolean> => {
        const { API } = this.authSettingsStore;
        let isSucceed = false;
        try {
            this.setLoadingCustomGroups(true);
            const { added, deleted } = changedUsers;
            const addedEmails = added.map(item => item.toLowerCase());
            const deletedEmails = deleted.map(item => item.toLowerCase());
            await API.patch(BASEURL.backend(), ENDPOINTS.groupUsers(groupId), { body: { added: addedEmails, deleted: deletedEmails, groupName } });
            this.setUserListInGroup(newMembers, groupId);
            this.setSelectedGroupId(groupId);
            isSucceed = true;
        } catch (error) {
            captureErrorForSentry(error, 'UsersListStore.fetchUsersAndRoles');
            const { statusCode } = getErrorResponse(error);
            const errorCode = `${statusCode}` || '500';
            this.setGroupErrorState({ hasGroupsError: true, errorCode });
        }
        this.setLoadingCustomGroups(false);
        return isSucceed;
    }

    createUsersCustomGroup = async (groupName: string, members: GroupMember[]): Promise<boolean> => {
        const { API } = this.authSettingsStore;
        let isSucceed = false;
        try {
            this.setLoadingCustomGroups(true);
            const membersToLowerCase = members.map(item => ({...item, email: item.email.toLowerCase()}));
            // TODO: add response type here
            const result = await API.put(BASEURL.backend(), ENDPOINTS.groups(), { body: { groupName, members: membersToLowerCase } });
            this.prependNewGroup({
                groupName,
                members,
                groupId: result.groupId,
            });
            isSucceed = true;
        } catch (error) {
            captureErrorForSentry(error, 'UsersListStore.fetchUsersAndRoles');
            const { statusCode } = getErrorResponse(error);
            const errorCode = `${statusCode}` || '500';
            this.setGroupErrorState({ hasGroupsError: true, errorCode });
        }
        this.setLoadingCustomGroups(false);
        return isSucceed;
    }

    inviteMember = async (members: NewMember[]): Promise<boolean> => {
        const { API } = this.authSettingsStore;
        let isSucceed = false;
        try {
            this.setAddMembersModalLoading(true);
            const users = members.map(item => ({
                email: item.email.toLowerCase(),
                role: item.role
            }));
            const result = await API.post(
                BASEURL.backend(), ENDPOINTS.inviteUserToTheOrganization(), { body: { users } },
            );
            isSucceed = true;
        } catch (error) {
            this.setIsMemberInviteSuccess(false);
            captureErrorForSentry(error, 'UsersListStore.inviteMember');
        }
        this.setAddMembersModalLoading(false);
        return isSucceed;
    };

    @action
    setRolesErrorState = ({
        hasRoleError = false,
        errorCode = '',
        errorMessage = '',
    }: Partial<RoleErrorState>): void => {
        this.roleErrorState = { hasRoleError, errorMessage, errorCode };
    }

    @action
    setGroupErrorState = ({
        hasGroupsError = false,
        errorCode = '',
        errorMessage = '',
    }: Partial<GroupsErrorState>): void => {
        this.groupsErrorState = { hasGroupsError, errorMessage, errorCode };
    }

    @action
    setSelectedGroupId = (value: string): void => {
        this.selectedGroupId = value;
    }

    @action
    setNewGroupsMode = (value: boolean): void => {
        this.newGroupsMode = value;
    }

    @action
    setAddMembersModal = (value: boolean): void => {
        this.addMembersModal = value;
    }

    @action
    setAddMembersModalLoading = (value: boolean): void => {
        this.addMembersModalLoading = value;
    }

    @action
    prependNewGroup = (value: CustomGroup): void => {
        this.userGroupsList = [value, ...this.userGroupsList];
    }

    @action
    cleanUserGroupsList = (): void => {
        this.userGroupsList = [];
    }

    @action
    setUserGroups = (value: CustomGroup[]): void => {
        this.userGroupsList = value;
    }

    @action
    setUserListInGroup = (members: GroupMember[], id: string): void => {
        const newArray = [...this.userGroupsList];
        const groupIndex = newArray.findIndex(({ groupId }) => groupId === id);
        newArray[groupIndex].members = members;
        this.userGroupsList = newArray;
    }

    removeUser = (targetUserId: string): void => {
        const filteredList = this.usersList.filter(({ user_id: userId }) => userId !== targetUserId);
        this.setUsersList(filteredList);
    }

    private setSupportedUserGroups(groups: CognitoGroups[]): void {
        const supportedGroupsSet: Set<CognitoGroups> = new Set<CognitoGroups>(
            Object.values(CognitoGroups) as CognitoGroups[],
        );
        this.setCognitoGroupsList(groups.filter((group) => supportedGroupsSet.has(group)));
    }
}

export default UsersListStore;
