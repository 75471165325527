import React, { Component } from 'react';

import { matchPath } from 'react-router';
import { Alert, Layout } from 'antd';
import { inject, observer } from 'mobx-react';
import cn from 'classnames';

import i18n from '@/content';
import { ROOT_FOLDER } from '@/consts';
import Header from '../Header';
import SideBar from '../SideBar/SideBar';
import ErrorMessage from '../Common/ErrorMessage/ErrorMessage';
import QuestionTooltip from '../Common/QuestionTooltip';
import RouterWrapper from './RouterWrapper';
import UploadDrawer from '../UploadDrawer';
import CreateFolderModal from '../Common/CreateFolderModal';
import WorkspaceDrawer from '../WorkspaceDrawer';
import { BatchDownloadWidget } from '../Common/OverflowWidget';
import { withRouter, WithRouterProps } from '@/components/HOC';
import { AppStore, FilesListType, UploadedItem } from '../../stores';
import appConfig from '../../config/env';
import ability, { SpecterXRules } from '../../config/ability';
import {
    APP_ADDITIONAL_ROUTES_TEMPLATES,
    AppRoutes,
    EXTERNAL_STORAGE_ROUTES_SET,
    ExternalStorageRoutes,
} from '@/config/appRoutes';
import { SimpleCallback } from '@/types/types';
import { captureErrorForSentry } from '../utils';
import styles from './App.module.scss';
import './index.scss';

const { Content } = Layout;

const {
    ENABLE_WORKSPACE,
    HIDE_UPLOAD_BUTTON,
    DISABLE_CREATE_FOLDER,
} = appConfig;

const ZIP_FILES_LIST_TYPES_AND_ROUTES: Array<[FilesListType, AppRoutes]> = [
    ['sharedFiles', AppRoutes.sharedWithMe],
    ['myFiles', AppRoutes.myFiles],
    ['allFiles', AppRoutes.allFiles],
];

interface AppProps extends WithRouterProps {
    appStore?: AppStore;
}

interface AppState {
    isCreateFolderOpen: boolean;
}

@inject('appStore')
@observer
class App extends Component<AppProps, AppState> {
    private readonly errorsNameSpace = 'general.errors';

    constructor(props: AppProps) {
        super(props);
        this.state = { isCreateFolderOpen: false };
    }

    componentDidMount(): void {
        const { appStore, location, navigate } = this.props;
        const { authSettingsStore: { IS_WSO } } = appStore;

        if (!IS_WSO) {
            const queryParams = new URLSearchParams(location.search);
            if (queryParams.has('auth')) {
                queryParams.delete('auth');
                navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
            }
        }

        try {
            this.trySetUpFilesStore();
        } catch (error) {
            console.log('failed to set up', error);
            captureErrorForSentry(error, 'App.componentDidMount');
            appStore.setErrorStatus(true, '');
        }
    }

    componentDidUpdate(prevProps: Readonly<AppProps>, prevState: Readonly<AppState>, snapshot?: any) {
        const { location: prevLocation } = prevProps;
        const { location, appStore } = this.props;
        if (location !== prevLocation) {
            appStore.setLoadingStatus(false);
            appStore.setErrorStatus(false);
        }
    }

    componentWillUnmount(): void {
        const { appStore: { filesListStore, batchDownloadStore } } = this.props;
        filesListStore.clearFilesListStore();
        batchDownloadStore.clear();
    }

    trySetUpFilesStore(): void {
        const {
            appStore: {
                menuRoutesSet,
                filesListStore,
                workspacesStore,
            },
            location,
            navigate,
        } = this.props;
        const isAdditionalRoute = APP_ADDITIONAL_ROUTES_TEMPLATES.some(
            (path) => matchPath(path, location.pathname),
        );
        if (!isAdditionalRoute) {
            if (ENABLE_WORKSPACE) {
                workspacesStore.fetchAllWorkspaces();
            }
            filesListStore.setUp(navigate);
            ZIP_FILES_LIST_TYPES_AND_ROUTES.forEach(([filesListType, route]) => {
                if (menuRoutesSet.has(route) && location.pathname !== route) {
                    filesListStore.initScheduleRefresh(filesListType);
                }
            });
        }
    }

    handleCloseAlert = (): void => {
        const { appStore } = this.props;
        appStore.setAppAlert({ hasAlert: false, message: '', type: 'warning' });
    }

    closeUploader = (newFiles: UploadedItem[]): void => {
        const { appStore } = this.props;
        const {
            setFileUploaderState, sharedUsersStore,
            uploadFilesStore, filesListStore,
        } = appStore;
        const {
            onUploadNewFiles,
        } = filesListStore;
        if (newFiles.length) {
            this.onFinishAddingMyFiles(() => onUploadNewFiles(newFiles));
        }
        setFileUploaderState('closed');
        uploadFilesStore.clear();
        sharedUsersStore.finishRecipientsFlow();
    }

    openCreateFolder = (): void => {
        this.setState({ isCreateFolderOpen: true });
    }

    closeCreateFolder = (folderCreated: boolean): void => {
        const { appStore } = this.props;
        const { filesListStore: { fetchNewFiles } } = appStore;
        if (folderCreated) {
            this.onFinishAddingMyFiles(() => fetchNewFiles(1));
        }
        this.setState({ isCreateFolderOpen: false });
    }

    private onFinishAddingMyFiles(action: SimpleCallback): void {
        const { appStore: { filesListStore }, navigate, location } = this.props;
        const {
            setDisplayFilesType,
            navigateToRoot,
            fetchFolder,
            currentFolder,
        } = filesListStore;
        const isUploadToFolder = (currentFolder?.menu?.options?.includes('add_files')
            || filesListStore.myFilesFolderId !== ROOT_FOLDER);

        if (location.pathname !== AppRoutes.myFiles && !isUploadToFolder) {
            setDisplayFilesType('myFiles');
            if (filesListStore.myFilesFolderId !== ROOT_FOLDER) {
                navigateToRoot('myFiles');
            }
            navigate(AppRoutes.myFiles);
        }
        if (filesListStore.filesToDisplay.length) {
            action();
        } else if (isUploadToFolder) {
            fetchFolder({ folderKey: filesListStore.currentFolderKey, updateMode: 'forceUpdate' });
        }
    }

    render(): JSX.Element {
        const { isCreateFolderOpen } = this.state;
        const { appStore, location } = this.props;
        const {
            errorStatus,
            appAlert,
            menuRoutesSet,
            userStore: { isExternal, isAuditor },
            isWorkspaceDrawerOpen,
        } = appStore;
        const isMenuRoute = menuRoutesSet.has(location.pathname as AppRoutes);
        const isShareFiles = !errorStatus.hasError && !isMenuRoute;
        const classNames = cn({ [styles['share-files']]: isShareFiles });
        const loadInnerContent = !EXTERNAL_STORAGE_ROUTES_SET.has(location.pathname as ExternalStorageRoutes);
        const isPossibleMenuRoute = isMenuRoute || Object.values(AppRoutes).includes(location.pathname as AppRoutes);
        const hasAddingFiles = isPossibleMenuRoute && !isExternal && !isAuditor && ability.can(SpecterXRules.SpecterxOwnFiles, 'ALL');
        const hasUpload = !HIDE_UPLOAD_BUTTON && hasAddingFiles;
        const hasCreateFolder = !DISABLE_CREATE_FOLDER && hasAddingFiles;
        const hasWorkspace = ENABLE_WORKSPACE && hasAddingFiles;

        return (
            <div className={classNames}>
                <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
                    {loadInnerContent && (
                        <Header openCreateFolder={this.openCreateFolder} hasCreateFolder={hasCreateFolder} />)}
                    <Layout>
                        {!isShareFiles && <SideBar hasWorkspace={hasWorkspace} hasUpload={hasUpload} /> }
                        {loadInnerContent && <UploadDrawer closeUploader={this.closeUploader} />}
                        {hasAddingFiles && (
                            <>
                                {isWorkspaceDrawerOpen && ENABLE_WORKSPACE && <WorkspaceDrawer />}
                                {hasCreateFolder && (
                                    <CreateFolderModal
                                        isOpen={isCreateFolderOpen}
                                        closeModal={this.closeCreateFolder}
                                    />
                                )}
                            </>
                        )}
                        <Content className="app-content" style={{ backgroundColor: '#fff' }}>
                            {appAlert.hasAlert && (
                                <Alert
                                    type={appAlert.type as 'error' | 'warning' | 'success' | 'info'}
                                    message={(
                                        <>
                                            {appAlert.description && (
                                                <span className="tooltip-wrapper">
                                                    <QuestionTooltip>{appAlert.description}</QuestionTooltip>
                                                </span>
                                            )}
                                            {appAlert.message}
                                        </>
                                    )}
                                    banner
                                    closable
                                    onClose={this.handleCloseAlert}
                                />
                            )}
                            {errorStatus.hasError && (
                                <ErrorMessage
                                    className="app-screen"
                                    title={i18n.t(`${this.errorsNameSpace}.1`, { type: 'application' })}
                                    subtitle={i18n.t(`${this.errorsNameSpace}.try`)}
                                />
                            )}
                            <div className={cn('app-content-inner', {
                                'has-error': errorStatus.hasError,
                            })}
                            >
                                <RouterWrapper />

                            </div>
                        </Content>
                        <BatchDownloadWidget />
                    </Layout>
                </Layout>

            </div>
        );
    }
}

export default withRouter<{}>(App);
