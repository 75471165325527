import React, { RefObject } from 'react';

import {
    Alert,
    Button,
    Col,
    Form,
    Input,
    Row,
    Space,
} from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation as WithTranslationProps } from 'react-i18next';

import { MILLISECONDS_IN_SECOND } from '@/consts';
import PhoneComp from '../../Common/PhoneComp';
import { AuthSettingsStore } from '@/stores';
import appConfig from '@/config/env';
import { ENDPOINTS, BASEURL, getMyPhone } from '@/api';
import { captureErrorForSentry, checkIs404, getCognitoUserToken } from '../../utils';
import './index.scss';

interface MfaCompState {
    disablePhone: boolean,
    disableSubmit: boolean,
    sendCodeKey: string,
    phoneSearching: boolean,
    errorSending: boolean,
    codeCheckFrozen: boolean,
    buttonPause: number,
}

interface MfaCompProps extends WithTranslationProps {
    onMfaClick: (code: string) => Promise<void>;
    authSettingsStore?: AuthSettingsStore;
    isSharedFile? : boolean;
}

const COUNTDOWN_SEC = 30;

@inject('authSettingsStore')
@observer
class MfaComp extends React.Component<MfaCompProps, MfaCompState> {
    formRef: RefObject<FormInstance> = React.createRef();

    private readonly nameSpace = 'mfaModal';

    private readonly codeButtonNameSpace = 'codeAuth.buttons';

    private mounted = false;

    constructor(props: MfaCompProps) {
        super(props);
        this.state = {
            errorSending: false,
            codeCheckFrozen: true,
            buttonPause: COUNTDOWN_SEC + 1,
            disablePhone: false,
            disableSubmit: true,
            sendCodeKey: `${this.codeButtonNameSpace}.send`,
            phoneSearching: true,
        };
    }

    async componentDidMount(): Promise<void> {
        const { authSettingsStore: { API } } = this.props;
        const form = this.formRef.current;

        this.mounted = true;
        try {
            const { phone, prefix } = await getMyPhone(API);
            if (this.mounted) {
                form.setFieldsValue({ phone, prefix: { dialCode: prefix, prefix } });
                this.setState({
                    disablePhone: true,
                    phoneSearching: false,
                });
            }
        } catch (error) {
            if (!checkIs404(error)) {
                captureErrorForSentry(error, 'MfaComp.componentDidMount');
            }
            if (this.mounted) {
                this.setState({ phoneSearching: false });
            }
        }
    }

    componentWillUnmount(): void {
        this.mounted = false;
    }

    changeCode = (rule, value): void => {
        this.setState({ disableSubmit: !value });
    }

    handleSubmit = async (event): Promise<void> => {
        const { onMfaClick } = this.props;
        event.preventDefault();
        const form = this.formRef.current;
        this.setState({ disableSubmit: true });
        await onMfaClick(form.getFieldValue('code'));
        if (this.mounted) {
            this.setState({ disableSubmit: false });
        }
    }

    getCode = async (): Promise<void> => {
        const { authSettingsStore: { API } } = this.props;
        const { buttonPause: currentButtonPause } = this.state;
        const { getFieldValue } = this.formRef.current;

        try {
            this.setState({
                buttonPause: COUNTDOWN_SEC,
                sendCodeKey: `${this.codeButtonNameSpace}.sending`,
                codeCheckFrozen: true,
            });

            const timer = setInterval(() => {
                const buttonPause = currentButtonPause - 1;
                if (buttonPause === 0) {
                    clearInterval(timer);
                    return this.setState({
                        buttonPause: buttonPause || COUNTDOWN_SEC + 1,
                    });
                }
                return this.setState({
                    buttonPause,
                });
            }, MILLISECONDS_IN_SECOND);

            const token = await getCognitoUserToken();
            const queryParams = new URLSearchParams(window.location.search);
            const linkId = queryParams.get('linkId');
            await API.post(BASEURL.backend(),
                ENDPOINTS.getPhoneCode(),
                {
                    response: true,
                    headers: { Authorization: token },
                    body: { prefix: getFieldValue('prefix').prefix, phone: getFieldValue('phone'), linkId },
                });
            this.setState({
                codeCheckFrozen: false,
                sendCodeKey: `${this.codeButtonNameSpace}.sendAgain`,
            });
        } catch (error) {
            captureErrorForSentry(error, 'MfaComp.getCode');
            this.setState({
                sendCodeKey: `${this.codeButtonNameSpace}.sendAgain`,
                buttonPause: COUNTDOWN_SEC + 1,
            });
        }
    }

    render(): JSX.Element {
        const { nameSpace } = this;
        const { isSharedFile, t, i18n } = this.props;
        const {
            disableSubmit,
            codeCheckFrozen,
            phoneSearching,
            disablePhone,
            sendCodeKey,
            buttonPause,
            errorSending,
        } = this.state;
        const dir = i18n.dir();
        const confirmCodeDisabled = disableSubmit || codeCheckFrozen;
        const isCodeInputDisabled = codeCheckFrozen;

        return (
            <Form
                className={`mfa-wrapper ${dir}`}
                ref={this.formRef}
                layout="vertical"
            >
                <h3 className="ant-typography">
                    {t(`${nameSpace}.${isSharedFile ? 'twoFactorAuth' : 'identityVerification'}`)}
                </h3>
                {isSharedFile && <div className="sub-title">{t(`${nameSpace}.ownerRequestedMfa`)}</div>}
                <div className="form-container">
                    {errorSending && <Alert message={t(`${nameSpace}.errorSendingCode`)} type="error" showIcon />}
                    <Row gutter={8} className="phone-input-row">
                        <Col className="phone-input-wrapper" md={14} sm={16} xs={24}>
                            <PhoneComp
                                isLoading={phoneSearching}
                                disablePhone={appConfig.DISABLE_SELF_PHONE_FILLING || disablePhone}
                            />
                        </Col>
                        <Col className="send-button-wrapper" sm={8} xs={12}>
                            <Button
                                type="primary"
                                onClick={this.getCode}
                                disabled={buttonPause < COUNTDOWN_SEC + 1 || phoneSearching}
                            >
                                {t(sendCodeKey)}
                            </Button>
                        </Col>
                    </Row>

                    <Space align="end" className="code-row">
                        <Form.Item
                            name="code"
                            className="phone-code"
                            label={t(`${nameSpace}.code`)}
                            rules={[
                                { required: true, message: t(`${nameSpace}.pleaseEnterCode`) },
                                { validator: this.changeCode },
                            ]}
                        >
                            <Input disabled={isCodeInputDisabled} />
                        </Form.Item>
                        <Form.Item className="phone-code-send">
                            <Button
                                onClick={this.handleSubmit}
                                type="primary"
                                htmlType="submit"
                                disabled={confirmCodeDisabled}
                            >
                                {t('general.buttons.confirm')}
                            </Button>
                        </Form.Item>
                    </Space>
                </div>
            </Form>
        );
    }
}

export default withTranslation()(MfaComp);
