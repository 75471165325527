import { AxiosResponse } from 'axios';
import { TFunction } from 'i18next';

import { MFA_ATTEMPTS } from '@/consts';
import {
    FileAccessError,
    MFAError,
    OfficeViewerResponse,
    OpenFileRequestBody,
} from '../../types/types';
import { OpenOptions } from '../../config/openOptions';
import { BASEURL, ENDPOINTS, SPXAPI } from '../../api';
import { BatchDownload } from '../BatchDownloadStore/interfaces';
import { BEResultsMap, MFAAccessState, ResultsMap } from './interfaces';
import {
    checkIsMFAFailed,
    checkIsMFARequired,
    getErrorResponse,
    checkIsFileBlocked,
    checkIsFileScanning,
    invertDict,
    isUnionType,
    checkIsFileBeingPrepared,
    preventTimeoutOpenFile, preventTimeout,
} from '@/components/utils';

export const BATCH_DOWNLOAD_KEY = '__BATCH_DOWNLOAD__';

const MAP_ERROR_CODE_TO_ERROR_TYPE: Record<number, FileAccessError> = {
    403: 'noPermissions',
    404: 'notFound',
    406: 'unsupportedAccessType',
    408: 'fileBeingPrepared',
    412: 'noRelatedAccount',
};

export const getErrorType = (error: unknown): FileAccessError => {
    const {
        message,
        statusCode,
    } = getErrorResponse(error);
    let result: FileAccessError;
    if (checkIsMFARequired(statusCode, message)) {
        result = 'MFARequired';
    } else if (checkIsMFAFailed(statusCode, message)) {
        result = 'MFAFailed';
    } else if (checkIsFileBlocked(statusCode, message)) {
        result = 'fileBlocked';
    } else if (checkIsFileScanning(statusCode, message)) {
        result = 'fileScanning';
    } else if (checkIsFileBeingPrepared(statusCode, message)) {
        result = 'fileBeingPrepared';
    }
    if (!result) {
        result = MAP_ERROR_CODE_TO_ERROR_TYPE[statusCode] || 'serverError';
    }
    return result;
};

const processResult = <T extends OpenOptions>(response: AxiosResponse<BEResultsMap[T]>): ResultsMap[T] => {
    const { data } = response;
    if (response.status === 202) {
        return { ...data, status: 'processing' } as ResultsMap[T];
    }
    if (response.status === 408) {
        return { ...data, status: 'protected' } as ResultsMap[T];
    }
    if ((data as OfficeViewerResponse).viewUrl) {
        return data as ResultsMap[T];
    }
    if ((data as BatchDownload).batch_id) {
        return { batchId: (data as BatchDownload).batch_id, status: 'processing' } as ResultsMap[T];
    }
    return { ...data, status: 'success' } as ResultsMap[T];
};

interface OpenFileParams<T extends OpenOptions> {
    API: SPXAPI;
    accessType: T;
    fileId: string;
    code?: string;
    alwaysCheckMFA?: boolean;
    setLoadingStatus?: any;
    isProtected?: boolean;
}

export const openFile = async <T extends OpenOptions>({
    API,
    accessType,
    fileId,
    code,
    alwaysCheckMFA,
    isProtected,
}: OpenFileParams<T>): Promise<ResultsMap[T]> => {
    const body: OpenFileRequestBody = {
        app_name: accessType,
        auth_code: code,
        always_check_mfa: alwaysCheckMFA,
    };
    const openingFunc = isProtected ? preventTimeoutOpenFile : preventTimeout;
    const retryRequestLimit = isProtected ? 30 : 5;
    const response = await openingFunc<AxiosResponse<BEResultsMap[T]>>(async () => API.post(
        BASEURL.backend(),
        ENDPOINTS.openFile(fileId),
        { body, response: true },
    ), retryRequestLimit);
    return processResult(response);
};

export const MAP_ERROR_TYPE_TO_STATUS_CODE: Partial<Record<FileAccessError, string>> = {
    ...invertDict<string, FileAccessError>(MAP_ERROR_CODE_TO_ERROR_TYPE),
    serverError: '500',
};

export const MFA_STATE_INIT: MFAAccessState = { attemptsCounter: MFA_ATTEMPTS, errorType: null };

export const MFA_ERRORS: MFAError[] = ['MFAFailed', 'MFARequired', 'MFALimitReached'];

export const checkIsMFAErrorType = (errorType: FileAccessError): errorType is MFAError => (
    isUnionType<MFAError>(errorType, MFA_ERRORS)
);

export const getOpenFileErrorText = (errorType: FileAccessError, t: TFunction): string => {
    const statusCode = MAP_ERROR_TYPE_TO_STATUS_CODE[errorType];
    const messageKey: string | string[] = statusCode
        ? `general.errors.${statusCode}`
        : 'general.errors.1';
    return t(messageKey, { type: t('general.specterxCommon.file') });
};
