import React, { FC, memo } from 'react';
import ReactCountryFlag from "react-country-flag";
import UserAgent from './UserAgent';
import styles from './DeviceIdentityCell.module.scss';
import getCountryName from './CountryUtil';

interface DeviceIdentityCellProps {
    userIP: string;
    userCountryCode: string;
    userAgentStr: string;
}

const DeviceIdentityCell: FC<DeviceIdentityCellProps> = ({ userAgentStr, userIP, userCountryCode }) => {
    const countryName = getCountryName(userCountryCode);
    return (
    	<div className={styles['cell-wrapper']}>
        	{userIP && <code className={styles['ip-text']}>{userIP}</code>}
            {!!userCountryCode && <ReactCountryFlag title={countryName} countryCode={userCountryCode} svg style={{
                width: '2em',
                height: '2em',
            }} />}
        	{userAgentStr && <UserAgent userAgentStr={userAgentStr} />}
    	</div>
	);
}

export default memo(DeviceIdentityCell);
