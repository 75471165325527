import {
    action,
    computed,
    observable,
    makeObservable,
} from 'mobx';

import AuthSettingsStore from '../AuthSettingsStore';
import {
    DropdownItem,
    Policy,
    PolicySetting,
    PolicySettingsGroup,
} from '../../types/types';
import { FIVE_SECONDS_IN_MILLI } from './options';
import { BASEURL, ENDPOINTS } from '../../api';
import { captureErrorForSentry } from '../../components/utils';
import { PolicySimpleWithMFA } from '../UploadFilesStore/interfaces';
import { preparePolicyForEditor, editPolicy } from './helpers';

interface FetchPolicyListPayload {
    isSilently?: boolean;
}

const FETCH_POLICY_LIST_PAYLOAD_DEFAULT: FetchPolicyListPayload = { isSilently: false };

class PolicyStore {
    constructor(authSettingsStore: AuthSettingsStore) {
        this.authSettingsStore = authSettingsStore;
        makeObservable(this);
    }

    private readonly authSettingsStore: AuthSettingsStore;

    @observable lastFetchTime = new Date().getTime();

    @observable hasFilePolicyError = false;

    @observable loadingPolicy = false;

    @observable policyList: Policy[] = [];

    @observable selectedPolicy: Policy = null;

    @observable changedPolicy: Policy = null;

    @computed
    get hasLoadingError(): boolean {
        return this.hasFilePolicyError;
    }

    @computed
    get hasSettingsInList(): boolean {
        const { policyList } = this;
        if (policyList.length) {
            const firstElement = policyList[0];
            return !!(firstElement.settings && firstElement.settings.access);
        }
        return false;
    }

    @computed
    get policiesListToDropdown(): DropdownItem[] {
        return this.policyList
            .map<DropdownItem<string>>(({ id, title }) => ({ value: id, label: title }))
            .sort((current, prev) => (
                current.label?.toLowerCase() > prev.label?.toLowerCase() ? 1 : -1
            ));
    }

    @computed
    get hasPolicies(): boolean {
        return !!this.policyList.length;
    }

    @computed
    get defaultPolicy(): Policy | null {
        return this.policyList.length
            ? this.policyList.find((policy) => policy.active) || this.policyList[0]
            : null;
    }

    @computed
    get defaultPolicyWithMFA(): PolicySimpleWithMFA {
        const { defaultPolicy } = this;
        return defaultPolicy && {
            id: defaultPolicy.id,
            title: defaultPolicy.title,
            isMfa: defaultPolicy.isMfa,
        };
    }

    @action
    setPolicyList = (policyList: Policy[]): void => {
        this.policyList = policyList;
    }

    @action
    setLastFetchTime = (lastFetchTime: number): void => {
        this.lastFetchTime = lastFetchTime;
    }

    @action
    setSelectedPolicy = (selectedPolicy: Policy): void => {
        this.selectedPolicy = selectedPolicy;
    }

    @action
    setPolicyForEditing = (policy: Policy): void => {
        const policyForEditor = preparePolicyForEditor(policy);
        this.selectedPolicy = { ...policyForEditor };
        this.changedPolicy = { ...policyForEditor };
    }

    selectPolicyForEditing = (policyId?: string): void => {
        const policy: Policy = policyId ? this.findPolicy(policyId) : this.defaultPolicy;
        if (policy) {
            this.setPolicyForEditing(policy);
        }
    }

    @action
    setChangedPolicy = (changedPolicy: Policy): void => {
        this.changedPolicy = changedPolicy;
    }

    @action
    setEditingPolicyName = (policyName: string): void => {
        this.changedPolicy.title = policyName;
    }

    @action
    setLoadingPolicy = (val: boolean): void => {
        this.loadingPolicy = val;
    }

    fetchPolicyList = async ({
        isSilently,
    }: FetchPolicyListPayload = FETCH_POLICY_LIST_PAYLOAD_DEFAULT): Promise<void> => {
        const { policyList } = this;
        // Immediately set states if not silently fetching
        try{

            if (!isSilently) {
                this.setHasFilePolicyError(false);
                this.setLoadingPolicy(true);
            }
        
            if (!policyList.length) {
                // When store is empty we actually want to wait for the response
                await this.fetchPoliciesInBackground(isSilently);
            } else{
                // When store exist we prefer to fetch in the background, and the ui would be updated when the response is ready
                this.fetchPoliciesInBackground(isSilently);
            }
        
            // Return immediately if not silently fetching
            if (!isSilently) {
                this.setLoadingPolicy(false);
            }
        } catch(error){
            if(!this.policyList.length){
                captureErrorForSentry(error, 'PolicyStore.fetchPolicyList');
            }
        }

    }
    
    // New function to fetch policies in the background
    fetchPoliciesInBackground = async (isSilently: boolean): Promise<void> => {
        const { API } = this.authSettingsStore;
        const { policyList, lastFetchTime } = this;
        let hasError = false;
    
        try {
            const currentTime = new Date().getTime();
            const timeSinceLastFetch = Math.abs(currentTime - lastFetchTime); // Difference in milliseconds
            if (timeSinceLastFetch > FIVE_SECONDS_IN_MILLI || !policyList.length) {
                const newPolicyList = await API.get(BASEURL.backend(), ENDPOINTS.policies(), {});
                this.setLastFetchTime(new Date().getTime());
                this.setPolicyList(newPolicyList);
            }
        } catch (error) {
            hasError = true;
            if(!policyList.length){
                captureErrorForSentry(error, 'PolicyStore.fetchPoliciesInBackground');
            }
        } finally {
            if (!isSilently) {
                this.setLoadingPolicy(false);
                this.setHasFilePolicyError(hasError);
            }
        }
    }

    removePolicyFromList = (policyId: string): void => {
        this.setPolicyList(this.policyList.filter(({ id }) => id !== policyId));
    }

    tryAddPolicyToList = (policy: Policy): void => {
        if (!this.findPolicy(policy.id)) {
            this.setPolicyList([...this.policyList, policy]);
        }
    }

    @action
    setHasFilePolicyError = (val: boolean): void => {
        this.hasFilePolicyError = val;
    }

    @action
    unmount = (): void => {
        this.selectedPolicy = null;
        this.changedPolicy = null;
        this.hasFilePolicyError = false;
        this.loadingPolicy = false;
    }

    findPolicy = (policyId: string): Policy | undefined => (
        this.policyList.find(({ id }) => id === policyId)
    )

    tryFindPolicy = async (policyId: string, forceRefresh = false): Promise<Policy | undefined> => {
        if (!forceRefresh) {
            const cachedPolicy = this.findPolicy(policyId);
            if (cachedPolicy) {
                return cachedPolicy;
            }
        }
        await this.fetchPolicyList({ isSilently: true });
        return this.findPolicy(policyId);
    }

    editPolicy = (settingId: string, groupType: PolicySettingsGroup, settingChanges: Partial<PolicySetting>): void => {
        const newState: Policy = editPolicy(settingId, groupType, settingChanges, this.changedPolicy);
        this.setChangedPolicy(newState);
    }
}

export default PolicyStore;
