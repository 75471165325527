import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

// Register the locale with the countries library
countries.registerLocale(enLocale);

const getCountryName = (countryCode: string): string => {
    return countries.getName(countryCode, 'en') || 'Unknown Country';
};

export default getCountryName;
