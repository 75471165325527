import type { FileAccessResponse, FileMetadata, FileStatus } from '@/types/types';
import { OpenOptions } from '@/config/openOptions';
import type {
    AsyncDownloadItem,
    BatchDownload,
    DownloadStatus,
    StatusCheckWorker,
} from './interfaces';
import { BASEURL, ENDPOINTS, getFileMetadataCancellable } from '@/api';

export const getBatchDownloadStatus: StatusCheckWorker = async (API, batchId) => {
    const { result } = await API.get<BatchDownload>(
        BASEURL.backend(),
        ENDPOINTS.getBatchDownload(batchId),
        batchId,
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { batch_id: id, user_id: userId, ...rest } = result;
    return { id, ...rest };
};

const MAP_FILE_STATUS_TO_BATCH_STATUS: Record<FileStatus, DownloadStatus> = {
    ready_for_download: 'DONE',
    ready: 'DONE',
    fetching_chuncks: 'FETCHING',
    preparing: 'FETCHING',
    ready_for_open: 'PENDING',
    exporting: 'PENDING',
    scanning: 'PENDING',
    deleted: 'DELETED',
    uploading: 'PENDING',
    scan_blocked: 'ERROR',
    error: 'ERROR',
};

const fileMetadataToDownloadItem = ({
    file_id: id,
    filename: name,
    download_status: downloadStatus,
    error_message: message,
}: FileMetadata): AsyncDownloadItem => ({
    id,
    name,
    status: MAP_FILE_STATUS_TO_BATCH_STATUS[downloadStatus] || 'PENDING',
    downloadStatus,
    message,
});

export const getFileDownloadStatus: StatusCheckWorker = async (API, fileId) => {
    const fileMetadata = await getFileMetadataCancellable(API, fileId);
    const downloadItem = fileMetadataToDownloadItem(fileMetadata);
    if (downloadItem.status === 'DONE') {
        const { result } = await API.post<FileAccessResponse>(
            BASEURL.backend(),
            ENDPOINTS.openFile(fileId),
            fileId,
            { body: { app_name: OpenOptions.download } },
        );
        return { ...downloadItem, link: result.link };
    }
    return downloadItem;
};
