import { AppRoutes } from '../../config/appRoutes';
import { CognitoGroups } from '../../types/types';
import { SpecterXRules } from '../../config/ability';
import { MenuItem } from './interfaces';

const ADMIN_ONLY: CognitoGroups[] = [CognitoGroups.Administrators];

const AUDITOR_ONLY: CognitoGroups[] = [CognitoGroups.Auditors];

const COLLABORATORS: CognitoGroups[] = [CognitoGroups.Administrators, CognitoGroups.Collaborators];

const MenuItems: MenuItem[] = [
    {
        displayName: 'My files',
        route: AppRoutes.myFiles,
        permissionRequired: SpecterXRules.SpecterxOwnFiles,
        allowedGroups: COLLABORATORS,
    },
    {
        displayName: 'Shared with me',
        route: AppRoutes.sharedWithMe,
        permissionRequired: null,
        defaultRoute: true,
    },
    {
        displayName: 'My workspaces',
        route: AppRoutes.myWorkspaces,
        permissionRequired: SpecterXRules.SpecterxOwnFiles,
        allowedGroups: COLLABORATORS,
    },
    {
        displayName: 'All files',
        route: AppRoutes.allFiles,
        permissionRequired: SpecterXRules.SpecterxOwnFiles,
        allowedGroups: ADMIN_ONLY,
    },
    {
        displayName: 'Policies',
        route: AppRoutes.policyEditor,
        permissionRequired: SpecterXRules.SpecterxListPolicy,
        allowedGroups: ADMIN_ONLY,
    },
    {
        displayName: 'Users',
        route: AppRoutes.userRoleEditor,
        permissionRequired: SpecterXRules.SpecterXListUsers,
        allowedGroups: ADMIN_ONLY,
    },
    {
        displayName: 'Audit logs',
        route: AppRoutes.auditLogs,
        permissionRequired: SpecterXRules.SpecterxAuditLogs,
        allowedGroups: [...COLLABORATORS, ...AUDITOR_ONLY],
    },
    {
        displayName: 'Domains dashboard',
        route: AppRoutes.domainsDashboard,
        permissionRequired: SpecterXRules.SpecterxAdminRights,
        allowedGroups: ADMIN_ONLY,
    },
    {
        displayName: 'Settings',
        route: AppRoutes.settings,
        permissionRequired: SpecterXRules.SpecterxAdminRights,
        allowedGroups: ADMIN_ONLY,
    },
];

export default MenuItems;
