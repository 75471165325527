import { Amplify } from 'aws-amplify';

import WebappStorageCipherProxy from '../webappStorage/WebappStorageCipherProxy';
import config from './env';
import { getAmplifyAPIEndpoints, getAWSAmplifyRegion } from '../api';
import { getCognitoOauth } from './oauth';

const configureAmplify = (hasCustomSignOutURL = false): void => {
    Amplify.configure({
        Auth: {
            mandatorySignIn: true,
            userPoolId: config.cognito.USER_POOL_ID,
            userPoolWebClientId: config.cognito.APP_CLIENT_ID,
            authenticationFlowType: 'USER_PASSWORD_AUTH',

            identityPoolId: config.cognito.IDENTITY_POOL_ID,
            region: getAWSAmplifyRegion(config.cognito.IDENTITY_POOL_ID),
            identityPoolRegion:  getAWSAmplifyRegion(config.cognito.IDENTITY_POOL_ID),
            oauth: getCognitoOauth(config, hasCustomSignOutURL),
            storage: new WebappStorageCipherProxy(localStorage),
        },
        API: {
            endpoints: getAmplifyAPIEndpoints(),
        },
    });
};

export default configureAmplify;
