import { ToolbarItem } from './pspdfkitTypes';

const LICENSES = [
    {
        name: 'PSPDFKit for Web for SpecterX Application',
        product: 'PSPDFKit for Web',
        identifier: 'PS220524-92840-35956',
        bundle_ids: [
            {
                bundle_id: '*.specterx.com',
                // eslint-disable-next-line max-len
                license_key: 'eqey3_9ggS9InsgW8YrR1O1lHkVF9B0IZ-nJL-uk9dIxcdwON4G2rYzpTQ-Do1Dd7vv-fw3jZRXELXmsldkzvfFfTO1Ij3btXr88bWvYXM-FZADAeP7P_XOileoETBGWuqrRTgH1OFJ7trK13VvFSqm-8JGwjcQsMQfj3d5rzsiMse-IyIzBwXTeuoVyHE6hgs58VYlmTtqBefCti18bXTY8HK1DJ96Vg7Lyww4WkwKcGHZAKuKj51UyRzsEA_lztb1UlsZ0_JrMdNJ-hENXgUu6-fY9b_3UnveoHkmAIw9uzCZcN3nFvCCDI-NARLqYuqE5SK6EGh2-lRdbbdqWLIdk87Ir4eQOYtqL2QPbcKmt0_xXHXZ9qAnOyKDzBnOyz2hq_YxvYhzlwLrRMl35ac-_0PACEu14wCt4kIOLwg6DcJn42kM_4RwLz6dmi0f6UvS2RzXpsJgUg_5TQa5CKilG8_P014vjxlywkcK9QEt5qE2dg-LmxFGTby4hq5qNKwG42ZY4dKuEP3vBEH34kxdyiS0KpdUBrnVvOb4xow5BfoBt7FUkmarew2x6vI2OHlpo6CU60x1o8R57EtKX7dytrRbCZ2sHkKZYKKqCiP4',
                platform: 'web',
                is_beta: false,
            },
            {
                bundle_id: 'specterx.ips.gov.il',
                // eslint-disable-next-line max-len
                license_key: 'F0g_4jnwZ8br3NEHYtmlypXU4-DQzwplAzTRDYXcSTGYwdvdXuLAycgsmngZCcrQ7VJirA8J-DmXVFLnhFzG9PmKDIZukkz-RoxYt3jccstD5zS_wygufkgh22lf8BrKIOC1itU1_FUDYmOSFeJttNwsWCPGb-ghzrVzz_GIAK3905ydOQvkO9LDqSJe9IZoo8WwBidW5rgOlvxwzYGoXim8ytxoJTLdszXjVx9o6II1x-NEPG54bXhY5HrIid4L87hI-mctEER7tQ6fum5w3lcwnyi3q_-Zynm1rG4NCopOEn76NM5m0ujtuPmgvGSpb2tXrorODzRtnHuFolLUg8GKc_-OabslGqcEvVx3MIFEP3B8jHKQ90-yCK0Uf7pfB0S6iRPMeREKTBRJMxQRVlfUo2nqR4KCPR3H_YHuXlqI0mggQlDNwEPFeEnrm5kCyQuWCouYhGN-F7n4DHQXsfUKHSEJM7LZy5sf5Thv7f2Jbp4JbCc25SU1dBetBjH_OgMBTSN9pcq4aa3DKNlrHoZbhd-v8RAFqtbErhPSSy3sHaY_DM8iqQXgYHKsXXrjs62NqJIPU0YoZeZixU9rOZB3JaP9-fpTHnQ_eILIta4',
                platform: 'web',
                is_beta: false,
            },
            {
                bundle_id: 'spx.varonis.com',
                // eslint-disable-next-line max-len
                license_key: 'UdVbrlDHK1mHqIzMbZopYFKol9q1YVKXK1Y_Gb4HKpooW02xohmEK3DiW-2172-V40L7VQsRUwNf6zK66csNTHwPPghg8S4-HR3Zf9_6_wCpPAc5KryWOejhfoBT9PbDJPtbO-TsjIxCUVFz-TxFpoZKPaA6pu2h234OlfVZGtbf4OJk1hFsq8_Sd4HLcuy5vMKEyOahFtDj1hl0w_MZEWs94HH6QnqTHWDaVQCk4VgzQcC6wCxEsj2yHSCxnpPih8aciPmJ8brkEVMr8gCD-ll0IVqVYTxP5gPk7t_lKtySl3PD8K4kQIX11vmGgt1ggjjsjacwpPa4UHbJEkqyobfi6FguNumbJdNODh9KNsGHVTgJ4XDIvrV0JKklxvluBEHyELkLGKjbKwgk_PguGER7VBlnVJnIhlHcwv4GWp8PQ0U6YPiBGVpvt-zEFB812Xy9lFCFTdgxpI2d8Wk_UkK2xsGYzjAxmLjksOe2RYdueDrXtb0IfeRoY-555SBhLrnainra6PYunICaW5p588bh1Od2_zWdO8JpLs63GMunNqXsPwgsL0Pjt8tOe2NM-HdzHYNirzO1WHSP8821kzXOe-UKbaBqHZPq2HDt2Yk',
                platform: 'web',
                is_beta: false,
            },
        ],
    },
];

export const LICENSE_KEY = (() => {
    const { origin } = window.location;

    for (const license of LICENSES) {
        for (const bundle of license.bundle_ids) {
            if (origin.endsWith(bundle.bundle_id.replace('*.', ''))) {
                return bundle.license_key;
            }
        }
    }

    // Default to the first license if no specific customer license is found
    return LICENSES[0].bundle_ids[0].license_key;
})();

export const DEFAULT_TOOLBAR_ITEMS: ToolbarItem[] = [
    { type: 'sidebar-thumbnails' },
    { type: 'sidebar-document-outline' },
    { type: 'pager' },
    { type: 'pan' },
    { type: 'zoom-out' },
    { type: 'zoom-in' },
    { type: 'zoom-mode' },
    { type: 'search' },
];

export const CAROUSEL_MODE_STYLESHEET = '/PSPDFKitCustomCSS/carouselMode.css';
